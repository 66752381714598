/// <reference path='./../_reference.ts' />
module Apex.Account.Services {
    'use strict';
    export class Account extends Core.Service {
        accountsQueryUrl = '/Accounting/AccountsQuery';
        productsQueryUrl = '/Common/Dictionary/Goods/QueryOr';
        booksQueryUrl = '/Accounting/BooksQuery?jsconfig=datehandler:iso8601datetime';
        vatId = '3DC4642B-376F-4FF6-81C7-19003E6C67B4';
        useExpandRecipeId = 'fe05b9b4-625f-4bbc-9461-162456b32809';
        cardAccountsQueryUrl = '/Crm/Accounts/Query';

        // exportCrmBranchPersonsExec() :angular.IPromise<any>{
        //     // return this.httpGet("/Accounting/Crm/Branch/Persons/Documents");
        //     var url  =this.filterUrl("/Accounting/Crm/Branch/Persons/Documents/",null);
        //     return this.http.get(url).then((result: any) => {
        //         console.log(result);
        //         if (result.status === 401) {
        //             var deferred = this.q.defer();
        //             this.location.path('/access/login');
        //             return deferred.promise;
        //         }
                
        //         if (result.status === 200) {
        //             var a =<any> angular.element('a');
        //             var blob = new Blob([result], {'type': 'application/octet-stream'});
        //             a.href = window.URL.createObjectURL(blob);
        //             a.download = "result.xlsx";
        //             a.click();
        //             return deferred.promise;
        //         }
        //     });
        // }

        deleteEmptyPayRollDets(rollId:number):angular.IPromise<any>{
            return this.httpDelete("/Accounting/PayRoll/"+rollId+"/PayRollDet/Empty")
        }
        // Persons //
        deletePayRollAccrualRecord(payRollId: number): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayRoll/AccrualRecord/" + payRollId);
        }
        DeletePayedPayRollAccrualRecord(payedPayRollId: number): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayedPayRoll/AccrualRecord/" + payedPayRollId);
        }
        getCrmBranchPersons(branchId?: number, IsOwn?: boolean, payRollId?: number): angular.IPromise<Interfaces.GetCrmBranchPersonsResponse> {
            var params = {
                nm: 'BranchId={0}&IsOwn={1}&PayRollId={2}',
                pr: [branchId, IsOwn, payRollId]
            }
            return this.httpGet("/Accounting/Crm/Branch/Persons", params);
        }
        RecordPayedPayRollInTheAccounting(PayedPayRollId: number, operId: string, acc:string): angular.IPromise<any> {            
            return this.httpPost("/Accounting/PaydPayRoll/RecordInTheAccount", { PayedPayRollId: PayedPayRollId, OperId: operId, Acc:acc });
        }
        recordPayRollInTheAccounting(payRollId: number, operId: string): angular.IPromise<any> {
            

            return this.httpPost("/Accounting/PayRoll/RecordInTheAccount", { PayRollId: payRollId, OperId: operId });
        }
        getCrmPersonsBankAcc(personId: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Persons/" + personId + "/Account");
        }

        deletePayedPayRollDet(payedPayRollDetIds: number[]): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayedPayRollDet/" + payedPayRollDetIds);
        }
        getPayRollDetNumber(): angular.IPromise<any> {
            return this.httpGet("/Accounting/PayRollDet/Number");
        }
        getPayRollNumber(): angular.IPromise<any> {
            return this.httpGet("/Accounting/PayRollNumber");
        }
        getPayedPayRollDet(payedPayId: number): angular.IPromise<Interfaces.GetPayedPayRollDetResponse> {
            return this.httpGet("/Accounting/PayedPayRollDet/" + payedPayId);
        }
        deletePayedPayRoll(id: number[]): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayedRoll/" + id);
        }
        savePayedPayRollDet(payedPayRollDet: Interfaces.IPayedPayrollDet[]): angular.IPromise<any> {
            return this.httpPost("/Accounting/PayedPayRollDet", { PayedPayRollDets: payedPayRollDet });
        }
        savePayedPayRoll(payedPayRoll: Interfaces.IPayedPayroll): angular.IPromise<any> {
            return this.httpPost("/Accounting/PayedRoll", { PayedPayRoll: payedPayRoll });
        }

        getPayedPayRollById(PayedPayRollId: number): angular.IPromise<Interfaces.IGetPayedPayRollResponse> {
            return this.getPayedPayRolls(PayedPayRollId, null);
        }

        getPayedPayRollByPayRollId(payRollId: number): angular.IPromise<Interfaces.IGetPayedPayRollResponse> {
            return this.getPayedPayRolls(null, payRollId);
        }

        private getPayedPayRolls(PayedPayRollId?: number, payRollId?: number): angular.IPromise<any> {
            var url: string;
            if (PayedPayRollId)
                url = "/Accounting/PayedPayRoll/" + PayedPayRollId;
            else if (payRollId)
                url = "/Accounting/PayRoll/" + payRollId + "/PayedPayRoll";
            return this.httpGet(url);
        }
        deletePayRollDet(payRollDetIds: number[]): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayRollDet/" + payRollDetIds);
        }
        savePayRollDets(payRollDets: Interfaces.IPayRollDet[]): angular.IPromise<any> {
            return this.httpPost("/Accounting/PayRollDet", { PayRollDets: payRollDets });
        }
        updatePayRollDets(payRollDet: Interfaces.IPayRollDet): angular.IPromise<any> {
            return this.httpPut("/Accounting/PayRollDet", { PayRollDet: payRollDet });
        }
        getPayRollType(): angular.IPromise<any> {
            return this.httpGet("/Accounting/PayRolType");
        }
        deletePayRoll(rollId: number): angular.IPromise<any> {
            return this.httpDelete("/Accounting/PayRoll/" + rollId);
        }
        savePayRoll(payRoll: Interfaces.IPayRoll): angular.IPromise<any> {
            

            return this.httpPost("/Accounting/PayRoll", { PayRoll: payRoll });
        }
        getPayRollDet(PayRollDetId?: number, RollId?: number, PersonId?: number): angular.IPromise<Apex.Account.Interfaces.IGetPayRollDetResponse> {
            var params = {
                nm: "PayRollDetId={0}&RollId={1}&PersonId={2}",
                pr: [PayRollDetId, RollId, PersonId]
            };
            return this.httpGet("/Accounting/PayRollDet", params);
        }
        getPayRoll(RollId: number, d1 = null, d2 = null): angular.IPromise<Apex.Account.Interfaces.IGetPayRollResponse> {
            var params = {
                nm: "RollId={0}&D1={1}&D2={2}&jsconfig=dh:iso8601",
                pr: [RollId, d1, d2]
            }
            // jsconfig=dh:iso8601
            // var url=this.filterUrl("/Accounting/PayRoll", params);
            return this.httpGet("/Accounting/PayRoll", params);
        }

        getOpers(formId?): angular.IPromise<{ Result: Array<Interfaces.IOperInfo> }> {
            if (formId) {
                var params = {
                    nm: 'FFormsID={0}',
                    pr: [formId]
                }
                return this.httpGetCached('/Accounting/Opers/Allowed', params);
            }
            return this.httpGetCached('/Accounting/Opers/Allowed');
        }

        async getOperInfo(operId: string): Promise<Interfaces.IOperInfo> {
            var resp = await this.httpGetCached('/Accounting/Opers/' + operId + '/Info');
            return resp.Result;
        }

        getReturnableDocuments(fromDate, toDate, creditAcc): angular.IPromise<any> {
            return this.httpGet("/Accounting/Returnable/Documents?BeginDate=" + fromDate + "&EndDate=" + toDate + "&Acc=" + creditAcc);
        }

        getReturnableProducts(creditAcc: string, fromDate, toDate, barcode: string, productName: string, bookIds: Array<string> = null): angular.IPromise<any> {
            var requestString = "/Accounting/Returnable/Products?" + "&Acc=" + creditAcc;

            if (fromDate != undefined && fromDate != null) {
                requestString += "&BeginDate=" + fromDate;
            }

            if (toDate != undefined && toDate != null) {
                requestString += "&EndDate=" + toDate;
            }

            if (barcode != undefined && barcode != null && barcode.trim().length > 0) {
                requestString += "&BCode=" + barcode;
            }

            if (productName != undefined && productName != null && productName.trim().length > 0) {
                requestString += "&Name=" + productName;
            }

            if (bookIds != undefined && bookIds != null && bookIds.length > 0) {
                requestString += "&BookIds=" + bookIds;
            }

            return this.httpGet(requestString);
        }

        deleteDoc(docID): angular.IPromise<any> {
            return this.httpDelete('/Accounting/Docs/' + docID);
        }

        getAvailableRefBooks(docId: string): angular.IPromise<any> {
            return this.httpGet("/Accounting/Docs/" + docId + "/References/Available");
        }


        addBookReference(docId: string, referencesList: Array<any>): angular.IPromise<any> {
            var params = {
                BookReferences: referencesList
            };
            return this.httpPost("/Accounting/Docs/" + docId + "/References", params);
        }

        deleteBookReference(referenceId: string): angular.IPromise<any> {
            return this.httpDelete("/Accounting/Docs/References?BookRefId=" + referenceId);
        }

        changeBookRefType(refid: string, refType: number): angular.IPromise<any> {
            return this.httpPatch("/Accounting/Docs/References/", { BookRefId: refid, RefType: refType });
        }

        getDocReferences(docId: string): angular.IPromise<any> {
            return this.httpGet("/Accounting/Docs/" + docId + "/References");
        }

        getProductRecipe(prodppId: string, count: any, canExpand: any = null): angular.IPromise<any> {
            var params = {
                nm: 'MCount={0}',
                pr: [count]
            };

            if (canExpand != null) {
                params.nm += "&CanExpand={1}";
                params.pr = [count, canExpand];
            }
            return this.httpGet("/Common/Dictionary/SimpleProductRecipe/" + prodppId, params);
        }

        getDocByID(docID): angular.IPromise<any> {
            return this.httpGet('/Accounting/Docs/' + docID);
        }

        docBlocking(docID, blocking): angular.IPromise<any> {
            return this.httpPatch('/Accounting/Docs/Blocking', { Blocking: blocking, DocIDs: [docID] });
        }

        getVatById(): angular.IPromise<any> {
            return this.httpGet('/Core/FixVal/' + this.vatId);
        }

        getFixVal(mgrId: string): angular.IPromise<any> {
            return this.httpGet("/Core/FixVal?mgrId=" + mgrId);
        }

        getFixValById(codeId: string): angular.IPromise<any> {
            return this.httpGet('/Core/FixVal/' + codeId);
        }

        getCurrency(): angular.IPromise<any> {
            return this.httpGetCached('/Accounting/Currency');
        }
        getServices(): angular.IPromise<any> {
            return this.httpGet('/Accounting/Services');
        }

        getAccountsByOperID(operID): angular.IPromise<any> {
            return this.httpGet('/Accounting/Opers/' + operID + '/Template/Accounts/');
        }

        getSuppliers(): angular.IPromise<any> {
            return this.httpGet('/Accounting/Accounts/Suppliers/');
        }

        getRates(date, currency): angular.IPromise<any> {
            var params = {
                nm: 'Date={0}&CurID={1}',
                pr: [date, currency]
            }
            return this.httpGet('/Accounting/Currency/Rates', params);
        }

        getAccounts(accnType?): angular.IPromise<any> {
            if (!accnType) {
                return this.httpGet('/Accounting/Accounts/All');
            }
            return this.httpGet('/Accounting/Accounts/' + accnType);
        }

        getAccountCards(): angular.IPromise<any> {
            return this.httpGet('/Accounting/Account/Cards');
        }

        getTransactions(acc, d1, d2): angular.IPromise<any> {
            var params = {
                nm: 'Acc={0}&D1={1}&D2={2}&jsconfig=dh:iso8601',
                pr: [acc, d1, d2]
            };
            return this.httpGet('/Accounting/Transactions', params);
        }

        getBalanceTurnover(sDate, eDate, showNullableAcc): angular.IPromise<any> {
            var params = {
                nm: 'StartDate={0}&EndDate={1}&ShowAccountsWithNoTurnover={2}',
                pr: [sDate, eDate, showNullableAcc]
            };

            return this.httpGet('/Accounting/Turnover/Tree', params);
        }

        exportToExcel(url) {
            return this.getDataToExport(url).then(result => {
                return result;
            });
        }

        saveDoc(doc): angular.IPromise<any> {
            return this.httpPost('/Accounting/Docs/', doc);
        }

        getAvailableProductsWithPrice(dDate, warehouseAcc, clientAcc, grouped, pricetype): angular.IPromise<{ Result: Array<Interfaces.IAvailableProductWithPrice> }> {
            var params = {
                nm: 'DDate={0}&WarehouseAcc={1}&Grouped={2}&PriceType={3}&ClientAcc={4}',
                pr: [dDate, warehouseAcc, grouped, pricetype, clientAcc]
            };
            return this.httpGet("/Accounting/Supplies/Avaliable/Priced", params);
        }


        getAvaliableSupplies(dDate, warehouseAcc): angular.IPromise<{ Result: Array<Interfaces.ISupplie> }> {
            var params = {
                nm: 'DDate={0}&WarehouseAcc={1}',
                pr: [dDate, warehouseAcc]
            };
            return this.httpGet('/Accounting/Supplies/Avaliable', params);

        }
        getAvaliableSuppliesNotGrouped(dDate, warehouseAcc): angular.IPromise<{ Result: Array<Interfaces.ISupplie> }> {
            var params = {
                nm: 'DDate={0}&WarehouseAcc={1}&Grouped={2}',
                pr: [dDate, warehouseAcc, false]
            };
            return this.httpGet('/Accounting/Supplies/Avaliable', params);

        }

        getAvailableReports(operID) {
            return this.httpGet('/reports/documents/' + operID);
        }

        printReport(reportID, layoutID, key, params) {
            //var url = Global.Settings.serviceUrl + '/reports/' + reportID + '/' + layoutID + '?RptParams={"' + key + '":"' + params + '"}';
            //this.$window.open(url);
            this.http({
                url: Global.Settings.serviceUrl + '/reports/' + reportID + '/' + layoutID + '?RptParams={"' + key + '":"' + params + '"}',
                method: "GET",
                headers: {
                    'Content-type': "application/json"
                },
                responseType: "arraybuffer"
            }).then((data) => {
                console.log("data=", data); 
                // Core.Utils.Helper.saveFile(data, "result.pdf");
            }, err => { })


            // .success((data, status, headers, config) => {
            //     console.log(data, status, headers, config);

            // }).error((data, status, headers, config) => {
            // });

        }

        getAccountsTree(): angular.IPromise<{ Result: any }> {
            return this.httpGet("/Accounting/AccCard/Tree?jsconfig=inv:true");
        }
        getAccountsView(accCard) {
            return this.httpGet("/Crm/Accounts/Query?CardStartsWith=" + parseInt(accCard.split("0").join("")));
        }

        saveAccount(account) {
            return this.httpPost("/Accounting/Accounts/", { Account: account });
        }

        updateAccount(account) {
            return this.httpPut("/Accounting/Accounts/", { Account: account });
        }
        deleteAccount(acc) {
            return this.httpDelete("/Accounting/Accounts/" + acc)
        }


        getFreeAccount(cardAcc) {
            return this.httpGet("/Accounting/AccCard/" + cardAcc + "/Accounts/Free");
        }

        saveAccCard(model) {
            return this.httpPost('/Accounting/AccCard/', { AccCard: model });
        }

        updateAccCard(model) {
            return this.httpPut('/Accounting/AccCard/', { AccCard: model });
        }

        deleteAccCard(accCard) {
            return this.httpDelete('/Accounting/AccountingCard/' + accCard);
        }
    }
}
