/// <reference path="./../_reference.ts"/>
module Apex.Account.Utils {
    "use strict";
    export class Documents {
        //private static accService: Services.Account = Core.Utils.Helper.getServiceFromJs("accountService");


        private static _accService: Services.Account;
        private static vat: number;

        private static accService(): Services.Account {
            if (Documents._accService === undefined)
                Documents._accService = Core.Utils.Helper.getServiceFromJs("accountService");
            return Documents._accService;
        }

        public static async openDoc(docID: Interfaces.IDoc, isCopied: boolean = false, callback: Function = null): Promise<void> {
            kendo.ui.progress($(document.body), true);
            var accService = Documents.accService();
            try {
                var doc: Interfaces.IDoc = await accService.getDocByID(docID);
                var selectedOperation = await accService.getOperInfo(doc.Operid);
                if (typeof selectedOperation === "undefined" || selectedOperation === null) {
                    Core.Utils.ModalForm.openError('frmNotFound', true);
                    kendo.ui.progress($(document.body), false);
                    return;
                }

                var curResult = await accService.getCurrency();
                _.each(curResult.Result, (x: any) => {
                    x.textTemplate = x.Code + ' - ' + x.Name;
                });


                var formData: any = {
                    Currency: curResult.Result,
                    Operation: selectedOperation,
                    DocInfo: doc,
                    isCopied: isCopied
                };
                if (this.vat == null) {
                    var vatInfo = await accService.getVatById();
                    if (vatInfo != null && vatInfo.Result != null) {
                        this.vat = vatInfo.Result.Vp;
                    } else {
                        this.vat = 0.18;
                    }
                }
                formData.Vat = this.vat;
                
                Documents.openDocForm(selectedOperation, formData, callback);
            }
            catch (e) {
                kendo.ui.progress($(document.body), false);
                Core.Utils.ModalForm.openError(e.message, false);
            }
        }

        public static expensesModalRef;

        public static openExpensesForm(docId: string): any {
            Documents.accService().getAvailableRefBooks(docId).then(res => {
                Documents.accService().getDocReferences(docId).then(resp => {
                    var formData = { availableReferences: res.Result, docReferences: resp.Result, docId: docId };
                    var dlg = Core.Utils.ModalForm.openModalForm("modalsadd.expense.html", 'addExpenseController', formData, Core.Utils.ModalSize.Large, false);
                    this.expensesModalRef = dlg;
                });
            });
        }

        public static deleteDoc(docId: Interfaces.IDoc, callback: Function = null) {
            if (docId === undefined || docId === null)
                return;
            var confirmDialog = Core.Utils.ModalForm.openConfirmation('deleteConfirmation', true);
            confirmDialog.result.then(() => {
                var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
                Documents.accService().deleteDoc(docId).then(results => {
                    
                    if (results.Result == true && results.success != false) {
                        toaster.pop('success', translate.instant('Info'), translate.instant('docDeleted'));
                        if (callback !== null && callback !== undefined)
                            callback();
                    } else if (!results.success) {
                        // toaster.pop('error', "Error", translate.instant('docDontDeleted'));
                        return;
                    }
                    return null;
                });
            });
        }

        public static addDocByOperId(operId: string, callback: Function = null) {
            kendo.ui.progress($(document.body), true);
            Documents.operInfo(operId).then(o => {
                var formData: any = {
                    Operation: o,
                    DocInfo: {},
                    isCopied: false
                };
                Documents.getCurrencies().then(a => {
                    formData.Currency = a;
                    Documents.accService().getVatById().then(data => {
                        formData.Vat = data.Result.Vp;
                        Documents.openDocForm(o, formData, callback);
                    });
                });
            });
           
        }

        public static addDoc(selectedOperation: Interfaces.IOperInfo, callback: Function = null) {
            kendo.ui.progress($(document.body), true);
            var formData: any = {
                Operation: selectedOperation,
                DocInfo: {},
                isCopied: false
            };
            Documents.getCurrencies().then(a => {
                formData.Currency = a;
                Documents.accService().getVatById().then(data => {
                    formData.Vat = data.Result.Vp;
                    Documents.openDocForm(selectedOperation, formData, callback);
                });
            });
        }


        public static async allOperations(): Promise<Interfaces.IOperInfo[]> {
            var response = await Documents.accService().getOpers();
            _.each(response.Result, (x: Interfaces.IOperInfo) => {
                x.templateName = x.OperID + ' - ' + x.Name;
            });
            return _.sortBy(response.Result, "OperID");
        }

        public static async allowedOpers(): Promise<Interfaces.IOperInfo[]> {
            var response = await Documents.allOperations();
            var allowedOpers = _.filter(response, (x: Interfaces.IOperInfo) => (typeof x.WebFormName !== "undefined"));
            return allowedOpers;
        }

        public static async operInfo(operId: string): Promise<Interfaces.IOperInfo> {
            var opers = await Documents.allowedOpers();
            return _.filter(opers, (x: Interfaces.IOperInfo) => (x.OperID == operId))[0];
        }

        public static getCurrencies = (): angular.IPromise<any> => {
            return Documents.accService().getCurrency().then(result => {
                _.each(result.Result, (x: any) => {
                    x.textTemplate = x.Code + ' - ' + x.Name;
                });
                return result.Result;
            });
        }

        private static openDocForm = (docOperation: Interfaces.IOperInfo, formData: any, callback: Function) => {
            var controllerName = '';
            _.each(docOperation.WebFormName.split("."), (item: string) => {
                if (controllerName !== '') {
                    controllerName += Common.capitalizeFirstLetter(item);
                } else {
                    controllerName = item;
                }
            });
            var modalSize = Core.Utils.ModalSize.Large;
            var modalClass = '';
            if (controllerName === 'memorialOrder') {
                modalSize = Core.Utils.ModalSize.Medium;
                modalClass = 'apx-dialog-center';
            }

            kendo.ui.progress($(document.body), false);

            var dlg = Core.Utils.ModalForm.openModalForm("modal.book.html", controllerName + 'Controller', formData, modalSize, false, modalClass);
            dlg.result.then(result => {
                if (typeof result !== "undefined" && callback !== null) {
                    callback();
                }
            });

        }
        public static getDocDetails(type, formsId, docId) {
            var accountService: Account.Services.Account = Core.Utils.Helper.getServiceFromJs("accountService");
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var rootScope = Core.Utils.Helper.getServiceFromJs(Globals.rootScope);

            accountService.getOpers().then((response) => {
                _.each(response.Result, (x: any) => {
                    x.templateName = x.OperID + ' - ' + x.Name;
                });
                var allowedOpers = _.filter(response.Result, (x) => (typeof x.WebFormName !== "undefined"));

                var selectedOperation: any = _.find(allowedOpers, (x) => (x.FFormsID === formsId));
                if (typeof selectedOperation === "undefined" || selectedOperation === null) {
                    Core.Utils.ModalForm.openError(translate.instant('frmNotFound'));
                    return;
                }

                var formData: any = {
                    Operation: selectedOperation,
                    DocInfo: {},
                    Currency: [],
                    isCopied: false
                };
                var openModal = () => {
                    var controllerName = '';
                    _.each(selectedOperation.WebFormName.split("."), (item: string) => {
                        if (controllerName !== '') {
                            controllerName += Common.capitalizeFirstLetter(item);
                        } else {
                            controllerName = item;
                        }
                    });

                    var modalSize = Core.Utils.ModalSize.Large;
                    var modalClass = '';
                    if (controllerName === 'memorialOrder') {
                        modalSize = Core.Utils.ModalSize.Medium;
                        modalClass = 'apx-dialog-center';
                    }

                    Documents.getCurrencies().then(a => {
                        formData.Currency = a;
                        Documents.accService().getVatById().then(data => {
                            formData.Vat = data.Result.Vp;
                            var dlg = Core.Utils.ModalForm.openModalForm("modal.book.html", controllerName + 'Controller', formData, modalSize, false, modalClass);
                            dlg.result.then(result => {
                                if (typeof result !== "undefined") {
                                    rootScope.$broadcast('updateGrid');
                                }
                            });
                        });
                    });
                };

                accountService.getCurrency().then(result => {
                    _.each(result.Result, (x: any) => {
                        x.textTemplate = x.Code + ' - ' + x.Name;
                    });
                    formData.Currency = result.Result;
                    //types : 1- editDoc, 2 - copyDoc, 3 - createDoc
                    if (type !== 3) {
                        accountService.getDocByID(docId).then(results => {
                            if (type === 1) {
                                formData.DocInfo = results;
                                openModal();
                            } else {
                                formData.DocInfo = results;
                                formData.isCopied = true;
                                openModal();
                            }
                        });
                    } else {
                        openModal();
                    }
                });


            });
        }
    }

    export class Common {
        public static capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}