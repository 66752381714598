/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IDocEx {
        ID?: number;
        SubDoc?: number;
        DB?: string;
        CR?: string;
        DocID?: string;
        DocType?: number;
    }
} 