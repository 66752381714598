/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IIntCode {
        IntCodeID?: string;
        Name?: string;
        Vat?: number;
        TaxMethod?: number;
        Tax1?: number;
        TaxK?: number;
    }
} 