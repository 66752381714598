/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IPayedPayRollDetScope extends angular.IScope {
        formName: string,
        Components: {
            payedPayRolDet: Apex.Components.IApxGrid
        },
        model: {
            selectedPayedPayRollDet?: any,
            payedPayRoll?: Interfaces.IPayedPayroll,
            personsBankAcc?: any,
            bankAccDataForPersons?: any,
            changedData?: Interfaces.PayedPayRollDetView[],
            tempPersonsData?:any,
            // lastChangedRow ?: Interfaces.PayedPayRollDetView
        },
        events: {
            refresh: Function,
            save:Function,
            add: Function,
            edit: Function,
            delete: Function,
            closeForm: Function,
            addPersons: Function,
            clearValue: Function,
            dropDownEventOnChange: Function,
            savePayedPayRollDetChanges:Function,
            getPersonsBankAcc:Function,
            selectAll:Function,
            unselectAll:Function
        },
        disabled:{
            disableBtn?:boolean
        }
    }
    export class PayedPayRollDet extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService', '$q','crmService'];
        constructor(private $scope: IPayedPayRollDetScope, private toaster, private $uibModalInstance, data: any, $timeout,
            private $translate, private accountService: Apex.Account.Services.Account, private $q: angular.IQService, private crmService:Apex.Crm.Services.Crm) {
            super(arguments, PayedPayRollDet.$inject)
            this.initialize($scope);

            this.$scope.model.payedPayRoll = data;
            $scope.formName = "გადახდის უწყისის დეტალები";

            $timeout(() => {
                this.refresh();
            });
        }

        initialize(scope: IPayedPayRollDetScope) {
            scope.disabled={
                disableBtn:true
            };
            scope.Components = {
                payedPayRolDet: this.initPayedPayRollDet()
            };
            scope.model = {
                personsBankAcc: {},
                changedData: [],
                tempPersonsData:[{}]
            };
            scope.events = {
                refresh: this.refresh,
                save:this.save,
                add: this.add,
                edit: this.edit,
                delete: this.delete,
                closeForm: this.closeForm,
                addPersons: this.addPersons,
                clearValue: this.clearValue,
                dropDownEventOnChange: this.dropDownEventOnChange,
                savePayedPayRollDetChanges:this.savePayedPayRollDetChanges,
                getPersonsBankAcc:this.getPersonsBankAcc,
                selectAll : this.selectAll,
                unselectAll:this.unselectAll
            }
        }
        unselectAll=()=>{
           this.$scope.Components.payedPayRolDet.UnselectAll();
           this.$scope.disabled.disableBtn= this.$scope.Components.payedPayRolDet.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>().length==0;
        }
        selectAll = () => {
            this.$scope.Components.payedPayRolDet.SelectAll();
            this.$scope.disabled.disableBtn=false;
        }
        
        getPersonsBankAcc=(personId:number)=>{
            return this.crmService.GetStaffParams(personId);
        }
        save=(data)=>{
            // var data=[];
            // for (var index = 0; index < this.$scope.model.changedData.length; index++) {                
                    var _data=<Interfaces.IPayedPayrollDet>{
                    Amount:data.Amount,
                    BankAcc:data.BankAcc,
                    Id:data.PayDId,
                    OtherWithholdAmount:data.OtherWithholdAmount,
                    PayId:data.PayId,
                    PayType:data.PayType,
                    RollDId:data.RollDId,
                    TaxAmount:data.TaxAmount
                };
            // }
            this.accountService.savePayedPayRollDet([_data]).then(r => {
                if (r.success != false) {
                    this.toaster.pop("success", "შეტყობინება", "ცვლილება წარმატებით განხორციელდა!");
                    this.$scope.model.changedData=[];
                }
            });
        }
        dropDownEventOnChange(data: Interfaces.PayedPayRollDetView, scope:IPayedPayRollDetScope) {
            Core.Utils.Helper.safeApply(scope, () => {
                
                var _data=scope.Components.payedPayRolDet.gridView.selectedItem();
                if(scope.model.selectedPayedPayRollDet && _data  && _data.BankAcc!=scope.model.selectedPayedPayRollDet.BankAcc){
                    
                    scope.model.selectedPayedPayRollDet =jQuery.extend(true, {}, _data);
                    this.save(_data);
                }
            });
        }

        savePayedPayRollDetChanges=(data:Interfaces.IPayedPayrollDet[])=> {
            this.accountService.savePayedPayRollDet(data).then(r => {
                if (r.success != false) {
                    this.toaster.pop("success", "შეტყობინება", "ცვლილება წარმატებით განხორციელდა!");
                }
            });
        }

        clearValue(dataItem: Interfaces.IPayedPayrollDet, scope:IPayedPayRollDetScope, element) {
            
            
            scope.Components.payedPayRolDet.grid.select($(element.target).closest("tr"));
            if(scope.model.selectedPayedPayRollDet && scope.model.selectedPayedPayRollDet.Id==dataItem.Id && !dataItem.DocId){
                dataItem.BankAcc = "";
            }
        }
        getPayedPayRollDet = () => {
            this.$scope.Components.payedPayRolDet.gridView.showLoading();
            this.accountService.getPayedPayRollDet(this.$scope.model.payedPayRoll.Id).then(r => {
                if (r) {
                    _.map(r.Result, (item) => {
                        this.$scope.model.personsBankAcc[item.PersonId] = item.BankAcc;
                        item["isSelected"]=false;
                        return item;
                    });
                    this.$scope.Components.payedPayRolDet.dataSource.data(r.Result);
                }
            }).finally(() => { this.$scope.Components.payedPayRolDet.gridView.hideLoading(); });
        }

        refresh = () => {
            this.$scope.model.changedData = [];
            this.getPayedPayRollDet();
        }

        add = () => {

        }
        edit = () => {

        }
        delete = () => {
            let selectedItem: Interfaces.PayedPayRollDetView[] = this.$scope.Components.payedPayRolDet.getSelectedOrCurrent<Interfaces.PayedPayRollDetView>();
            var Ids=_.map(selectedItem,(item)=>{return item.PayDId});
            var dialog = Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.accountService.deletePayedPayRollDet(Ids).then(r => {
                    if (r && r.success != false) {                        
                        this.toaster.pop("success", "შეტყობინება", "მონაცემების წაშლა წარმატებით დასრულდა!");
                        this.refresh();
                    }
                });
            })
        }
        closeForm = (value=false) => {
            this.$uibModalInstance.close(value);
        }
        addPersons = () => {
            this.openPersonsModalForm({ payedPayRoll: this.$scope.model.payedPayRoll, payRollId: this.$scope.model.payedPayRoll.RollId, personsModalFor: "payedPayRollDet" });
        }
        openPersonsModalForm = (data) => {
            var dialog = Core.Utils.ModalForm.openModalForm("persons.modal.html", Apex.Account.Controllers.PersonsByBranchModal.getName(), data, Core.Utils.ModalSize.Large);
            dialog.result.then(r => {
                if (r) {
                    this.getPayedPayRollDet()
                }
            });
        }


        initPayedPayRollDet = (): Apex.Components.IApxGrid => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initPayedPayRollDetGridDataSource(),
                columns: this.payedPayRollDetColumns,
                settings: { editble: { disableEditRow: true }, height: window.innerHeight-250 },
                parentScope: this.$scope,
                gridEvents:[
                    {
                        eType:Components.EventType.Save,
                        eFunction:(e:any)=>{
                            console.log("save=",e);
                            
                        }
                    },
                    {
                        eType:Components.EventType.Change,
                        eFunction:(e:any)=>{
                            Apex.Core.Utils.Helper.safeApply(this.$scope, ()=>{
                                // var item=this.$scope.Components.payedPayRolDet.gridView.selectedItem();
                                var selectedItem=this.$scope.Components.payedPayRolDet.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>();
                                this.$scope.model.selectedPayedPayRollDet=jQuery.extend(true, {},selectedItem[0]);
                               
                                this.$scope.disabled.disableBtn=  selectedItem.length==0;
                            });
                        }
                    },
                    {
                        eType:Components.EventType.ISelectedChange,
                        customFunction:(e:any)=>{
                            var selectedItem=this.$scope.Components.payedPayRolDet.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>();
                            this.$scope.disabled.disableBtn=selectedItem?false:true;
                        }
                    }
                ]
            }
            return grid;
        }

        initPayedPayRollDetGridDataSource = () => {
            return new kendo.data.DataSource({
                aggregate: [
                    // { field: "PName", aggregate: "count" },
                    { field: "FullName", aggregate: "count" },
                    { field: "Amount", aggregate: "sum" },
                    { field: "TaxAmount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "PayDId",
                        fileds:{
                            isSelected:{type:"boolean", editable:false},
                            // PName:{type:"string", editable:false},
                            FullName:{type:"string", editable:false},
                            PersonSN:{type:"string", editable:false},
                            BranchName:{type:"string", editable:false},
                            PostName:{type:"string", editable:false},
                            DepName:{type:"string", editable:false},
                            Amount:{type:"number", editable:false},
                            TaxPercent:{type:"number", editable:false},
                            TaxAmount:{type:"number", editable:false}
                        }
                    }
                }
            });
        }
        private payedPayRollDetColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "PayDId", hidden: true },
            { field: "PayId", hidden: true },
            { field: "RollDId", hidden: true },
             {
                field: 'isSelected', title: ' ', titleTranslated: "isSelected", width: 40, filterable: 'boolean', template: `
                <div class="checkbox checkbox-styled checkbox-danger" style="margin-bottom: 0px; text-align: center;">
                    <label>
                        <input type="checkbox"  ng-model="dataItem.isSelected" ng-true-value="true" ng-false-value="false" ng-change="options.parentScope.events.selectedChange(dataItem)">
                        <span ></span>
                    </label>
                </div>`
            },
            // { field: "PName", editable:false, titleTranslated: "namelastname", width: 150
            // , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.PName? data.PName.count:0) #' },
            { field: "FullName", editable:false, titleTranslated: "namelastname", width: 150,
            template:"<span>{{dataItem.FirstName}} {{dataItem.LastName}}</span>"
            , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.FullName? data.FullName.count:0) #' },
            { field: "PersonSN",editable:false, titleTranslated: "personalNumber", width: 100 },
            // { field: "PayRollDetAmount", titleTranslated: "salary", width: 100, format: Core.Kendo.Format.Number },
            { field: "Amount", editable:false, titleTranslated: "amount", width: 80, format: Core.Kendo.Format.Price2
            , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount ? data.Amount.sum.toFixed(2) : 0) #' },
            { field: "TaxPercent", editable:false,titleTranslated: "TaxPersent", width: 80, format: Core.Kendo.Format.Percent },
            { field: "TaxAmount", editable:false,titleTranslated: "incom", width: 80, format: Core.Kendo.Format.Price2 
            , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.TaxAmount ? data.TaxAmount.sum.toFixed(2) : 0) #' },
            {
                field: "BankAcc", titleTranslated: "ibanAcc", width: 230, template: `
               <div ng-if="!dataItem.BankAcc" >
                    <apx-dropdown       ng-disabled="dataItem.DocId" 
                                        ng-model="dataItem.BankAcc" 
                                        apx-datasource="[]"
                                        apx-value-id="Bank_acc"
                                        apx-value-text="Bank_acc"
                                        event-on-change="options.parentScope.events.dropDownEventOnChange(dataItem,options.parentScope)"
                                        apx-get-data="options.parentScope.events.getPersonsBankAcc(dataItem.PersonId)">
                    </apx-dropdown>
                </div> 
                <div ng-if="dataItem.BankAcc" apx-col-span="12">
                    <span>{{dataItem.BankAcc}}</span>
                    <div style="float:right;" >
                        <i ng-disabled="dataItem.DocId" style="cursor: pointer;" class="fa fa-times" aria-hidden="true" ng-click="options.parentScope.events.clearValue(dataItem,options.parentScope, $event)"></i>
                    </div>
                 </div>
            ` },
            { field: "BranchName",editable:false, titleTranslated: "branch", width: 200 },
            { field: "PostName", editable:false, titleTranslated: "Position", width: 150 },
            { field: "DepName", editable:false, titleTranslated: "dep", width: 150 }

        ]

    }
}