/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IPersonsByBranchModalScope extends angular.IScope {
        Components: {
            branchTreeList: Apex.Components.IApxTreeList,
            personsGrid: Apex.Components.IApxGrid,
            onlyPersonsCheckBox: {
                visible: boolean
            }
        },
        events: {
            closeForm: Function,
            refresh: Function,
            save: Function,
            selectAll:Function,
            unselectAll:Function
        },
        model: {
            selectedBranch?: Apex.Crm.Interface.ICompanyBranch,
            payRollId?: number,
            payRollDets?: Interfaces.IPayRollDet[],
            payedPayRoll?: Interfaces.IPayedPayroll,
            payedPayRollDets?: Interfaces.IPayedPayrollDet[],
            onlyPerson?: boolean,
        },
        options: {
            formMode?: string,
        },
        dataSources: {
            personalStatus?: any;
        }
    }
    /** data= {payRollId:number,personsModalFor:string ("payRollDet", "payedPayRollDet")} */
    export class PersonsByBranchModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService', 'crmService'];
        constructor(private $scope: IPersonsByBranchModalScope, private toaster, private $uibModalInstance, data: any,
            private $timeout, private $translate, private accountService: Apex.Account.Services.Account,
            private crmService: Apex.Crm.Services.Crm) {
            super(arguments, PersonsByBranchModal.$inject)
            this.initialize($scope);
            $scope.model.payRollId = data.payRollId;
            $scope.options.formMode = data.personsModalFor;
            if (data.personsModalFor == "payedPayRollDet") {
                $scope.model.payedPayRoll = data.payedPayRoll;
                $scope.Components.onlyPersonsCheckBox.visible = false;
                this.getPayedPayRollDet($scope.model.payedPayRoll.Id);
            }
            $timeout(() => {
                $(".split-handler").css("left", "40%");
            });
        }

        initialize(scope: IPersonsByBranchModalScope) {
            scope.dataSources = {};
            scope.options = {};
            scope.model = {
                onlyPerson: true
            };
            scope.events = {
                closeForm: this.closeForm,
                refresh: this.getOwnCompanyBranch,
                save: this.save,
                selectAll:this.selectAll,
                unselectAll:this.unselectAll
            };
            scope.Components = {
                branchTreeList: this.initBranchTreeList(),
                personsGrid: this.initPersonsGrid(),
                onlyPersonsCheckBox: {
                    visible: true
                }
            };

            this.$timeout(() => {
                // this.getOwnCompanyBranch();
                this.getPayRollDet(scope.model.payRollId, this.getOwnCompanyBranch);
                this.getPersonsStatus();
            });
        }


        selectAll=()=>{
            this.$scope.Components.personsGrid.SelectAll();
            // this.$scope.disabled.disableBtn= this.$scope.Components.personsGrid.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>().length==0;
        }
        unselectAll=()=>{
            this.$scope.Components.personsGrid.UnselectAll();
            // this.$scope.disabled.disableBtn=this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayRollDet>().length==0;;
        }

        //#region Methods
        getPayedPayRollDet = (payedPayId: number) => {
            this.accountService.getPayedPayRollDet(payedPayId).then(r => {
                this.$scope.model.payedPayRollDets = r.Result;
            }, error => {
                ///???
            });
        }
        getPersonsStatus = () => {
            this.accountService.getFixVal("9f47b840-e35d-48c1-b9f3-e35caa70178b").then(r => {
                if (r) {
                    this.$scope.dataSources.personalStatus = r.Result;
                }
            });
        }

        /** payRollDet ის წამოღების მერე მჭირდება კომპანიის ფილიალების წამოღება (ფილტრისთვის ინფორმაციის წინასწარი მომზადება) */
        getPayRollDet = (rollId: number, getOwnCompanyBranch: Function) => {
            this.accountService.getPayRollDet(null, rollId, null).then(r => {
                this.$scope.model.payRollDets = r.Result;
                getOwnCompanyBranch();
            });
        }

        getOwnCompanyBranch = () => {
            kendo.ui.progress($(".modal-body"), true);
            this.crmService.getCompanyBranches(null, true, false, false, false).then(r => {
                kendo.ui.progress($(".modal-body"), false);
                for (var item of r.Result) {
                    if (!item.ParentId) item.ParentId = -1;
                }
                r.Result.splice(0, 0, <Apex.Crm.Interface.ICompanyBranch>{ Name: "ყველა", ParentId: null, Id: -1 });
                this.$scope.Components.branchTreeList.treeList.setDataSource(this.convertToTreeListDataSource(r.Result));
                this.$scope.Components.branchTreeList.treeList.select(this.$scope.Components.branchTreeList.treeList.element.find("tr:eq(1)"));
            }).catch(ex => { kendo.ui.progress($(".modal-body"), false); })
        }

        getCrmBrancPersons = (branchId?: number, isOwn: boolean = true) => {
            this.$scope.Components.personsGrid.gridView.showLoading();
            if (this.$scope.options.formMode == "payRollDet") {
                this.accountService.getCrmBranchPersons(branchId, isOwn).then(r => {
                    if (r) {
                        for (var item of r.Result) {
                            item.isSelected = false;
                        }
                        var data: any;
                        if (r.Result && this.$scope.model.payRollDets) {
                            data = _.filter(<any>r.Result, (h) => {
                                if (this.$scope.model.onlyPerson) {
                                    return _.filter(this.$scope.model.payRollDets, (q) => {
                                        return q.PersonId == (<Interfaces.CrmBranchPersonsView>h).PersonId
                                    }).length == 0 && (<Interfaces.CrmBranchPersonsView>h).StafStatus == 1;
                                } else {
                                    return _.filter(this.$scope.model.payRollDets, (q) => {
                                        return q.PersonId == (<Interfaces.CrmBranchPersonsView>h).PersonId
                                    }).length == 0;
                                }
                            });
                        }
                        this.$scope.Components.personsGrid.dataSource.data(data);
                    }
                }).finally(() => {
                    this.$scope.Components.personsGrid.gridView.hideLoading();
                });
            } else
                if (this.$scope.options.formMode == "payedPayRollDet") {
                    // var data:Interfaces.IPayRollDet[]=<any>this.$scope.model.payRollDets;
                    // _.map(data,(a)=>{
                    //     return a.isSelected=false;
                    // });
                    // if(branchId>0){
                    //     data=_.filter(this.$scope.model.payRollDets,(r)=>{
                    //         return (<Interfaces.IPayRollDet>r).BranchId==branchId;
                    //     });
                    // }
                    // this.$scope.Components.personsGrid.dataSource.data(data);

                    this.accountService.getCrmBranchPersons(branchId, isOwn, this.$scope.model.payRollId).then(r => {
                        if (r) {
                            for (var item of r.Result) {
                                item.isSelected = false;
                            }
                            var data: any = r.Result;
                            // if (r.Result && this.$scope.model.payRollDets) {
                            //     data = _.filter(<any>r.Result, (r) => {
                            //         return _.filter(this.$scope.model.payRollDets, (q) => {
                            //             // (<Apex.Crm.Interface.CrmBranchPersonsView>r).Salary=q.Salary;
                            //             if(q.PersonId == (<Interfaces.CrmBranchPersonsView>r).PersonId){
                            //                 (<Interfaces.CrmBranchPersonsView>r).Salary=q.Amount;
                            //                 (<Interfaces.CrmBranchPersonsView>r).TaxAmount=q.TaxAmount;
                            //                 return true;
                            //             }
                            //             return q.PersonId == (<Interfaces.CrmBranchPersonsView>r).PersonId;
                            //         }).length >0;
                            //     });

                            //     data=_.filter(data,(k)=>{
                            //         return _.filter(this.$scope.model.payedPayRollDets, (q)=>{
                            //             return (<any>q).PersonId==(<any>k).PersonId
                            //         }).length==0;
                            //     });
                            // }
                            this.$scope.Components.personsGrid.dataSource.data(data);
                        }
                    }).finally(() => {
                        this.$scope.Components.personsGrid.gridView.hideLoading();
                    });
                }
        }
        //#endregion methods

        //#region Initialization
        initPersonsGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.personsGridDataSource(),
                columns: this.personsGridColumns,
                settings: { editble: { disableEditRow: true }, height: 400 },
                parentScope: this.$scope
            }
            return grid;
        }
        private personsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "Id", hidden: true },
            {
                field: 'isSelected', title: ' ', titleTranslated: "isSelected", width: 40, filterable: 'boolean', template: `
                <div class="checkbox checkbox-styled checkbox-danger" style="margin-bottom: 0px; text-align: center;">
                    <label>
                        <input type="checkbox"  ng-model="dataItem.isSelected" ng-true-value="true" ng-false-value="false" >
                        <span ></span>
                    </label>
                </div>`
            },
            {
                field: 'StafStatus', titleTranslated: "status", width: 30, format: Core.Kendo.Format.Number,
                template: `
            <div ng-repeat="item in options.parentScope.dataSources.personalStatus">
                <div ng-if="item.HCode==dataItem.StafStatus" popover-placement="right" popover-trigger="mouseenter" uib-popover="{{item.Name}}">
                     <i class="fa fa-circle fa-align-center" ng-class="item.HCode==0? 'text-danger' : (item.HCode==1? 'text-success' : (item.HCode==2? 'text-warning':'text-muted'))"></i>
                </div>
            </div>
            ` },
            {
                field: "PName", titleTranslated: "PName", width: 200
                , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.PName? data.PName.count:0) #'
            },
            { field: "PersonSN", titleTranslated: "personalNumber" },
            { field: "DepName", titleTranslated: "dep" },
            { field: "PostName", titleTranslated: "Position" },
            {
                field: "Salary", titleTranslated: "salary"
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Salary ? data.Salary.sum.toFixed(2) : 0) #'
            },
            { field: "bankAcc", titleTranslated: "ibanAcc" }

        ];

        personsGridDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "PName", aggregate: "count" },
                    { field: "Salary", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            isSelected: { type: "boolean" },
                            StafStatus: { type: "number" },
                            PName: { type: "string" },
                            DepName: { type: "string" },
                            PostName: { type: "string" },
                            Salary: { type: "number" },
                            bankAcc: { type: "string" }
                        }
                    }
                }
            })
        }


        initBranchTreeList = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    height: "404px",
                    columns: this.BranchTreeListColumns,
                    dataSource: this.convertToTreeListDataSource([]),
                    change: this.branchTreeListSelectedChange,
                    editable: {
                        mode: "inline"
                    },
                    filterable: true,
                    resizable: true,
                    sortable: { mode: "single" },
                    selectable: true                  

                }
            }
            return _treeList;
        }
        branchTreeListSelectedChange = (args: any) => {
            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                this.$scope.model.selectedBranch = (<JQuery>args.sender.select()).data().$scope.dataItem;
                if (this.$scope.model.selectedBranch) {
                    var branchId = (<any>this.$scope.model.selectedBranch).parentId == null ? null : this.$scope.model.selectedBranch.Id;
                    this.getCrmBrancPersons(branchId, branchId != null ? null : true);
                }
            });

        }
        convertToTreeListDataSource = (data: any): kendo.data.TreeListDataSource => {
            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded: true,
                        id: "Id",
                        fields: {
                            parentId: { field: "ParentId", nullable: true }
                        }
                    }
                }
            });
        }
        BranchTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Id", hidden: true },
            { field: "Code", width: 150, titleTranslated: "code" },
            { field: "Name", width: 200, titleTranslated: "name" },
            { field: "Address.FullAddress", width: 200, titleTranslated: "address" }
        ];
        //#endregion Initialization

        //#region events
        closeForm = (status = false) => {
            this.$uibModalInstance.close(status);
        }

        save = () => {
            var dataSource = this.$scope.Components.personsGrid.dataSource.data();

            if (dataSource) {
                var selectedData = this.$scope.Components.personsGrid.getSelectedOrCurrent<Interfaces.CrmBranchPersonsView>();
                if (selectedData.length > 0) {
                    if (this.$scope.options.formMode == "payRollDet") {
                        var forSaveData: Interfaces.IPayRollDet[] = [];
                        for (var item of selectedData) {
                            forSaveData.push(<Interfaces.IPayRollDet>{
                                BranchId: (<Interfaces.CrmBranchPersonsView>item).BranchId,
                                DepId: (<Interfaces.CrmBranchPersonsView>item).DepId,
                                PersonId: (<Interfaces.CrmBranchPersonsView>item).PersonId,
                                PostId: (<Interfaces.CrmBranchPersonsView>item).PostId,
                                RollId: this.$scope.model.payRollId,
                                Salary: (<Interfaces.CrmBranchPersonsView>item).Salary,
                                // TaxPersent: (<Apex.Crm.Interface.CrmBranchPersonsView>item).TaxPercen,
                                // Amount:(<Apex.Crm.Interface.CrmBranchPersonsView>item).Salary*(1-(<Apex.Crm.Interface.CrmBranchPersonsView>item).TaxPercen),
                                // TaxAmount:(<Apex.Crm.Interface.CrmBranchPersonsView>item).Salary*(<Apex.Crm.Interface.CrmBranchPersonsView>item).TaxPercen

                            });
                        }
                        this.accountService.savePayRollDets(forSaveData).then(r => {
                            if (r.success != false) {
                                this.toaster.pop("success", "შეტყობინება", "შენახვა წარმატებით დასრულდა!");
                                this.closeForm(true);
                            }
                        });
                    } else
                        if (this.$scope.options.formMode == "payedPayRollDet") {
                            var forSavePayedPayRollDetData: Interfaces.IPayedPayrollDet[] = [];
                            for (var item of selectedData) {
                                forSavePayedPayRollDetData.push(<Interfaces.IPayedPayrollDet>{
                                    Amount: (<Interfaces.CrmBranchPersonsView>item).Salary,
                                    PayId: this.$scope.model.payedPayRoll.Id,
                                    RollDId: this.getRollDIdByPersonId((<Interfaces.CrmBranchPersonsView>item).PersonId),
                                    TaxAmount: (<Interfaces.CrmBranchPersonsView>item).TaxAmount,
                                    BankAcc: (<Interfaces.CrmBranchPersonsView>item).bankAcc,
                                    PayType: (<Interfaces.CrmBranchPersonsView>item).bankAcc ? 0 : 1
                                });
                            }

                           

                            this.accountService.savePayedPayRollDet(forSavePayedPayRollDetData).then(r => {
                                if (r.success != false) {
                                    this.toaster.pop("success", "შეტყობინება", "შენახვა წარმატებით დასრულდა!");
                                    this.closeForm(true);
                                }
                            });
                        }
                }
            }
        }
        //#endregion events

        getRollDIdByPersonId = (personId: number) => {
            var payRollDet = _.filter(this.$scope.model.payRollDets, (r) => {
                return r.PersonId == personId;
            });
            return payRollDet[0].Id;
        }


    }
}