/// <reference path='./../_reference.ts' />
module Apex.Account.Directives {
    "use strict";
    export class AddBook implements angular.IDirective {
        restrict = "AE";
        scope = { };
        template =  '<apx-search-dropdown apx-value-id="OperID" apx-value-name="Name" apx-show-value="true" apx-on-click="createDoc(val)" apx-array="allowedOpers"></apx-search-dropdown>' ;

        link = (scope: any, element: angular.IAugmentedJQuery, attrs:any) => {
            this.accountService.getOpers().then((response) => {
                _.each(response.Result, (x:any) => {
                    x.templateName = x.OperID + ' - ' + x.Name;
                });
                scope.allowedOpers = _.filter(response.Result, (x:any) => (typeof x.WebFormName !== "undefined"));
            });

            this.accountService.getCurrency().then(result => {
                _.each(result.Result, (x: any) => { x.textTemplate = x.Code + ' - ' + x.Name });
                scope.currencies = result.Result;
            });

            scope.createDoc = (operation) => {          
                Utils.Documents.getDocDetails(3, operation.FFormsID, null);
            }
        }

        constructor(private $translate, private accountService) {
        }

        public static factory() {
            var directive = ($translate, accountService) => {
                return new AddBook($translate, accountService);
            };
            directive['$inject'] = ['$translate', 'accountService'];

            return directive;
        }
    }
} 