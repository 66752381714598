/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IEditPayRollDetScope extends angular.IScope {
        formName: string,
        model:{
            PayRollDetView?:Interfaces.IPayRollDetView,
            isClicked?:boolean
        }
        events: {
            save: Function,
            closeForm: Function,
            jumpClick:Function,
            salaryChange:Function,
            incomeChange:Function
        },
        priorityInputOption: {
            decimals: number,
            allowMinus: boolean, //false
            numberType: Components.NumberType
        },
        timeout:any        
    }

    export class EditPayRollDet extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService'];
        constructor(private $scope: IEditPayRollDetScope, private toaster, private $uibModalInstance, data: Interfaces.IPayRollDetView,
        $timeout, private $translate, private accountService: Apex.Account.Services.Account) {
            super(arguments, EditPayRollDet.$inject)
            $scope.timeout=$timeout;
            this.initialize($scope);
            this.$scope.model.PayRollDetView=data;
            $scope.formName="რედაქტირება";

            $scope.$watch("model.PayRollDetView.Salary",(val)=>{
                this.salaryChange();
            });
            //  $scope.$watch("model.PayRollDetView.TaxAmount",(val)=>{
            //     this.incomeChange();
            // });
        }

        initialize(scope: IEditPayRollDetScope) {
            scope.priorityInputOption={decimals: 2, numberType: Components.NumberType.Decimal, allowMinus: false};
            scope.model={
                isClicked:true
            };
            scope.events={
                closeForm:this.closeForm,
                save:this.save,
                jumpClick:this.jumpClick,
                salaryChange:this.salaryChange,
                incomeChange:this.incomeChange
            }    
            scope.timeout(()=>{
                var jump=$(".link-div");
                jump.addClass("link-div-active");
            }) ;   
        }

        salaryChange=()=>{
            if(this.$scope.model.isClicked)
                this.$scope.model.PayRollDetView.TaxAmount=this.$scope.model.PayRollDetView.Salary*this.$scope.model.PayRollDetView.TaxPersent;
            this.$scope.model.PayRollDetView.Amount=this.$scope.model.PayRollDetView.Salary-this.$scope.model.PayRollDetView.TaxAmount;
        }
        incomeChange=()=>{
            // if(this.$scope.model.isClicked){
            //     this.$scope.model.PayRollDetView.Salary=this.$scope.model.PayRollDetView.TaxAmount*(1/this.$scope.model.PayRollDetView.TaxPersent);
            // }
            // this.$scope.model.PayRollDetView.TaxAmount=this.$scope.model.PayRollDetView.Salary*this.$scope.model.PayRollDetView.TaxPersent;
        }
        
        jumpClick=()=>{
            var jump=$(".link-div");
            if(!this.$scope.model.isClicked){
                this.$scope.model.isClicked=true;
                jump.addClass("link-div-active");
            } else
            {
                this.$scope.model.isClicked=false
                jump.removeClass("link-div-active");
            }
        }

        closeForm = (status=false) => {
            this.$uibModalInstance.close(status);
        }

        save=()=>{
            var forSaveData=<Interfaces.IPayRollDet>{
                Amount:this.$scope.model.PayRollDetView.Amount,
                BranchId:this.$scope.model.PayRollDetView.BranchId,
                DepId:this.$scope.model.PayRollDetView.DepId,
                Id:this.$scope.model.PayRollDetView.Id,
                PersonId:this.$scope.model.PayRollDetView.PersonId,
                PostId:this.$scope.model.PayRollDetView.PostId,
                RollId:this.$scope.model.PayRollDetView.RollId,
                Salary:this.$scope.model.PayRollDetView.Salary,
                TaxAmount:this.$scope.model.PayRollDetView.TaxAmount,
                TaxPersent:this.$scope.model.PayRollDetView.TaxPersent
            }            

            this.accountService.updatePayRollDets(forSaveData).then(r => {
                    if (r.success != false) {
                        this.toaster.pop("success", "შეტყობინება", "შენახვა წარმატებით დასრულდა!");
                        this.closeForm(true);
                    }
             });
        }

    }
}