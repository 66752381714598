/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {

    export interface IAddExpenseScope extends angular.IScope {
        availableReferences: Array<any>;
        docReferences: Array<any>;
        closeForm: Function;
        components: IAddExpenseFormComponents;
        removeExp: Function;
        addExp: Function;
        createNewExp: Function;
        docId: string;
        refType: number;
        changeRefType: Function;
        disablePopup: boolean;
        isMemOpen: boolean;
    }

    export interface IAddExpenseFormComponents {
        availableReferencesGrid: Components.IApxGrid;
        documentReferencesGrid: Components.IApxGrid;
    }

    export class AddExpense extends Core.Controller {
        public static $inject = [Globals.scope, 'data', 'accountService', Globals.modalInstance, Globals.toaster, Globals.http, Globals.translate];
        $scope: IAddExpenseScope;
        $uibModalInstance: any;
        $translate: any;
        toaster: any;
        accountingService: Services.Account;
        constructor($scope: IAddExpenseScope, data, accountService, modalInstance, toaster, $http, $translate) {
            super(arguments, AddExpense.$inject);
            this.$scope = $scope;
            this.$uibModalInstance = modalInstance;
            this.$translate = $translate;
            this.toaster = toaster;
            this.accountingService = accountService;
            $scope.closeForm = this.closeForm;
            $scope.availableReferences = data.availableReferences;
            $scope.docReferences = data.docReferences;
            $scope.docId = data.docId;
            $scope.addExp = this.addExp;
            $scope.removeExp = this.removeExp;
            $scope.createNewExp = this.createNewExp;
            $scope.changeRefType = this.changeRefType;

            $scope.components = {
                availableReferencesGrid: this.getAvailableReferencesGridConfig($scope.availableReferences),
                documentReferencesGrid: this.getDocumentReferencesGridConfig($scope.docReferences)
            };
            
            this.$scope.disablePopup = true;
            this.$scope.isMemOpen = false;
            $scope.$watch('components.availableReferencesGrid.grid', (grid: kendo.ui.Grid) => {
                if (grid != undefined) {
                    $("#expenses-context-menu").kendoContextMenu({
                        target: "#targetGrid",
                        open: (e) => {
                            if ($scope.disablePopup) {                              
                                $("#refType1").css("opacity", "0.4");
                                $("#refType2").css("opacity", "0.4");
                                $("#refType3").css("opacity", "0.4");
                                $("#refType4").css("opacity", "0.4");
                            }
                            else {                               
                                $("#refType1").css("opacity", "1");
                                $("#refType2").css("opacity", "1");
                                $("#refType3").css("opacity", "1");
                                $("#refType4").css("opacity", "1");
                            }
                        }
                    });
                    
                    grid.tbody.bind('dblclick',  (e) =>{
                        var selectedObject: any = grid.dataItem(grid.select());
                        if (selectedObject != undefined && selectedObject != null) {
                           $scope.availableReferences = <any>this.$scope.components.availableReferencesGrid.grid.dataSource.data();
                            _.forEach($scope.availableReferences, (item: any) => {
                                if (selectedObject.BookId == item.BookId) {                                                                  
                                    item.selectedCount = item.AvailableVg;
                                    return;
                                }
                           });
                           $scope.components.availableReferencesGrid.grid.dataSource.sync();
                        }
                    });
                }
            });


            $(document).keydown(this.close);


        };

        close = (e) => {
            if (e.keyCode === 27) {
                if (!this.$scope.isMemOpen)
                    this.closeForm();
                else
                    this.$scope.isMemOpen = false;
            }
        }

        getAvailableReferencesGridConfig(availableReferences: Array<any>): Components.IApxGrid {
            var grid: Components.IApxGrid = {
                dataSource: new kendo.data.DataSource({
                    data: availableReferences,
                    schema: {
                        model: {
                            id:'BookId',
                            fields: {
                                'SourceDoc.DDate': { editable: false, type: "date" },
                                'SourceDoc.DBView.Acc': { editable: false, type: "string" },
                                'SourceDoc.CRView.Acc': { editable: false, type: "string" },
                                'SourceDoc.Vg': { editable: false, type: "number" },
                                'AvailableVg': { editable: false, type: "number" },
                                'SourceDoc.Contents': { editable: false, type: "string" },
                                'SourceDoc.CRView.Name': { editable: false, type: "string" },
                                'SourceDoc.NumberIn': { editable: false, type: "string" },
                                'SourceDoc.NumberOut': { editable: false, type: "string" },
                                'SourceDoc.OpDetId': { editable: false, type: "string"},
                                'selectedCount': {type:"number"}
                            }
                        }
                    }
                }),
                settings: {
                    editble: { disableEditRow: false },
                    height: 300
                },
                gridEvents: [{
                    eType: Components.EventType.Save, eFunction: (e: any) => {
                        switch (null) {
                            case e.values.selectedCount:
                                e.preventDefault();
                                return;
                        }

                        if (e.model.AvailableVg < e.values.selectedCount) {
                            this.toaster.pop('error', this.$translate.instant('Attention'), "ჩათვლას დასაქვემდებარებელი თანხა აღემატება არსებულს: " + e.model.AvailableVg);
                            e.preventDefault();
                            return;
                        }

                    }
                }],
                columns: [
                    { field: 'SourceDoc.DDate', titleTranslated: 'DATE', width: 100, format: Core.Kendo.Format.Date },
                    { field: 'SourceDoc.DBView.Acc', titleTranslated: 'DB', width: 100 },
                    { field: 'SourceDoc.CRView.Acc', titleTranslated: 'CR', width: 100 },
                    { field: 'SourceDoc.Vg', titleTranslated: 'vg', width: 100, format: Core.Kendo.Format.Price2 },
                    { field: 'AvailableVg', titleTranslated: 'rest', width: 100, format: Core.Kendo.Format.Price2 },
                    { field: 'selectedCount', titleTranslated: 'isSelected', editable: true, width: 100, format: Core.Kendo.Format.Price2 },
                    { field: 'SourceDoc.Contents', titleTranslated: 'Contents', width: 200 },
                    { field: 'SourceDoc.CRView.Name', titleTranslated: 'caption', width: 200 },
                    { field: 'SourceDoc.NumberIn', titleTranslated: 'numberin', width: 100 },
                    { field: 'SourceDoc.NumberOut', titleTranslated: 'numberout', width: 100 },
                    { field: 'SourceDoc.OpDetId', titleTranslated: 'operation', width: 100 }
                ]             
            };

            return grid;
        }

        removeExp = () => {
            var selectedRow: any = this.$scope.components.documentReferencesGrid.grid.dataItem(this.$scope.components.documentReferencesGrid.grid.select());
            
            if (selectedRow != undefined) {
                this.accountingService.deleteBookReference(selectedRow.Id).then(resp => {
                    if (resp.success != false) {
                        this.refreshSources();
                    }
                });
            }
        };

        changeRefType = (value: number): void => {           
            var selectedObject = this.$scope.components.documentReferencesGrid.selectedObject; 
            if (selectedObject == undefined || selectedObject == null)
            {
                return;
            }       
            this.accountingService.changeBookRefType(selectedObject.Id, value).then(response => {
                if (response.success != false)
                {
                    this.accountingService.getDocReferences(this.$scope.docId).then(resp => {                       
                        this.$scope.docReferences = resp.Result;                      
                        this.$scope.components.documentReferencesGrid.dataSource.data(this.$scope.docReferences);
                    });
                }
            });
        };
        
        addExp = () => {
            var datasource: any = this.$scope.components.availableReferencesGrid.grid.dataSource.data();
            
            if (datasource != undefined && datasource.length > 0) {
                var newRefs: Array<any> = [];
                _.forEach(datasource, (item: any) => {
                    if (item.selectedCount != undefined && item.selectedCount > 0) {
                        newRefs.push({ SourceId: item.BookId, Vg: item.selectedCount, RefType: 1 });
                    }
                });

                if (newRefs.length == 0)
                    return;
             
                this.accountingService.addBookReference(this.$scope.docId, newRefs).then(res => {
                    if (res.success != false && res.BookRefs != undefined && res.BookRefs.length > 0) {
                        this.refreshSources();
                    }                  
                });
            }
        };

        refreshSources = () => {
            this.accountingService.getAvailableRefBooks(this.$scope.docId).then(res => {
                this.accountingService.getDocReferences(this.$scope.docId).then(resp => {
                    this.$scope.availableReferences = res.Result;
                    this.$scope.docReferences = resp.Result;
                    this.$scope.components.availableReferencesGrid.dataSource.data(this.$scope.availableReferences);
                    this.$scope.components.documentReferencesGrid.dataSource.data(this.$scope.docReferences);
                });
            });

        };

        createNewExp = () => {

            Utils.Documents.addDocByOperId("000", result => this.refreshSources());

           
            

            //var formData = {
            //    Operation: { OperID: "000", Name: "მემორიალური ორდერი" },
            //    DocInfo: {},
            //    Currency: [],
            //    isCopied: false
            //};
            //this.$scope.isMemOpen = true;
            //var dlg = Core.Utils.ModalForm.openModalForm("modal.book.html", 'memorialOrderController', formData, Core.Utils.ModalSize.Medium, true, 'apx-dialog-center');
         
            //dlg.result.then(result => {
            //    if (result != undefined && result.DocID != undefined) {
            //        this.refreshSources();
            //    }  
            //});
        };

        getDocumentReferencesGridConfig(docReferences: Array<any>): Components.IApxGrid {
            var grid: Components.IApxGrid = {
                dataSource: new kendo.data.DataSource({
                    data: docReferences,
                    schema: {
                        model: {
                            fields: {
                                'SourceDoc.DDate': { type: "date" },
                                'SourceDoc.DBView.Acc': { type: "string"},
                                'SourceDoc.CRView.Acc': {type: "string"},
                                'Vg': { type: "number" },
                                'SourceDoc.Contents': {type:"string"},
                                'SourceDoc.CRView.Name': { type: "string" },
                                'SourceDoc.NumberIn': { type: "string" },
                                'SourceDoc.NumberOut': { type: "string" }
                            }
                        }
                    }
                }),
                settings: { height: 300 },
                columns: [
                    { field: 'SourceDoc.DDate', titleTranslated: 'DATE', width: 100, format: Core.Kendo.Format.Date },
                    { field: 'SourceDoc.DBView.Acc', titleTranslated: 'DB', width: 100 },
                    { field: 'SourceDoc.CRView.Acc', titleTranslated: 'CR', width: 100 },
                    { field: 'Vg', titleTranslated: 'vg', width: 100, format: Core.Kendo.Format.Price2 },
                    { field: 'SourceDoc.Contents', titleTranslated: 'Contents', width: 200 },
                    { field: 'SourceDoc.CRView.Name', titleTranslated: 'caption', width: 200 },
                    { field: 'SourceDoc.NumberIn', titleTranslated: 'numberin', width: 100 },
                    { field: 'SourceDoc.NumberOut', titleTranslated: 'numberout', width: 100 },
                ],
                gridEvents: [{
                    eType: Components.EventType.Change,
                    eFunction: (e) => {
                        if (this.$scope.components.documentReferencesGrid.selectedObject == undefined || this.$scope.components.documentReferencesGrid.selectedObject == null) {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                delete this.$scope.refType;
                                this.$scope.disablePopup = true;
                            });
                        }
                        else
                        {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.refType = this.$scope.components.documentReferencesGrid.selectedObject.RefType;
                                this.$scope.disablePopup = false;
                            });
                        }
                    }
                }]
            };

            return grid;
        }

        closeForm = () => {
            this.$uibModalInstance.close();
            $(document).unbind('keydown', this.close);
        };



    }
}