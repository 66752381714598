/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IOrder {
        ID?: string;
        Book_id?: string;
        Vg?: number;
        Vu?: number;
        Vc?: number;
        Vat?: number;
        Supplies_id?: string;
        Supplie?: ISupplie;
        SCount?: number;
        SCountD?: number;
        Discount?: number;
        PriceG?: number;
        PriceU?: number;
        PriceC?: number;
        Mp_Id?: string;
    }
} 