/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {
    export interface IPayRollScope extends angular.IScope {
        Components: {
            payRollGrid: Apex.Components.IApxGrid,
            payedPayRollGrid: Apex.Components.IApxGrid,
            editBtn: {
                disable?: boolean
            },
            deleteBtn: {
                disable?: boolean
            },
            payRolDetBtn: {
                disable?: boolean
            },
            popupMenu1: {
                refreshBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                addBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                editBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                deleteBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                detailBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                blockBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                saveInAccounting: {
                    element?: JQuery,
                    disable: boolean
                },
                cancelIncome: {
                    element?: JQuery,
                    disable: boolean
                }
            },
            popupMenu2: {
                refreshBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                addBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                editBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                deleteBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                detailBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                blockBtn: {
                    element?: JQuery,
                    disable: boolean
                },
                inAccounting: {
                    element?: JQuery,
                    disable: boolean
                },
                cancelIncome: {
                    element?: JQuery,
                    disable: boolean
                }

            }
        },
        disabled: {
            disablePayedPayPopUpBtn: boolean
        },
        events: {
            refresh: Function,
            add: Function,
            edit: Function,
            delete: Function,
            payRollDetView: Function,
            cancelPayRollIncome: Function,

            refreshPayedPayRoll: Function,
            addPayedPayRoll: Function,
            editPayedPayRoll: Function,
            deletePayedPayRoll: Function,
            peyedpayRollDetView: Function,
            cancelPayedPayRollIncome: Function

            changePayRollBlockStatus: Function,

            changePayedPayRollBlockStatus: Function,
            inAccounting: Function
        },
        Filters: {
            dateBitween?: any
        },
        model: {
            peyRoll: {
                selectedPayRoll?: Interfaces.IPayRoll,
                sumPayRollAmount?: number
            },
            payedPayRoll: {
                selectedPayedPayRoll?: Interfaces.IPayedPayroll,
                sumPayedPayRollamount?: number
            },
            operations: {
                dataSource?: any
            },
            payRollType: {
                dataSource?: any
            }
        }
    }

    export class PayRoll extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.translate, "$timeout", "accountService", "$translate"];
        constructor(private $scope: IPayRollScope, private toaster: any, private translate: any, private $timeout,
            private accountService: Apex.Account.Services.Account, private $translate) {
            super(arguments, PayRoll.$inject)
            this.initialize($scope);
            $timeout(() => {
                this.findPopUpMenue($scope);
                $("#pay-roll-context-menu").kendoContextMenu({
                    filter: ".pay-roll-grid",
                    open: (e) => {
                        var selectedItem: Interfaces.IPayRoll = this.$scope.Components.payRollGrid.gridView.selectedItem();

                        if (!selectedItem) {
                            $scope.Components.popupMenu1.editBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.editBtn.disable = true;
                            $scope.Components.popupMenu1.deleteBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.deleteBtn.disable = true;
                            $scope.Components.popupMenu1.detailBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.detailBtn.disable = true;
                            $scope.Components.popupMenu1.blockBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.blockBtn.disable = true;
                            $scope.Components.popupMenu1.saveInAccounting.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.saveInAccounting.disable = true;
                            $scope.Components.popupMenu1.cancelIncome.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.cancelIncome.disable = true;

                        } else {
                            var block = $("#pay-roll-block-popup i");
                            if (selectedItem.Status == "1") {
                                block.removeClass("fa-lock");
                                block.addClass("fa-unlock");
                                $("#pay-roll-block-popup b").text(this.$translate.instant("unBlockDoc"));
                            } else {
                                block.removeClass("fa-unlock");
                                block.addClass("fa-lock");
                                $("#pay-roll-block-popup b").text(this.$translate.instant("Block"));
                            }
                            if (selectedItem.Status == "0") {
                                $scope.Components.popupMenu1.editBtn.element.css("opacity", "1");
                                $scope.Components.popupMenu1.editBtn.disable = false;
                                $scope.Components.popupMenu1.deleteBtn.element.css("opacity", "1");
                                $scope.Components.popupMenu1.deleteBtn.disable = false;
                            } else {
                                $scope.Components.popupMenu1.editBtn.element.css("opacity", "0.4");
                                $scope.Components.popupMenu1.editBtn.disable = true;
                                $scope.Components.popupMenu1.deleteBtn.element.css("opacity", "0.4");
                                $scope.Components.popupMenu1.deleteBtn.disable = true;
                            }

                            $scope.Components.popupMenu1.detailBtn.element.css("opacity", "1");
                            $scope.Components.popupMenu1.detailBtn.disable = false;
                            $scope.Components.popupMenu1.blockBtn.element.css("opacity", "1");
                            $scope.Components.popupMenu1.blockBtn.disable = false;

                            if (this.$scope.Components.payRolDetBtn.disable || this.$scope.model.peyRoll.selectedPayRoll.Status == 2) {
                                $scope.Components.popupMenu1.saveInAccounting.element.css("opacity", "0.4");
                                $scope.Components.popupMenu1.saveInAccounting.disable = true;
                                $scope.Components.popupMenu1.cancelIncome.element.css("opacity", "1");
                                $scope.Components.popupMenu1.cancelIncome.disable = false;
                            } else {
                                $scope.Components.popupMenu1.saveInAccounting.element.css("opacity", "1");
                                $scope.Components.popupMenu1.saveInAccounting.disable = false;
                                $scope.Components.popupMenu1.cancelIncome.element.css("opacity", "0.4");
                                $scope.Components.popupMenu1.cancelIncome.disable = true;
                            }
                        }
                        if ($scope.Components.editBtn.disable) {
                            $scope.Components.popupMenu1.editBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.editBtn.disable = true;
                            $scope.Components.popupMenu1.deleteBtn.element.css("opacity", "0.4");
                            $scope.Components.popupMenu1.deleteBtn.disable = true;
                        }

                    }
                });

                $("#peyed-pay-roll-context-menu").kendoContextMenu({
                    filter: ".payed-Pay-roll-grid",
                    open: (e) => {
                        var selectedItem: Interfaces.IPayedPayroll = this.$scope.Components.payedPayRollGrid.gridView.selectedItem();
                        var selectedPayRoll: Interfaces.IPayRollView = this.$scope.Components.payRollGrid.gridView.selectedItem();
                        var payedPayRollDataSources = this.$scope.Components.payedPayRollGrid.dataSource.data(); ///??? es unda gavagrdzelo
                        // var payRollDataSource = this.$scope.Components.payRollGrid.dataSource.data();
                        this.$scope.model.payedPayRoll.sumPayedPayRollamount = Math.round(_.reduce(payedPayRollDataSources, (memo, item) => {
                            return memo + (<any>item).Amount;
                        }, 0)*100)/100;
        
                        /** roca archeuli ar aris daricxvis uwyisi */
                        if (!selectedPayRoll) {
                            this.$scope.Components.popupMenu2.refreshBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.refreshBtn.disable = true;
                            this.$scope.Components.popupMenu2.addBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.addBtn.disable = true;
                            this.$scope.Components.popupMenu2.cancelIncome.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.cancelIncome.disable = true;
                        } else {
                            if (selectedPayRoll.Status > 0) {
                                this.$scope.Components.popupMenu2.refreshBtn.element.css("opacity", "1");
                                this.$scope.Components.popupMenu2.refreshBtn.disable = false;
                            } else {
                                if (Math.round(selectedPayRoll.Amount*100)/100 == this.$scope.model.payedPayRoll.sumPayedPayRollamount) {
                                    this.$scope.Components.popupMenu2.addBtn.element.css("opacity", "0.4");
                                    this.$scope.Components.popupMenu2.addBtn.disable = true;
                                } else {
                                    this.$scope.Components.popupMenu2.addBtn.element.css("opacity", "1");
                                    this.$scope.Components.popupMenu2.addBtn.disable = false;
                                }
                            }
                        }

                        if ($scope.disabled.disablePayedPayPopUpBtn) {
                            this.$scope.Components.popupMenu2.editBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.editBtn.disable = true;
                            this.$scope.Components.popupMenu2.deleteBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.deleteBtn.disable = true;
                            this.$scope.Components.popupMenu2.detailBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.detailBtn.disable = true;
                            this.$scope.Components.popupMenu2.blockBtn.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.blockBtn.disable = true;
                            this.$scope.Components.popupMenu2.inAccounting.element.css("opacity", "0.4");
                            this.$scope.Components.popupMenu2.inAccounting.disable = true;
                        } else {
                            var block = $("#peyed-pay-roll-block-popup i");
                            if (selectedItem.Status == 1) {
                                block.removeClass("fa-lock");
                                block.addClass("fa-unlock");
                                $("#peyed-pay-roll-block-popup b").text(this.$translate.instant("unBlockDoc"))
                                this.$scope.Components.popupMenu2.editBtn.element.css("opacity", "0.4");
                                this.$scope.Components.popupMenu2.editBtn.disable = true;
                                this.$scope.Components.popupMenu2.deleteBtn.element.css("opacity", "0.4");
                                this.$scope.Components.popupMenu2.deleteBtn.disable = true;
                            } else {
                                block.removeClass("fa-unlock");
                                block.addClass("fa-lock");
                                $("#peyed-pay-roll-block-popup b").text(this.$translate.instant("Block"))
                                this.$scope.Components.popupMenu2.editBtn.element.css("opacity", "1");
                                this.$scope.Components.popupMenu2.editBtn.disable = false;
                                this.$scope.Components.popupMenu2.deleteBtn.element.css("opacity", "1");
                                this.$scope.Components.popupMenu2.deleteBtn.disable = false;
                            }
                            if (this.$scope.model.payedPayRoll.selectedPayedPayRoll.Status == 2) {
                                this.$scope.Components.popupMenu2.inAccounting.element.css("opacity", "0.4");
                                this.$scope.Components.popupMenu2.inAccounting.disable = true;
                                this.$scope.Components.popupMenu2.cancelIncome.element.css("opacity", "1");
                                this.$scope.Components.popupMenu2.cancelIncome.disable = false;
                            } else {
                                this.$scope.Components.popupMenu2.inAccounting.element.css("opacity", "1");
                                this.$scope.Components.popupMenu2.inAccounting.disable = false;
                                this.$scope.Components.popupMenu2.cancelIncome.element.css("opacity", "0.4");
                                this.$scope.Components.popupMenu2.cancelIncome.disable = true;
                            }
                            // this.$scope.Components.popupMenu2.editBtn.element.css("opacity", "1");
                            // this.$scope.Components.popupMenu2.deleteBtn.element.css("opacity", "1");
                            this.$scope.Components.popupMenu2.detailBtn.element.css("opacity", "1");
                            this.$scope.Components.popupMenu2.detailBtn.disable = false;
                            this.$scope.Components.popupMenu2.blockBtn.element.css("opacity", "1");
                            this.$scope.Components.popupMenu2.blockBtn.disable = false;
                        }
                    }
                });
            });
        }

        findPopUpMenue(scope: IPayRollScope) {
            /** Popupmenu1 */
            scope.Components.popupMenu1.addBtn.element = $("#add-popup");
            scope.Components.popupMenu1.blockBtn.element = $("#pay-roll-block-popup");
            scope.Components.popupMenu1.deleteBtn.element = $("#pay-roll-delete-popup");
            scope.Components.popupMenu1.detailBtn.element = $("#pay-roll-det-popup");
            scope.Components.popupMenu1.editBtn.element = $("#pay-roll-edit-popup");
            scope.Components.popupMenu1.refreshBtn.element = $("#pay-roll-refresh-popup");
            scope.Components.popupMenu1.saveInAccounting.element = $("#pay-roll-saveInAccountin-popup");
            scope.Components.popupMenu1.cancelIncome.element = $("#pay-roll-cancelIncome-popup");

            /** PopUpMenu2 */
            scope.Components.popupMenu2.addBtn.element = $("#peyed-pay-roll-add-popup");
            scope.Components.popupMenu2.blockBtn.element = $("#peyed-pay-roll-block-popup");
            scope.Components.popupMenu2.deleteBtn.element = $("#peyed-pay-roll-delete-popup");
            scope.Components.popupMenu2.detailBtn.element = $("#peyed-pay-roll-popup");
            scope.Components.popupMenu2.editBtn.element = $("#peyed-pay-roll-edit-popup");
            scope.Components.popupMenu2.refreshBtn.element = $("#peyed-pay-roll-refresh-popup");
            scope.Components.popupMenu2.cancelIncome.element = $("#peyed-pay-roll-cancelIncome-popup");
            scope.Components.popupMenu2.inAccounting.element = $("#peyed-pay-roll-inAccounting-popup");
            // scope.Components.popupMenu2.saveInAccounting.element = $("#pay-roll-saveInAccountin-popup");

        }

        initialize(scope: IPayRollScope) {
            scope.model = {
                peyRoll: {

                },
                payedPayRoll: {

                },
                operations: {
                },
                payRollType: {}
            }
            scope.Filters = {
                dateBitween: {}
            };
            scope.Components = {
                payRollGrid: this.initpayRollGrid(),
                payedPayRollGrid: this.initpayedPayRollGrid(),
                deleteBtn: {
                    disable: true
                },
                editBtn: {
                    disable: true
                },
                payRolDetBtn: {
                    disable: true
                },
                popupMenu1: {
                    addBtn: {
                        disable: false
                    },
                    blockBtn: {
                        disable: false
                    },
                    deleteBtn: {
                        disable: false
                    },
                    detailBtn: {
                        disable: false
                    },
                    editBtn: {
                        disable: false
                    },
                    refreshBtn: {
                        disable: false
                    },
                    saveInAccounting: {
                        disable: false
                    },
                    cancelIncome: {
                        disable: false
                    }
                },
                popupMenu2: {
                    addBtn: {
                        disable: false
                    },
                    refreshBtn: {
                        disable: false
                    },
                    blockBtn: {
                        disable: false
                    },
                    deleteBtn: {
                        disable: false
                    },
                    detailBtn: {
                        disable: false
                    },
                    editBtn: {
                        disable: false
                    },
                    inAccounting: {
                        disable: false
                    },
                    cancelIncome: {
                        disable: false
                    }
                }
            };
            scope.disabled = {
                disablePayedPayPopUpBtn: true
            };
            scope.events = {
                refresh: this.refresh,
                add: this.add,
                edit: this.edit,
                delete: this.delete,
                payRollDetView: this.payRollDetView,
                cancelPayRollIncome: this.cancelPayRollIncome,

                refreshPayedPayRoll: this.refreshPayedPayRoll,
                addPayedPayRoll: this.addPayedPayRoll,
                editPayedPayRoll: this.editPayedPayRoll,
                deletePayedPayRoll: this.deletePayedPayRoll,
                peyedpayRollDetView: this.peyedpayRollDetView,
                cancelPayedPayRollIncome: this.cancelPayedPayRollIncome,

                changePayRollBlockStatus: this.changePayRollBlockStatus,
                changePayedPayRollBlockStatus: this.changePayedPayRollBlockStatus,
                inAccounting: this.inAccounting
            }
            this.animateMessage();
            this.getPayRollTypes();
        }

        cancelPayRollIncome = () => {
            if (!this.$scope.Components.popupMenu1.cancelIncome.disable) {
                this.accountService.deletePayRollAccrualRecord(this.$scope.model.peyRoll.selectedPayRoll.RollId).then((success) => {
                    this.$scope.events.refresh();


                }, (error) => { });
            }
        }
        cancelPayedPayRollIncome = () => {
            if (!this.$scope.Components.popupMenu2.cancelIncome.disable)
                this.accountService.DeletePayedPayRollAccrualRecord(this.$scope.model.payedPayRoll.selectedPayedPayRoll.Id).then((success) => {
                    this.$scope.events.refreshPayedPayRoll();
                }, (error) => { });
        }
        inAccounting = (FormId: string = "1100") => {
            var item = FormId == "1100" ? this.$scope.Components.payRollGrid.gridView.selectedItem() : this.$scope.Components.payedPayRollGrid.gridView.selectedItem();

            if (FormId == "1100" && (!this.$scope.Components.popupMenu1.saveInAccounting.disable || !(this.$scope.Components.payRolDetBtn.disable || this.$scope.model.peyRoll.selectedPayRoll.Status == 2))) {
                item = this.$scope.Components.payRollGrid.gridView.selectedItem();
                this.openReferenceAccountingModal({ selectedItem: item, FormId: FormId })
            } else if (FormId == "1101" && !this.$scope.Components.popupMenu2.inAccounting.disable) {
                item = this.$scope.Components.payedPayRollGrid.gridView.selectedItem();
                this.openReferenceAccountingModal({ selectedItem: item, FormId: FormId });
            }
        }
        openReferenceAccountingModal = (data) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("refrence.in.accounting.html", Apex.Account.Controllers.ReferenceInAccountingModal.getName(), data, Core.Utils.ModalSize.Small);
            dialog.result.then(r => {
                if (r) {
                    if (data.FormId == "1100") {
                        this.$scope.events.refresh();
                    } else {
                        this.$scope.events.refreshPayedPayRoll();
                    }
                }
            });
        }

        getPayRollTypes = () => {
            this.accountService.getPayRollType().then(r => {
                if (r) {
                    this.$scope.model.payRollType.dataSource = r.Result;
                }
            });
        }
        getPayRollTypeName = (id: number): string => {
            var payRollType = _.filter(this.$scope.model.payRollType.dataSource, (item) => {
                return (<any>item).Id == id;
            });
            if (payRollType.length > 0)
                return (<any>payRollType[0]).Name;
            return "";
        }

        changePayRollBlockStatus = () => {
            var selectedItem: Interfaces.IPayRoll = this.$scope.Components.payRollGrid.gridView.selectedItem();
            if (selectedItem.Status == 2) return;
            if (selectedItem) {
                selectedItem.Status = selectedItem.Status ? 0 : 1;
                this.accountService.savePayRoll(selectedItem).then(r => {
                    this.$scope.Components.deleteBtn.disable =
                        this.$scope.Components.editBtn.disable = selectedItem.Status > 0;
                    // this.$scope.Components.payRollGrid.dataSource.sync();
                    this.refresh();
                });
            }
        }
        changePayedPayRollBlockStatus = () => {
            var selectedItem: Interfaces.IPayedPayroll = this.$scope.Components.payedPayRollGrid.gridView.selectedItem();
            if (selectedItem == 2) return;
            if (selectedItem) {
                selectedItem.Status = selectedItem.Status ? 0 : 1;
                this.accountService.savePayedPayRoll(selectedItem).then(r => {
                    this.$scope.Components.payedPayRollGrid.dataSource.sync();
                });
            }
        }
        animateMessage = () => {
            $('#animationSandbox').addClass('animated infinite flash');
        }
        refreshPayedPayRoll = () => {
            if (!this.$scope.Components.popupMenu2.refreshBtn.disable)
                this.getPayedPayRoll(this.$scope.model.peyRoll.selectedPayRoll.RollId);
        }

        peyedpayRollDetView = () => {
            if (!this.$scope.Components.popupMenu2.detailBtn.disable)
                this.openPayedPayRollDetModal(this.$scope.model.payedPayRoll.selectedPayedPayRoll);
        }
        addPayedPayRoll = () => {
            if (!this.$scope.Components.popupMenu2.addBtn.disable)
                this.openPayedPayRollModal({ rollId: this.$scope.model.peyRoll.selectedPayRoll.RollId, payedPayRollId: null });
        }
        editPayedPayRoll = () => {
            if (!this.$scope.Components.popupMenu2.editBtn.disable)
                this.openPayedPayRollModal({ rollId: null, payedPayRollId: this.$scope.model.payedPayRoll.selectedPayedPayRoll.Id });
        }
        deletePayedPayRoll = () => {
            if (!this.$scope.Components.popupMenu2.deleteBtn.disable)
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.accountService.deletePayedPayRoll([this.$scope.model.payedPayRoll.selectedPayedPayRoll.Id]).then((r) => {
                        if (r.success != false)
                            this.$scope.Components.payedPayRollGrid.grid.removeRow(this.$scope.Components.payedPayRollGrid.grid.select());
                    }, (error) => {
                        ///???
                    });
                });
        }

        getPayedPayRoll = (payRollId) => {
            this.$scope.Components.payedPayRollGrid.gridView.showLoading();
            var dataItem = this.$scope.Components.payRollGrid.gridView.selectedItem();
            this.accountService.getPayedPayRollByPayRollId(payRollId).then(r => {
                if (r) {
                    if (r.Result && r.Result.length > 0) {
                        for (var index = 0; index < r.Result.length; index++) {
                            r.Result[index].Status = r.Result[index].Status ? String(r.Result[index].Status) : "0";
                        }
                    }
                    this.$scope.Components.payedPayRollGrid.dataSource.data(r.Result);
                    this.$scope.Components.deleteBtn.disable = r.Result.length > 0 || this.$scope.Components.editBtn.disable;
                    var fa_eye = $("#tet-view").find("i");
                    if (this.$scope.Components.deleteBtn.disable) {
                        fa_eye.removeClass("fa-book");
                        fa_eye.addClass("fa-eye");
                        this.$scope.Components.popupMenu1.detailBtn.element.find("i").removeClass("fa-book");
                        this.$scope.Components.popupMenu1.detailBtn.element.find("i").addClass("fa-eye");
                    } else if (!dataItem.Status) {
                        fa_eye.removeClass("fa-eye");
                        fa_eye.addClass("fa-book");
                        this.$scope.Components.popupMenu1.detailBtn.element.find("i").removeClass("fa-eye");
                        this.$scope.Components.popupMenu1.detailBtn.element.find("i").addClass("fa-book");
                    }
                }
            }).finally(() => {
                this.$scope.Components.payedPayRollGrid.gridView.hideLoading();
            });
        }

        refresh = () => {
            var d1 = Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBitween.fromDate);
            var d2 = Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBitween.toDate);

            this.$scope.Components.payRollGrid.gridView.showLoading();
            this.accountService.getPayRoll(null, d1, d2).then(r => {
                this.$scope.Components.payRollGrid.gridView.hideLoading();
                if (r) {
                    for (var index = 0; index < r.Result.length; index++) {
                        
                        r.Result[index].Status = r.Result[index].Status ? String(r.Result[index].Status) : "0";
                        r.Result[index].PayRollTypeName = this.getPayRollTypeName(r.Result[index].RollType);
                    }
                    this.$scope.Components.payRollGrid.dataSource.data(r.Result);
                    this.payRollSelectedChange();
                }
            }).finally(() => { this.$scope.Components.payRollGrid.gridView.hideLoading(); })
        }

        add = () => {
            this.openModalForm();
        }
        edit = () => {
            var dataItem = this.$scope.Components.payRollGrid.gridView.selectedItem();
            if (dataItem && dataItem.Status == 0) {
                this.openModalForm((<Apex.Account.Interfaces.IPayRoll>dataItem).RollId);
            }
        }
        delete = () => {
            var selectedItem = <Interfaces.IPayRoll>this.$scope.Components.payRollGrid.gridView.selectedItem();
            if (selectedItem && selectedItem.Status == 0) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.accountService.deletePayRoll(selectedItem.RollId).then(r => {
                        if (r.success != false)
                            this.$scope.Components.payRollGrid.grid.removeRow(this.$scope.Components.payRollGrid.grid.select());
                    });
                });
            }
        }

        openModalForm = (data = null) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("payRoll.modal.html", Apex.Account.Controllers.AddPayRoll.getName(), data, Core.Utils.ModalSize.Small);
            dialog.result.then(r => {
                if (r) {
                    this.$scope.events.refresh();
                }
            });
        }
        payRollDetView = () => {
            var selecedItem = this.$scope.Components.payRollGrid.gridView.selectedItem();
            if (selecedItem) {
                this.openPayRollDetModalForm({ payRoll: selecedItem, editable: selecedItem.Status == 0 && this.$scope.Components.payedPayRollGrid.dataSource.data().length == 0 });
            }
        }
        openPayRollDetModalForm = (data) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("payRollDet.modal.html", Apex.Account.Controllers.PayRollDet.getName(), data, Core.Utils.ModalSize.Large);
            dialog.result.then(r => {
                this.$scope.events.refresh();
            });
        }
        openPayedPayRollModal = (data) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("payedPayRoll.modal.html", Apex.Account.Controllers.PayedPayRollModal.getName(), data, Core.Utils.ModalSize.Small);
            dialog.result.then(r => {
                if (r) {
                    this.refreshPayedPayRoll();
                }
            });
        }

        openPayedPayRollDetModal = (data) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("payedPayRollDet.modal.html", Apex.Account.Controllers.PayedPayRollDet.getName(), data, Core.Utils.ModalSize.Large);
            dialog.result.then(r => {
                if (r) {
                    this.refreshPayedPayRoll();
                }
            });
        }

        initpayedPayRollGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.initpayedPayRollDataSource(),
                    columns: this.payedPayRollColumns,
                    templates: this.templates,
                    settings: { editble: { disableEditRow: true } },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    var dataItem = this.$scope.Components.payedPayRollGrid.gridView.selectedItem();
                                    this.$scope.model.payedPayRoll.selectedPayedPayRoll = dataItem;
                                    // if (dataItem) {
                                    //     // this.getPayedPayRollDet(dataItem.RollId);
                                    // }
                                    this.$scope.disabled.disablePayedPayPopUpBtn = dataItem ? false : true;
                                });
                            }
                        }
                    ]
                };

            return grid;
        }
        initpayedPayRollDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "DDate", aggregate: "count" },
                    { field: "Amount", aggregate: "sum" },
                    { field: "OtherWithholdAmount", aggregate: "sum" },
                    { field: "TaxAmount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fileds: {
                            Salary: { type: "number" },
                            Amount: { type: "number" },
                            TaxPersent: { type: "number" },
                            TaxAmount: { type: "number" }
                        }
                    }
                }
            })
        }

        private payedPayRollColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: "Status",
                titleTranslated: "status",
                filterable: "custom",
                width: 40,
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: "ყველა" },
                            { value: 2, text: "ასახული" },
                            { value: 1, text: "ბლოკირებული" },
                            { value: 0, text: "ჩვეულებრივი" }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",
                        open: (e) => {
                            var listContainer = e.sender.list.closest(".k-list-container");
                           
                            listContainer.width(listContainer.width() + 100);
                        }

                    })
                },
                template: `
                <div ng-if="dataItem.Status==1" style="text-align:center">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                </div>
                <div ng-if="dataItem.Status==2" style="text-align:center">
                    <i class="fa fa-book" aria-hidden="true"></i>
                </div>`
            },
            { field: 'PayNum', titleTranslated: "FNumber", width: 150, format: Core.Kendo.Format.Number },
            {
                field: "DDate", titleTranslated: "DATE", width: 130, format: Core.Kendo.Format.Date,
                footerTemplate: '<span translate=count1></span> #=kendo.toString(data.DDate? data.DDate.count:0) #'
            },
            { field: "Amount", titleTranslated: "HAmount", filterOperator: 'eq', width: 80, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount ? data.Amount.sum.toFixed(2) : 0) #' },
            { field: "TaxAmount", titleTranslated: "incom", filterOperator: 'eq', width: 80, format: Core.Kendo.Format.Price2, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.TaxAmount ? data.TaxAmount.sum.toFixed(2) : 0) #' },
            { field: "OtherWithholdAmount", format: Core.Kendo.Format.Price2, width: 80, titleTranslated: "OtherWithholdAmount", footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.OtherWithholdAmount ? data.OtherWithholdAmount.sum.toFixed(2) : 0) #' },
            { field: "CrTime", titleTranslated: "crtime", format: Core.Kendo.Format.DateTime, width: 150 },
            { field: "Content", titleTranslated: "Contents", width: 250 },
            { field: "CUser", titleTranslated: "user", width: 150 },
        ];

        templates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'Amount', template: '<div class="apx-grid-cell-number">{{::dataItem.Amount| number:2}}</div>' }
        ];


        initpayRollGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.initpayRollDataSource(),
                    columns: this.payRollColumns,
                    settings: { editble: { disableEditRow: true } },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.payRollSelectedChange();
                                });
                            }
                        }
                    ]
                };
            return grid;
        }
        payRollSelectedChange = () => {
            var dataItem = this.$scope.Components.payRollGrid.gridView.selectedItem();
            var fa_eye = $("#tet-view").find("i");

            this.$scope.model.peyRoll.selectedPayRoll = dataItem;
            if (dataItem) {
                this.getPayedPayRoll(dataItem.RollId);
                if (dataItem.Status != "0") {
                    fa_eye.removeClass("fa-book");
                    fa_eye.addClass("fa-eye");
                    this.$scope.Components.popupMenu1.detailBtn.element.find("i").removeClass("fa-book");
                    this.$scope.Components.popupMenu1.detailBtn.element.find("i").addClass("fa-eye");

                    this.$scope.Components.deleteBtn.disable =
                        this.$scope.Components.editBtn.disable = true;
                } else {
                    this.$scope.Components.editBtn.disable = false;
                }
                this.$scope.Components.popupMenu1.cancelIncome.disable =
                    this.$scope.Components.payRolDetBtn.disable = false;


            } else {
                this.$scope.Components.deleteBtn.disable =
                    this.$scope.Components.popupMenu1.cancelIncome.disable =
                    this.$scope.Components.editBtn.disable =
                    this.$scope.Components.payRolDetBtn.disable = true;
                this.$scope.Components.payedPayRollGrid.dataSource.data([]);

            }
        }

        initpayRollDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "PayRollTypeName", aggregate: "count" },
                    { field: "Salary", aggregate: "sum" },
                    { field: "Amount", aggregate: "sum" },
                    { field: "TaxAmaunt", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "RollId",
                        fileds: {
                            RollType: { type: "number" },
                            DDate: { type: "date" },
                            RollNum: { type: "string" },
                            Content: { type: "string" },
                            RollStatus: { type: "string" },
                            CrTime: { type: "date" },
                            CUser: { type: "string" },
                            Salary: { type: "number" },
                            Amount: { type: "number" },
                            TaxAmaunt: { type: "number" } ///???
                        }
                    }
                }
            })
        }

        private payRollColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'RollId', hidden: true },
            {
                field: "Status",
                filterable: "custom",
                titleTranslated: "status",
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: "ყველა" },
                            { value: "2", text: "ასახული" },
                            { value: "1", text: "ბლოკირებული" },
                            { value: "0", text: "ჩვეულებრივი" }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",
                        open: (e) => {
                            var listContainer = e.sender.list.closest(".k-list-container");
                            
                            listContainer.width(listContainer.width() + 100);
                        }
                    })
                },
                width: 40, template: `
            <div ng-if="dataItem.Status==1" style="text-align:center">
                <i class="fa fa-lock" aria-hidden="true"></i>
            </div>
            <div ng-if="dataItem.Status==2" style="text-align:center">
                <i class="fa fa-book" aria-hidden="true"></i>
            </div>
            ` },
            {
                field: 'PayRollTypeName',
                titleTranslated: "roll_type",
                width: 70,
                footerTemplate: '<span translate=count1></span> #=kendo.toString(data.PayRollTypeName? data.PayRollTypeName.count:0) #'
            },
            { field: "RollNum", titleTranslated: "payRollNumber", width: 80 },
            { field: "DDate", titleTranslated: "DATE", width: 100, format: Core.Kendo.Format.Date },
            { field: "Salary", titleTranslated: "accruedSalary", width: 80, format: Core.Kendo.Format.Price2, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Salary ? data.Salary.sum.toFixed(2) : 0) #' },
            { field: "TaxAmaunt", titleTranslated: "incom", width: 80, format: Core.Kendo.Format.Price2, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.TaxAmaunt ? data.TaxAmaunt.sum.toFixed(2) : 0) #' },
            { field: "Amount", titleTranslated: "HAmount", width: 80, format: Core.Kendo.Format.Price2, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount ? data.Amount.sum.toFixed(2) : 0) #' },
            { field: "CrTime", titleTranslated: "crtime", width: 120, format: Core.Kendo.Format.DateTime },
            { field: "Content", titleTranslated: "Contents", width: 150 },
            { field: "RollPeriod", titleTranslated: "rollPeriod", width: 100, format: Core.Kendo.Format.Date },
            { field: "CUser", titleTranslated: "user", width: 80 }
        ];
    }


}