/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {  
    export interface IOperInfo {
        OperID?: string;
        Name?: string;
        DocsTypeID?: string;
        FFormsID?: number;
        DocType?: number;
        Spend?: boolean;
        LAutoNum?: boolean;
        AutoNum?: number;
        Dets?: Array<any>;
        WebFormName?: string;

        templateName?: string;
    }

    export enum FForms {
        GoodInnerBuying = 1024,
        GoodImport = 1014,
        Service = 1050,
        GoodReturn = 1214,
        GoodReturnPresaler=1215,
        Production = 1078,
        GoodInnerMovement = 1006,
        GoodRealization = 1073,
        GoodReturnToSupplier = 1070
    }
} 