/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IGoodInnerBuyingModel {
        debet?: string;
        credit?: string;
        operID?: any;
        crDate?: Date;
        numberIn?: string;
        numberOut?: string;
        isProject?: boolean;
        supplies?: Array<ISupplieModel>;
        waybillNum?: string;
        producer?: string;
        addedSupplie?: any;
    }

    export interface IGoodInnerBuyingScope extends IBaseBookScope {
        recalculation: Function;
        translateProvider: any;
        sameProductsCounter: number;
        goodInnerBuyingModel: IGoodInnerBuyingModel;
        isDisabled?: boolean;
        productsGridOptions: Components.IApxGrid;
        productLookup: Components.IApxLookup;
        countMaskOptions: Components.IApxNumber;
        vatText: string;
        gridOptions: any;
        // filterRow:any;
        // headerFilter:any;
    }

    export class GoodInnerBuying extends BaseBook {
        constructor($scope: IGoodInnerBuyingScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            $scope.recalculation = this.recalculation;
            $scope.goodInnerBuyingModel = this.toGoodInnerBuyingModel($scope.docInfo, data.Operation.OperID);
            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.sameProductsCounter = 0;
            if ($scope.docInfo.DocID) $scope.isDisabled = true;
            else $scope.isDisabled = false;
            $scope.productLookup = this.bcDropdownOptions();
            $scope.$watch('goodInnerBuyingModel.addedSupplie.vat', (vat) => {

                if (vat != undefined) {
                    this.calculateFromVat(this.$scope.goodInnerBuyingModel.addedSupplie, 'vat');
                }
            });
            $scope.$watch('goodInnerBuyingModel.addedSupplie.product', (product: any) => {
                if (product != undefined) {
                    this.$scope.goodInnerBuyingModel.addedSupplie.priceWithVat = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.itemPriceWithoutVat = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.vg = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.count = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.itemPrice = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.productVat = 0;
                    this.$scope.goodInnerBuyingModel.addedSupplie.markerCode = '';
                    this.$scope.goodInnerBuyingModel.addedSupplie.lifedate = '';
                    if (product.Vat != undefined) {
                        this.$scope.goodInnerBuyingModel.addedSupplie.vat = product.Vat * 1;
                        this.calculateFromVat(this.$scope.goodInnerBuyingModel.addedSupplie, 'vat');
                    }
                    if (product.PackCount != undefined) {
                        if (product.PackCount === 1) {
                            $scope.countMaskOptions = { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false };

                        } else {
                            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
                        }
                    }
                }
            });

            $scope.$watch('vat', (vat: number) => {
                if (vat != undefined) {
                    this.$scope.vatText = (this.$scope.vat * 100).toString() + '%';
                }
            });

            if (!$scope.isDisabled)
                $scope.goodInnerBuyingModel.supplies = [];

            $scope.saveDoc = this.saveDoc;
            $scope.goodInnerBuyingModel.addedSupplie = {
                prodPPId: '',
                count: 0,
                vat: 0,
                itemPriceWithoutVat: 0,
                priceWithVat: 0,
                vg: 0,
                itemPrice: 0,
                productVat: 0
            };

            $scope.goodInnerBuyingModel.supplies

            $scope.productsGridOptions = this.productGridOption($scope.goodInnerBuyingModel.supplies);

            this.$timeout(() => {
                $('.k-button.k-button-icontext.k-grid-edit').click(() => {
                });
            });
            //             'bCode', titleTranslated:
            // 'prodName', titleTranslat
            // 'categoryName', titleTran
            // 'producer', titleTranslat
            // 'unit', titleTranslated: 
            // 'lifedate', titleTranslat
            // 'markerCode', titleTransl
            // 'count', titleTranslated:
            // 'vg', titleTranslated: 'v
            // 'vat', titleTranslated: '
            // 'itemPriceWithoutVat', ti
            // 'itemPrice', titleTransla
            // 'priceWithVat', titleTran
            // 'vatG', titleTranslated: 
            // $scope.filterRow = {
            //     visible: true,
            //     applyFilter: "auto"
            // };

            // $scope.headerFilter = {
            //     visible: true
            // };
            // $scope.gridOptions = {
            //     dataSource: $scope.goodInnerBuyingModel.supplies,
            //     selection: {
            //         mode: "single"
            //     },
            //     bindingOptions: {
            //         filterRow: "filterRow",
            //         headerFilter: "headerFilter"
            //     },
            //     // searchPanel: {
            //     //     visible: true,
            //     //     width: 240,
            //     //     placeholder: "Search..."
            //     // },  
            //     sorting: {
            //         mode: "multiple"
            //     },
            //     hoverStateEnabled: true,
            //     columns: [ 
            //     "bCode",
            //     "prodName", 
            //     {
            //         dataField: "categoryName",
            //         caption:this.$scope.translateProvider.instant("categoryName"),
            //         width: 180
            //     }, {
            //         dataField: "producer",
            //         caption:this.$scope.translateProvider.instant("producer"),
            //         dataType: "string"
            //     }, {
            //         dataField: "unit",
            //         caption:this.$scope.translateProvider.instant("unit"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "lifedate",
            //         caption:this.$scope.translateProvider.instant("lifeDate"),
            //         dataType: "date"
            //     }
            //     ,{
            //         dataField: "markerCode",
            //         caption:this.$scope.translateProvider.instant("goodMarkerCode"),
            //         dataType: "string"
            //     }
            //     ,{
            //         dataField: "count",
            //         caption:this.$scope.translateProvider.instant("Count"),
            //         dataType: "nunber"
            //     }
            //     ,{
            //         dataField: "vg",
            //         caption:this.$scope.translateProvider.instant("vg"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "vat",
            //         caption:this.$scope.translateProvider.instant("vat"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "itemPriceWithoutVat",
            //         caption:this.$scope.translateProvider.instant("ItemPriceWithoutVat"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "itemPrice",
            //         caption:this.$scope.translateProvider.instant("ItemPrice"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "priceWithVat",
            //         caption:this.$scope.translateProvider.instant("PriceWithVat"),
            //         dataType: "number"
            //     }
            //     ,{
            //         dataField: "vatG",
            //         caption:this.$scope.translateProvider.instant("vatG"),
            //         dataType: "number"
            //     }
            //     ]
            //     // ,
            //     // onSelectionChanged: function (selectedItems) {
            //     //     $scope.selectedEmployee = selectedItems.selectedRowsData[0];
            //     // }
            // };

            this.$timeout(() => {
                $('.k-button.k-button-icontext.k-grid-edit').click(() => {
                    this.$scope.isBusy = true;
                });
                $('#multiDelete').on('click', () => {
                    this.multiDelete();
                });

            });
        }

        $scope: IGoodInnerBuyingScope;

        multiDelete = () => {
            var selectedRows = this.$scope.productsGridOptions.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: ISupplieModel = this.$scope.productsGridOptions.grid.dataItem(item).toJSON();
                    for (var i = 0; i < this.$scope.goodInnerBuyingModel.supplies.length; i++) {
                        if (this.$scope.goodInnerBuyingModel.supplies[i].supplieID === model.supplieID) {
                            this.$scope.goodInnerBuyingModel.supplies.splice(i, 1);
                            break;
                        }
                    }
                });
                this.$scope.productsGridOptions.grid.dataSource.data(this.$scope.goodInnerBuyingModel.supplies);
                this.makeFormDirty();
            });
        }

        suppliesDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate:
                [
                    { field: "vg", aggregate: "sum" },
                    { field: "count", aggregate: "sum" },
                    { field: "vatG", aggregate: "sum" },
                    { field: "priceWithVat", aggregate: "sum" },
                    { field: "itemPrice", aggregate: "sum" },
                    { field: "itemPriceWithoutVat", aggregate: "sum" },
                    { field: "bCode", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "supplieID",
                        fields: {
                            prodName: { editable: false, type: "string" },
                            unit: { editable: false, type: "string" },
                            producer: { editable: false, type: "string" },
                            bCode: { editable: false, type: "string" },
                            categoryName: { editable: false, type: "string" },
                            markerCode: { type: "string" },
                            count: { type: "number" },
                            vg: { type: "number" },
                            vat: { type: "number" },
                            itemPriceWithoutVat: { type: "number" },
                            itemPrice: { type: "number" },
                            priceWithVat: { type: "number" },
                            vatG: { type: "number", editable: false },
                            lifedate: { type: "date" }
                        }
                    }
                }
            });
            return ds;
        }

        bcDropdownOptions = () => {
            var pProductsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 120, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', titleTranslated: 'product' }
                    ],
                    dataSource: this.getDropDownDs(),
                    valueText: 'ProductsNu'
                }

            return pProductsLookup;
        }

        getDropDownDs = () => {
            var dataSourceModel = {
                fields: {
                    BCode: { type: 'string' },
                    ProductsNu: { type: 'string' }
                }
            }
            var r = Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.productsQueryUrl, dataSourceModel);

            return r;
        }

        getPartial(): string {
            return 'modalsgood.inner.buying.html';
        }

        private productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'markerCode', titleTranslated: 'goodMarkerCode', width: 90, editable: true },
            { field: 'bCode', titleTranslated: 'barcode', width: 120, footerTemplate: '<span translate=none> </span> #= kendo.toString(data.bCode ? data.bCode.count : 0) #' },
            { field: 'prodName', titleTranslated: 'caption', width: 250 },
            { field: 'vat', titleTranslated: 'VatWithPercentage', width: 50, editable: true, format: Core.Kendo.Format.Percent },
            { field: 'unit', titleTranslated: 'unit', width: 90 },
            { field: 'count', titleTranslated: 'Count', width: 70, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.count && data.count.sum ? parseFloat(data.count.sum).toFixed(4) : 0) #' },
            { field: 'itemPriceWithoutVat', titleTranslated: 'itemCost1', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.itemPriceWithoutVat &&  data.itemPriceWithoutVat.sum ? parseFloat(data.itemPriceWithoutVat.sum).toFixed(4) : 0) #' },
            { field: 'itemPrice', titleTranslated: 'lastCost', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.itemPrice && data.itemPrice.sum ? parseFloat(data.itemPrice.sum).toFixed(4) : 0) #' },
            { field: 'vg', titleTranslated: 'cst', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vg ? parseFloat(data.vg.sum).toFixed(4) : 0) #' },
            { field: 'vatG', titleTranslated: 'vatG', width: 90, editable: false, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vatG && data.vatG.sum ? parseFloat(data.vatG.sum).toFixed(4) : 0) #' },
            { field: 'priceWithVat', titleTranslated: 'amount', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.priceWithVat && data.priceWithVat.sum ? parseFloat(data.priceWithVat.sum).toFixed(4) : 0) #' },
            { field: 'lifedate', titleTranslated: 'lifeDate', editable: true, width: 80, format: Core.Kendo.Format.Date },
            { field: 'categoryName', titleTranslated: 'categoryName', width: 200 },
            { field: 'producer', titleTranslated: 'producer', width: 200 }


        ];

        private productsGridTempalate: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'vat', editor: (container: JQuery, options: any) => {
                    $('<input data-text-field="VatText" data-value-field="Id" data-bind="value:' + options.field + '"/>')
                        .appendTo(container)
                        .kendoDropDownList({
                            dataSource: [{ Id: 0, VatText: '0%' }, { Id: this.$scope.vat, VatText: this.$scope.vatText }],
                            dataTextField: "VatText",
                            dataValueField: "Id"
                        });
                }
            },
            {
                fieldName: 'lifedate',
                editor(container, options) {
                    $('<apx-date  ng-model="dataItem.' + options.field + '" preventDefaultValueOnFocusOut></apx-date>')
                        .appendTo(container);
                }
            }
        ];

        addEventFunctionality = (e) => {
            if (e.model != undefined && !e.model.isNew()) {
                var focusedRowModel: ISupplieModel = e.model;

                this.calculationOnEditEvent(e, e.values, focusedRowModel);

                e.sender.dataSource.sync();

                this.makeFormDirty();
            }
            else {
                if (!this.$scope.goodInnerBuyingModel.addedSupplie.product) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseProductAlert')));
                    e.preventDefault();
                    return;
                }
                if (Number(this.$scope.goodInnerBuyingModel.addedSupplie.itemPrice) == 0 ||
                    Number(this.$scope.goodInnerBuyingModel.addedSupplie.itemPriceWithoutVat) == 0 ||
                    Number(this.$scope.goodInnerBuyingModel.addedSupplie.priceWithVat) == 0 ||
                    Number(this.$scope.goodInnerBuyingModel.addedSupplie.itemPriceWithoutVat) == 0) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'შეუძლებელია ჩანაწერის დამატება ნულოვანი მნიშვნელობით'));
                    e.preventDefault();
                    return;
                }
                else {
                    this.initSomeProperties(this.$scope.goodInnerBuyingModel.addedSupplie);
                    this.$scope.goodInnerBuyingModel.supplies.unshift(this.$scope.goodInnerBuyingModel.addedSupplie);
                    this.makeFormDirty();
                    ++this.$scope.sameProductsCounter;
                    this.$scope.productsGridOptions.grid.dataSource.read();
                    this.$scope.productsGridOptions.grid.refresh();
                    this.$scope.goodInnerBuyingModel.addedSupplie = {
                        prodPPId: '',
                        lifedate: '',
                        count: 0,
                        vat: 0,
                        itemPriceWithoutVat: 0,
                        priceWithVat: 0,
                        vg: 0,
                        itemPrice: 0,
                        productVat: 0
                    };

                    // this.$timeout(() => {
                    //     $('.k-button.k-button-icontext.k-grid-edit').trigger('click');
                    // });

                }
            }

        }

        initSomeProperties = (currentSupplie: ISupplieModel): void => {
            currentSupplie.vatG = this.$scope.goodInnerBuyingModel.addedSupplie.vg * (Number(this.$scope.goodInnerBuyingModel.addedSupplie.vat));
            currentSupplie.productVat = this.$scope.goodInnerBuyingModel.addedSupplie.productVat;
            currentSupplie.prodPPId = this.$scope.goodInnerBuyingModel.addedSupplie.product.ProdPPId;
            currentSupplie.packCount = this.$scope.goodInnerBuyingModel.addedSupplie.product.PackCount;
            currentSupplie.producer = this.$scope.goodInnerBuyingModel.addedSupplie.product.ProducerNu;
            currentSupplie.categoryName = this.$scope.goodInnerBuyingModel.addedSupplie.product.CategoryNu;
            currentSupplie.unit = this.$scope.goodInnerBuyingModel.addedSupplie.product.Unit;
            currentSupplie.prodName = this.$scope.goodInnerBuyingModel.addedSupplie.product.ProductsNu;
            currentSupplie.bCode = this.$scope.goodInnerBuyingModel.addedSupplie.product.BCode;
            currentSupplie.supplieID = this.$scope.goodInnerBuyingModel.addedSupplie.prodPPId + '_' + this.$scope.sameProductsCounter;
        }

        saveDoc = () => {
            var modalBook = this.$scope.formScope.modalBook;
            if (modalBook.$invalid) {
                this.toaster.pop('error', this.translate.instant('DIALOGS_ERROR'), 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი !');
                return;
            }
            var model = this.$scope.goodInnerBuyingModel;

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosewarehouseandsupplier'));
                return;
            }
            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosewarehouse'));
                return;
            }
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosesupplier'));
                return;
            }

            var doc: any = {
                DB: this.$scope.baseModel.debet.Acc,
                CR: this.$scope.baseModel.credit.Acc,
                DDate: model.crDate,
                Operid: model.operID,
                NumberIn: model.numberIn,
                NumberOut: model.numberOut,
                WaybillNum: model.waybillNum,
                IsProject: model.isProject
            }
            if (!this.$scope.isCopied)
                doc.DocID = this.$scope.baseModel.docID;
            doc.Supplies = [];

            var supplies = this.$scope.productsGridOptions.grid.dataSource.data();

            _.forEach(supplies, (item: ISupplieModel) => {
                if (item.count && item.count != 0) {
                    var supplie: Interfaces.ISupplie = {};
                    supplie.ICount = item.count;
                    supplie.Vg = item.vg;

                    if (item.supplieID.length > 10)
                        supplie.ID = null;
                    else {
                        if (!this.$scope.isCopied)
                            supplie.ID = item.supplieID;
                    }
                    supplie.ProdPP_id = item.prodPPId;

                    if (_.isDate(item.lifedate))
                        supplie.LifeDate = item.lifedate.toJSON();

                    supplie.Vat = Number(item.vat);
                    supplie.Code = item.markerCode.replace(/\s/g, "");

                    doc.Supplies.push(supplie);
                }
            });

            if (doc.Supplies.length === 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('productlistisempty'));
                return;
            }

            this.saveDocument(doc);
        }

        cancelEventFunctionality = (e: kendo.ui.GridEvent) => {
            this.$scope.goodInnerBuyingModel.addedSupplie = {
                prodPPId: '',
                lifedate: '',
                count: 0,
                vat: 0,
                itemPriceWithoutVat: 0,
                priceWithVat: 0,
                vg: 0,
                itemPrice: 0
            };

            this.$timeout(() => { this.$scope.isBusy = false; });
        }
        private getAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addEventFunctionality
                };
            return addEvent;
        }


        private getDeleteEvent(): Components.IEvent {
            var deleteEventType: Components.EventType = Components.EventType.Delete;
            var deleteEvent: Components.IEvent =
                {
                    eType: deleteEventType,
                    eFunction: this.deleteEventFunctionality
                };
            return deleteEvent;
        }

        private deleteEventFunctionality = (e) => {
            var deletedRow = e.model;

            for (var i = 0; i < this.$scope.goodInnerBuyingModel.supplies.length; i++) {
                if (this.$scope.goodInnerBuyingModel.supplies[i].supplieID === deletedRow.supplieID) {
                    this.$scope.goodInnerBuyingModel.supplies.splice(i, 1);
                    break;
                }
            }
        }

        private getCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.cancelEventFunctionality
                };
            return cancelEvent;
        }

        private calculateFromCountOrItemPriceWithoutVat(model: ISupplieModel, val: string): void {
            if (model.count !== 0 && model.itemPriceWithoutVat !== 0 && model.itemPriceWithoutVat != undefined) {
                if (val != 'vg')
                    model.vg = parseFloat((model.count * model.itemPriceWithoutVat).toFixed(4));
                if (val != 'count')
                    model.count = Number(model.count);
                if (val != 'itemPriceWithoutVat')
                    model.itemPriceWithoutVat = Number(model.itemPriceWithoutVat);
                if (val != 'priceWithVat')
                    model.priceWithVat = parseFloat((model.vg + model.vg * Number(model.vat)).toFixed(4));
                if (val != 'itemPrice')
                    model.itemPrice = parseFloat((parseFloat(model.itemPriceWithoutVat.toString()) + parseFloat((model.itemPriceWithoutVat * Number(model.vat)).toString())).toFixed(4));
            }
            else {
                model.vg = model.itemPrice = model.priceWithVat = 0;
            }
        }

        private calculateFromItemPrice(model: ISupplieModel, val: string): void {
            if (val != 'itemPriceWithoutVat')
                model.itemPriceWithoutVat = parseFloat((model.itemPrice / (1 + Number(model.vat))).toFixed(4));
            if (val != 'vg')
                model.vg = parseFloat((model.count * model.itemPriceWithoutVat).toFixed(4));
            if (val != 'priceWithVat')
                model.priceWithVat = parseFloat((model.vg + model.vg * Number(model.vat)).toFixed(4));
            if (val != 'itemPrice')
                model.itemPrice = Number(model.itemPrice);
            if (val != 'count')
                model.count = Number(model.count);
        }

        private calculateFromVat(model: ISupplieModel, val: string): void {
            if (model.vat != undefined) {
                if (val != 'itemPriceWithoutVat')
                    model.itemPriceWithoutVat = parseFloat((model.itemPrice / (1 + Number(model.vat))).toFixed(4));
                if (val != 'vg')
                    model.vg = parseFloat((model.count * model.itemPriceWithoutVat).toFixed(4));
                if (val != 'vat')
                    model.vat = Number(model.vat);
                if (val != 'count')
                    model.count = Number(model.count);
                if (val != 'itemPrice')
                    model.itemPrice = Number(model.itemPrice);
            } else {
                if (val != 'itemPrice')
                    model.itemPrice = model.priceWithVat = 0;
            }
        }

        private calculateFromVg(model: ISupplieModel, val: string): void {

            if (model.vg != undefined && model.count != undefined && model.count !== 0) {
                if (val != 'itemPriceWithoutVat')
                    model.itemPriceWithoutVat = parseFloat((model.vg / model.count).toFixed(4));
                if (val != 'itemPrice')
                    model.itemPrice = parseFloat((model.itemPriceWithoutVat + model.itemPriceWithoutVat * model.vat * 1).toFixed(4));
                if (val != 'priceWithVat')
                    model.priceWithVat = parseFloat(parseFloat((parseFloat(model.vg.toString()) + parseFloat(model.vg.toString()) * parseFloat(model.vat.toString()) * 1).toString()).toFixed(4));
                if (val != 'vg')
                    model.vg = Number(model.vg);
                model.count = Number(model.count);
            } else {
                if (val != 'itemPrice')
                    model.itemPrice = model.itemPriceWithoutVat = model.priceWithVat = 0;
            }
        }

        private calculateFromPriceWithVat(model: ISupplieModel, val: string): void {
            if (model.count != undefined && model.count !== 0) {
                if (val != 'itemPrice')
                    model.itemPrice = parseFloat((model.priceWithVat / model.count).toFixed(4));
                if (val != 'itemPriceWithoutVat')
                    model.itemPriceWithoutVat = parseFloat((model.itemPrice / (1 + Number(model.vat))).toFixed(4));
                if (val != 'vg')
                    model.vg = parseFloat((model.itemPriceWithoutVat * model.count).toFixed(4));
                if (val != 'priceWithVat')
                    model.priceWithVat = Number(model.priceWithVat);
                if (val != 'count')
                    model.count = Number(model.count);
            } else {
                model.priceWithVat = 0;
            }
        }

        recalculation = (keycode, val: string): void => {

            if (keycode == 110 || keycode == 188 || keycode == 190)
                return;


            var model: ISupplieModel = this.$scope.goodInnerBuyingModel.addedSupplie;

            switch (val) {
                case 'vat':
                    {
                        this.calculateFromVat(model, val);
                        if (keycode != undefined && keycode === 13) {
                        }

                        break;
                    }
                case 'count':
                case 'itemPriceWithoutVat':
                    {
                        this.calculateFromCountOrItemPriceWithoutVat(model, val);
                        break;
                    }
                case 'itemPrice':
                    {
                        this.calculateFromItemPrice(model, val);
                        break;
                    }
                case 'vg':
                    {
                        this.calculateFromVg(model, val);
                        break;
                    }
                case 'priceWithVat':
                    {
                        this.calculateFromPriceWithVat(model, val);
                        break;
                    }
            }

        }
        private calculationOnEditEvent(e: any, changedValues: any, model: ISupplieModel): void {

            switch (null) {

                case changedValues.count:
                    e.preventDefault();
                    return;
                case changedValues.itemPrice:
                    e.preventDefault();
                    return;
                case changedValues.vg:
                    e.preventDefault();
                    return;
                case changedValues.itemPriceWithoutVat:
                    e.preventDefault();
                    return;
                case changedValues.priceWithVat:
                    e.preventDefault();
                    return;
            }


            if (changedValues.count != undefined) {
                model.vg = parseFloat((parseFloat(model.itemPriceWithoutVat.toString()) * changedValues.count).toFixed(4));
                model.priceWithVat = parseFloat((parseFloat(model.itemPrice.toString()) * changedValues.count).toFixed(4));
                model.vatG = parseFloat((model.vg * Number(model.vat)).toFixed(4));
                return;
            }
            if (changedValues.itemPriceWithoutVat != undefined) {
                model.vg = parseFloat((parseFloat(model.count.toString()) * changedValues.itemPriceWithoutVat).toFixed(4));
                model.priceWithVat = parseFloat((parseFloat(model.vg.toString()) + parseFloat(model.vg.toString()) * parseFloat(Number(model.vat).toString())).toFixed(4));
                model.itemPrice = parseFloat((parseFloat(changedValues.itemPriceWithoutVat.toString()) + parseFloat(changedValues.itemPriceWithoutVat.toString()) * parseFloat(Number(model.vat).toString())).toFixed(4));
                model.vatG = parseFloat((model.vg * Number(model.vat)).toFixed(4));
                return;
            }
            if (changedValues.vat != undefined) {
                model.itemPriceWithoutVat = parseFloat((model.itemPrice / (1 + Number(changedValues.vat))).toFixed(4));
                model.vg = parseFloat((model.count * model.itemPriceWithoutVat).toFixed(4));
                model.vatG = parseFloat((model.vg * Number(changedValues.vat)).toFixed(4));
                return;
            }
            if (changedValues.itemPrice != undefined) {
                model.itemPriceWithoutVat = parseFloat((changedValues.itemPrice / (1 + Number(model.vat))).toFixed(4));
                model.vg = parseFloat((model.count * model.itemPriceWithoutVat).toFixed(4));
                model.priceWithVat = parseFloat((model.vg + model.vg * Number(model.vat)).toFixed(4));
                model.vatG = parseFloat((model.vg * Number(model.vat)).toFixed(4));
                return;
            }
            if (changedValues.vg != undefined) {
                model.itemPriceWithoutVat = parseFloat((changedValues.vg / model.count).toFixed(4));
                model.itemPrice = parseFloat((Number(model.itemPriceWithoutVat) + (Number(model.itemPriceWithoutVat) * (Number(model.vat)))).toFixed(4));
                model.priceWithVat = parseFloat((Number(changedValues.vg) + (changedValues.vg * Number(model.vat))).toFixed(4));
                model.vatG = parseFloat((changedValues.vg * Number(model.vat)).toFixed(4));
                return;
            }
            if (changedValues.priceWithVat != undefined) {
                if (model.count === undefined || model.count === 0) {
                    e.preventDefault();
                    return;
                } else {
                    model.itemPrice = parseFloat((changedValues.priceWithVat / model.count).toFixed(4));
                    model.itemPriceWithoutVat = parseFloat((model.itemPrice / (1 + Number(model.vat))).toFixed(4));
                    model.vg = parseFloat((model.itemPriceWithoutVat * model.count).toFixed(4));
                    model.vatG = parseFloat((model.vg * Number(model.vat)).toFixed(4));
                    return;
                }

            }

        }

        exportToExcelFunctionality = (e) => {
            var rows = e.workbook.sheets[0].rows;
            for (var ri = 0; ri < rows.length; ri++) {
                var row = rows[ri];
                if (row.type === "header") {
                    _.forEach(row.cells, (item: any) => {
                        item = this.initExcelCaptions(item);
                    });
                }
                if (row.type === "group-footer" || row.type === "footer") {
                    for (var ci = 0; ci < row.cells.length; ci++) {
                        var cell = row.cells[ci];
                        if (cell.value) {
                            cell.value = '';
                        }
                    }
                }
            }
        }

        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        initExcelCaptions = (column: any): any => {
            if (column.value.includes("'barcode'")) {
                column.value = this.$scope.translateProvider.instant('barcode');
                return column;
            }
            if (column.value.includes("'product'")) {
                column.value = this.$scope.translateProvider.instant('product');
                return column;
            }
            if (column.value.includes("'categoryName'")) {
                column.value = this.$scope.translateProvider.instant('categoryName');
                return column;
            }
            if (column.value.includes("'producer'")) {
                column.value = this.$scope.translateProvider.instant('producer');
                return column;
            }
            if (column.value.includes("'unit'")) {
                column.value = this.$scope.translateProvider.instant('unit');
                return column;
            }
            if (column.value.includes("'lifeDate'")) {
                column.value = this.$scope.translateProvider.instant('lifeDate');
                return column;
            }
            if (column.value.includes("'goodMarkerCode'")) {
                column.value = this.$scope.translateProvider.instant('goodMarkerCode');
                return column;
            }
            if (column.value.includes("'Count'")) {
                column.value = this.$scope.translateProvider.instant('Count');
                return column;
            }
            if (column.value.includes("'vg'")) {
                column.value = this.$scope.translateProvider.instant('vg');
                return column;
            }
            if (column.value.includes("'vat'")) {
                column.value = this.$scope.translateProvider.instant('vat');
                return column;
            }
            if (column.value.includes("'ItemPriceWithoutVat'")) {
                column.value = this.$scope.translateProvider.instant('ItemPriceWithoutVat');
                return column;
            }
            if (column.value.includes("'ItemPrice'")) {
                column.value = this.$scope.translateProvider.instant('ItemPrice');
                return column;
            }
            if (column.value.includes("'PriceWithVat'")) {
                column.value = this.$scope.translateProvider.instant('PriceWithVat');
                return column;
            }
            if (column.value.includes("'vatG'")) {
                column.value = this.$scope.translateProvider.instant('vatG');
                return column;
            }

            return column;
        }


        private productGridOption(datasource: Array<ISupplieModel>): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: this.productsGridColumns,
                    templates: this.productsGridTempalate,
                    dataSource: this.suppliesDatasource(datasource),
                    settings:
                    {
                        selectable: "multiple",
                        gridButtons: {
                            multiDelete: { enabled: true },
                            add: { enabled: true },
                            exportToExcell: true
                        },
                        height: 500,
                        editble: {
                            popupTemplateID: 'good_inner_buying_popup_editor',
                            popupWidth: 750
                        }
                    },
                    parentScope: this.$scope,
                    gridEvents: [
                        this.getAddEvent(),
                        this.getCancelEvent(),
                        this.getExportToExcelEvent(),
                        this.getEditEvent()
                    ]
                };
            return gridOptions;
        }

        toGoodInnerBuyingModel(docInfo: Interfaces.IDoc, operid: any) {
            var innerBuyingModel: IGoodInnerBuyingModel = {};
            if (!!docInfo.DocID) {
                innerBuyingModel.crDate = this.$scope.baseModel.crDate;
                innerBuyingModel.numberIn = docInfo.NumberIn;
                innerBuyingModel.numberOut = docInfo.NumberOut;
                innerBuyingModel.debet = docInfo.DB;
                innerBuyingModel.credit = docInfo.CR;
                innerBuyingModel.isProject = docInfo.IsProject;
                innerBuyingModel.supplies = [];
                innerBuyingModel.waybillNum = docInfo.WaybillNum;
                _.forEach(docInfo.Supplies, (item: Interfaces.ISupplie) => {
                    innerBuyingModel.supplies.push(
                        {
                            prodPPId: item.ProdPP_id,
                            categoryName: item.Product.Category.CategoryName,
                            vg: item.Vg,
                            itemPriceWithoutVat: parseFloat((item.Vg / item.ICount).toFixed(4)),
                            producer: item.Product.Producer.ProducerName,
                            count: item.ICount,
                            unit: item.Product.Unit,
                            packCount: item.Product.PackCount,
                            itemPrice: parseFloat(((item.Vg / item.ICount) + ((item.Vg / item.ICount) * item.Vat)).toFixed(4)),
                            priceWithVat: parseFloat((item.Vg + (item.Vg * item.Vat)).toFixed(4)),
                            prodName: item.Product.Name,
                            bCode: item.Product.BCode,
                            supplieID: item.ID,
                            vat: Number(item.Vat),
                            lifedate: this.todate(item.LifeDate),
                            markerCode: item.Code,
                            productVat: item.Product.Vat,
                            vatG: parseFloat((item.Vg * item.Vat).toFixed(4))
                        });
                });

            } else {
                innerBuyingModel.crDate = new Date();
            }
            innerBuyingModel.operID = operid;
            return innerBuyingModel;
        }
    }
}