/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IBaseBookScope extends angular.IScope {
        docInfo: Interfaces.IDoc;
        isCopied?: boolean;
        modalInstance: any;
        vat: number;
        openExpensesForm: Function;
        formName: string;
        formHtml: string;
        booksGridOptions: Components.IApxGrid;
        rates: { rate; rateC };
        formScope: { modalBook };
        setForm: Function;
        saveDoc: Function;
        getPartial: Function;
        closeDoc: Function;
        todate: Function;
        makeFormDirty: Function;
        baseComponents: {
            debet: Components.IApxLookup;
            credit: Components.IApxLookup;
        };
        baseModel: IBaseModel;
        debetDataSource: any;
        creditDataSource: any;
        report: {
            isPrintable?: boolean;
            printCurrentDoc?: any;
            availableReports?: any;
        }
        isBusy: boolean;
        isDirty: boolean;
    }
    export interface IBaseModel {
        debet?: Interfaces.IAccountInfo;
        credit?: Interfaces.IAccountInfo;
        docID?: string;
        crDate?: Date;
        numberIn?: string;
        numberOut?: string;
        operId?: string;
        formsId?: number;
        isProject?: boolean;
        currencyID?: string;
        isBlocked?: boolean;
    }

    export interface ISupplieModel {
        prodPPId?: string;
        bCode?: string;
        packCount?: number;
        producer?: string;
        unit?: string;
        count?: number;
        vg?: number;
        vc?: number;
        vu?: number;
        vat?: number;
        prodName?: string;
        supplieID?: string;
        categoryName?: string;
        itemPriceWithoutVat?: number;
        priceWithVat?: number;
        itemPrice?: number;
        lifedate?: Date;
        product?: Interfaces.IGood;
        markerCode?: string;
        productVat?: number;
        vatG?: number;
    }

    export interface IServedModel {
        ID?: string;
        Name?: string;
        Code?: string;
        Vg?: number;
        Vc?: number;
        VgInfo?: number;
        PriceC?: number;
        PriceG?: number;
        ServiceID?: any;
        ICount?: any;
        Vat?: number;
        Discount?: number;
    }

    export class BaseBook extends Core.Controller {

        public static $inject = [
            Globals.scope, 'data', 'accountService', Globals.modalInstance, Globals.toaster, Globals.http
        ];

        formData: any;

        $scope: IBaseBookScope;
        data: any;
        accountService: Services.Account;
        $uibModalInstance: any;
        toaster: any;
        translate: any;
        $timeout: any;
        constructor($scope: IBaseBookScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, private $http: angular.IHttpService) {
            super(arguments, BaseBook.$inject);
            this.$scope = $scope;
            this.accountService = accountService;
            this.$scope.docInfo = data.DocInfo;
            this.$timeout = Core.Utils.Helper.getServiceFromJs(Globals.$timeout);
            this.$scope.vat = data.Vat;
            this.toaster = toaster;
            this.$uibModalInstance = $uibModalInstance;
            this.translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.isBusy = false;
            $scope.isCopied = data.isCopied;
            $scope.isDirty = false;
            $scope.report = {
                isPrintable: false
            }

            var operation: Interfaces.IOperInfo = data.Operation;
           
             $scope.formName = operation.OperID != undefined ? operation.OperID + ' - ' + (operation.Name != undefined ? operation.Name : "") : (operation.Name != undefined ? operation.Name : "");
           
            $scope.setForm = (formScope) => {
                this.$timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            }; 
            
            $scope.baseModel = this.toBaseModel($scope.docInfo, operation, $scope.isCopied);
            $scope.todate = this.todate;
            $scope.closeDoc = this.closeDoc;
            $scope.getPartial = this.getPartial;
            $scope.makeFormDirty = this.makeFormDirty;
            $scope.openExpensesForm = this.openExpensesForm;
            var dataSourceIsLoaded = false;
            $scope.booksGridOptions = this.booksGridOptions($scope.docInfo.Books, operation.FFormsID);


            var openLookupEvent = () => {
                if (!dataSourceIsLoaded)
                    accountService.getAccountsByOperID(data.Operation.OperID).then(a => {
                        if (operation.FFormsID === Interfaces.FForms.GoodImport) {                                
                            $scope.creditDataSource = a.CRAccounts;
                            $scope.baseComponents.credit.dataSource = _.filter($scope.creditDataSource, (item: any) => (item.Acc.slice(-2) === $scope.baseModel.currencyID)).toDatasource();
                        }
                        else {
                            $scope.baseComponents.credit.dataSource = a.CRAccounts.toDatasource();
                        }
                        $scope.baseComponents.debet.dataSource = a.DBAccounts.toDatasource();
                        dataSourceIsLoaded = true;
                        return a;
                    });
            }

            $scope.baseComponents = {
                debet: {
                    valueText: 'AccWithName',
                    columns: this.devebCreditColumns,
                    dataSource: [].toDatasource(),
                    openEvent: openLookupEvent
                },
                credit: {
                    valueText: 'AccWithName',
                    columns: this.devebCreditColumns,
                    dataSource: [].toDatasource(),
                    openEvent: openLookupEvent
                }
            };

            if (!$scope.isCopied && !!data.DocInfo.DocID) {
                accountService.getAvailableReports(data.Operation.OperID).then(a => {                    
                    $scope.report.availableReports = a.Result;
                    if ($scope.report.availableReports !== undefined && $scope.report.availableReports !== null
                        && $scope.report.availableReports.length > 0) {
                        $scope.report.isPrintable = true;
                    }
                });
            }
            $scope.report.printCurrentDoc = this.printCurrentDoc;
            // todo Global ESC event in dialogs

            $(document).keydown(this.closeHandle);

        }

        closeHandle = (e) => {
            if (e.keyCode === 27 && !this.$scope.isBusy) {
                this.closeDoc();
            }
        }

        printCurrentDoc = () => {
            var rp = this.$scope.report.availableReports[0]; // todo List of reports
            this.accountService.printReport(rp.ReportId, rp.LayoutId,"@docs_id", this.$scope.baseModel.docID);
        }

        devebCreditColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Acc', titleTranslated: 'acc', width: 110 },
            { field: 'Name', titleTranslated: 'nameCaption' }
        ];

        getPartial(): string {
            throw ("Template name is empty! ");
        }

        static toDate(d) {
            if (!!d) {
                if (d.getMonth)
                    return d;
                var myDate = new Date(parseInt(d.replace('/Date(', '')));
                return myDate;
            }
        }

        private toBaseModel(docInfo: Interfaces.IDoc, operation: Interfaces.IOperInfo, isCopied: boolean) {
            var baseModel: IBaseModel = {};
            baseModel.isBlocked = docInfo.IsBlocked;
            baseModel.operId = operation.OperID;
            baseModel.formsId = operation.FFormsID;
            baseModel.crDate = new Date();
            if (!!docInfo.DocID) {
                if (!isCopied) {
                    var dt: string = docInfo.DDate.toString();
                    baseModel.docID = docInfo.DocID;
                    baseModel.crDate = new Date(parseInt(dt.replace('/Date(', '')));
                    baseModel.numberIn = docInfo.NumberIn;
                    baseModel.numberOut = docInfo.NumberOut;
                }
              //  baseModel.blocking: docInfo.Books[0].Blocking,
                baseModel.isProject = docInfo.IsProject;
                baseModel.debet = docInfo.DBView;
                baseModel.credit = docInfo.CRView;
                baseModel.currencyID = docInfo.Currency_id;
            }
            return baseModel;
        }

        todate(d) {
            if (!!d) {
                if (d.getMonth)
                    return d;
                var myDate = new Date(parseInt(d.replace('/Date(', '')));
                return myDate;
            }
            return null;
        }


        getEditEvent(): Components.IEvent {
            var editEventType: Components.EventType = Components.EventType.Edit;
            var editEvent: Components.IEvent =
                {
                    eType: editEventType,
                    eFunction: this.editEventFunctionality
                };
            return editEvent;
        }

        editEventFunctionality = (e: any) => {
            if (!e.model.isNew()) {
                if (e.model.packCount === 1) {
                    var options: any = { numeric: true, min: 0, allowMinus: false }
                    var count = $(".apx-col-edit-count").find("input").inputmask('integer', options);
                }
            }
        }

        private booksGridOptions(dataSource: Array<Interfaces.IBook>, FFormsId: any): Components.IApxGrid{
            var columns = (this.getBooksGridColumns(FFormsId));  
            var booksGridOptions: Components.IApxGrid = {               
                settings: {
                    scrollable: false,
                    filterDisabled: true
                },
                dataSource: new kendo.data.DataSource({
                    data: dataSource,
                    sort: { field: "OpDetID", dir: "asc" },
                    aggregate: [
                        { field: "VC", aggregate: "sum" },
                        { field: "VG", aggregate: "sum" },
                        { field: "VU", aggregate: "sum" },
                        { field: "OpDetID", aggregate: "count" }
                    ],
                    schema: {
                        model: {
                            id: "BookID"
                        }
                    }
                }),
                columns: columns
            };
            return booksGridOptions;
        }

        openExpensesForm = () => {
            this.$scope.isBusy = true;
            Utils.Documents.openExpensesForm(this.$scope.docInfo.DocID);
            this.$timeout(() => {
                if (Utils.Documents.expensesModalRef != undefined) {
                    Utils.Documents.expensesModalRef.result.then(result => {                    
                        this.$scope.isBusy = false;
                    });
                }
            });
        };      

        checkOperationsForBooksGridVc = (fformsId: any): boolean => {
            return (fformsId != Interfaces.FForms.GoodInnerBuying && fformsId != Interfaces.FForms.Production && fformsId != Interfaces.FForms.GoodReturn && fformsId != Interfaces.FForms.GoodReturnPresaler)
        }


        getBooksGridColumns = (fformsId: any): Array<Core.Interfaces.IGridColumn> => {
            var columns: Array<Core.Interfaces.IGridColumn> = [];
            if (this.checkOperationsForBooksGridVc(fformsId)) {
                columns = [
                    { field: 'OpDetID', titleTranslated: 'OpDetId', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.OpDetID ? data.OpDetID.count : 0) #' },
                    { field: 'DB', titleTranslated: 'DB', width: 80 },
                    { field: 'CR', titleTranslated: 'CR', width: 80 },
                    { field: 'VG', titleTranslated: 'vg', width: 60, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VG && data.VG.sum ? parseFloat(data.VG.sum).toFixed(4) : 0) #' },
                    { field: 'VU', titleTranslated: 'vu', width: 60, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VU && data.VU.sum ? parseFloat(data.VU.sum).toFixed(4) : 0) #' },
                    { field: 'VC', titleTranslated: 'vc', width: 60, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VC && data.VC.sum ? parseFloat(data.VC.sum).toFixed(4) : 0) #'  }
                ];
            }
            else {
                columns =  [
                    { field: 'OpDetID', titleTranslated: 'OpDetId', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.OpDetID ? data.OpDetID.count : 0) #' },
                    { field: 'DB', titleTranslated: 'DB', width: 80 },
                    { field: 'CR', titleTranslated: 'CR', width: 80 },
                    { field: 'VG', titleTranslated: 'vg', width: 60, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VG && data.VG.sum ? parseFloat(data.VG.sum).toFixed(4) : 0) #' },
                    { field: 'VU', titleTranslated: 'vu', width: 60, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VU && data.VU.sum ? parseFloat(data.VU.sum).toFixed(4) : 0) #' }                   
                ];
            }
            return columns;

        }       


        getRates = (date, currencyID): angular.IPromise<{ rate: number; rateC: number }> => {
            return this.accountService.getRates(date, currencyID).then(result => {
                return {
                    rate: result.RateU,
                    rateC: result.RateC
                };
            });
        }


        saveDocument = (doc: Interfaces.IDoc, closeForm: boolean = true) => { 
            
            kendo.ui.progress($('.modal-content'), true);
            var forUpdate = this.$scope.baseModel.docID !== undefined;          
            this.accountService.saveDoc({ Doc: doc, UpdateDocument: forUpdate }).then(result => {
                if (typeof result.DocID !== "undefined") {
                    this.toaster.pop('success', this.translate.instant('Info'), this.translate.instant('docSave'));                
                    if (closeForm)
                        this.$uibModalInstance.close(result);
                }
                kendo.ui.progress($('.modal-content'), false);      
            });
        }

        makeFormDirty = (e?) => {
            if (e == undefined) {
                if (this.$scope.isDirty === false) {
                    this.$scope.isDirty = true;
                }
            } else {
                if (this.$scope.isDirty === false && e.keyCode !== 13 && e.keyCode !== 27) {
                    this.$scope.isDirty = true;
                }
            }
        }

        closeDoc = () => {
            if (this.$scope.isDirty) {                
                var r = Core.Utils.ModalForm.openConfirmation(this.translate.instant("resetChanges"));
                r.result.then(() => {
                    this.$uibModalInstance.close();
                    $(document).unbind('keydown', this.closeHandle);
                });
            } else {
                this.$uibModalInstance.close();
                $(document).unbind('keydown', this.closeHandle);
            }
        }
    }
}