/// <reference path='./../_reference.ts' />
module Apex.Account.Directives {
    "use strict";
    export class ApxAddBook implements angular.IDirective {
        restrict = "E";
        scope = {
            callback: '&'
        };
        template = '<apx-search-dropdown apx-value-id="OperID" apx-value-name="Name" apx-show-value="true" apx-on-click="createDoc(val)" apx-array="operations"></apx-search-dropdown>';

        link = (scope: any, element: angular.IAugmentedJQuery, attrs: any) => {
            Utils.Documents.allowedOpers().then(result => {
                scope.operations = result;
                return "";
            });
            scope.createDoc = (operation: Interfaces.IOperInfo) => {
                Utils.Documents.addDoc(operation, scope.callback);
            }
        }

        constructor(private $translate, private accountService) {
        }

        public static factory() {
            var directive = ($translate, accountService) => {
                return new ApxAddBook($translate, accountService);
            };
            directive['$inject'] = ['$translate', 'accountService'];

            return directive;
        }
    }
} 