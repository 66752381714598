/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IPayedPayRollScope extends angular.IScope {
        formName: string,
        model: {
            PayedPayRoll?: Interfaces.IPayedPayroll,
            PayedPayRollId?:number
        },
        events: {
            save: Function,
            closeForm: Function,
            getPayedPayRollDetNumber:Function
        },
        setting: {
            priorityInputOption: {
                numeric: boolean,
                allowMinus: boolean, //false
                min: number, //0.0001
                numberType: Components.NumberType
            }
        }
    }
    export class PayedPayRollModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService'];
        constructor(private $scope: IPayedPayRollScope, private toaster, private $uibModalInstance, data: any, $timeout,
            private $translate, private accountService: Apex.Account.Services.Account) {
            super(arguments, PayedPayRollDet.$inject)
            this.initialize($scope);
            
            
            if (data.rollId && !data.payedPayRollId){
                 $scope.formName = "გადახდის უწეყისის დამატება";
                 $scope.model.PayedPayRoll={
                     RollId:data.rollId
                 }
                 $scope.model.PayedPayRollId=data.payedPayRollId;
                 this.getPayedPayRollDetNumber();
                 this.getPayRoll(data.rollId);
            }
            else if(data.payedPayRollId){
                $scope.formName = "გადახდის უეყისის რედაქტირება";
                this.getPayedPayRoll(data.payedPayRollId);
            }
        }

        initialize(scope: IPayedPayRollScope) {
            scope.setting = {
                priorityInputOption: {
                    allowMinus: false,
                    numeric: true,
                    min: 0,
                    numberType: Components.NumberType.Integer
                }
            }
            scope.model = {};
            scope.events = {
                save: this.save,
                closeForm: this.closeForm,
                getPayedPayRollDetNumber:this.getPayedPayRollDetNumber
            }
        }
        getPayRoll(rollId:number){
            this.accountService.getPayRoll(rollId).then(r=>{
                if(r.Result){
                    this.$scope.model.PayedPayRoll.DDate=r.Result[0].DDate;
                }
            });
        }
        getPayedPayRollDetNumber=()=>{
            this.accountService.getPayRollDetNumber().then(r=>{
                if(r){
                    this.$scope.model.PayedPayRoll.PayNum=r.Result;
                }
            })
        }

        getPayedPayRoll=(id:number)=>{
            this.accountService.getPayedPayRollById(id).then(r=>{
                if(r && r.Result)
                    this.$scope.model.PayedPayRoll=r.Result[0];
            });
        }

        save=()=>{
            this.accountService.savePayedPayRoll(this.$scope.model.PayedPayRoll).then(r=>{
                if(r.success==undefined){
                    this.toaster.pop("success","შეტყობინება","შენახვა წარმატებით დასრულდა!");
                    this.closeForm(true);
                }
            });
        }

        closeForm = (value=false) => {
            this.$uibModalInstance.close(value);
        }

    }
}