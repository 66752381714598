/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IGoodImportModel {
        debet?: string;
        credit?: string;
        operID?: any;
        crDate?: Date;
        numberIn?: string;
        numberOut?: string;
        isProject?: boolean;
        rateC?: number;
        supplies?: Array<ISupplieModel>;
        waybillNum?: string;
        addedSupplie?: any;
    }

    export interface IGoodImportScope extends IBaseBookScope {
        goodImportModel: IGoodImportModel;
        translateProvider: any;
        sameProductsCounter: number;
        productsGrid: Components.IApxGrid;
        isDisabled?: boolean;
        currencies?: any;
        currencyDropdownOptions?: any;
        rateMaskOptions?: Components.IApxNumber;
        countMaskOptions?: Components.IApxNumber;
        productLookup: Components.IApxLookup;
        recalculation: Function;
    }

    export class GoodImport extends BaseBook {
        $scope: IGoodImportScope;
        constructor($scope: IGoodImportScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            
            $scope.goodImportModel = this.toGoodImportModel($scope.docInfo, data.Operation.OperID);
            $scope.rateMaskOptions = { allowMinus: false, decimals: 4, numberType: Components.NumberType.Decimal };
            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.sameProductsCounter = 0;
            this.$scope.currencies = _.filter(data.Currency, (itm: any) => (itm.CurType !== 0));

            $scope.currencyDropdownOptions = {
                dataValueField: "CurrencyID",
                filter: 'contains',
                index: 0,
                dataTextField: "textTemplate",
                dataSource: $scope.currencies.toDatasource()
            }; 
            
            if ($scope.docInfo.DocID) $scope.isDisabled = true;
            else $scope.isDisabled = false;

            if (!$scope.isDisabled)
                $scope.goodImportModel.supplies = [];
            $scope.productsGrid = this.productGridOption($scope.goodImportModel.supplies);
            $scope.productLookup = this.bcDropdownOptions();
            
            $scope.$watch('[goodImportModel.crDate, baseModel.currencyID]', (val) => {
                if ($scope.baseModel.currencyID != null && $scope.goodImportModel.crDate != null) {
                    $scope.baseComponents.credit.dataSource = _.filter($scope.creditDataSource, (item: any) => (item.Acc.slice(-2) === $scope.baseModel.currencyID)).toDatasource();
                    var dt = $scope.goodImportModel.crDate;
                    this.getRates(dt, $scope.baseModel.currencyID).then((a: any) => {
                        $scope.goodImportModel.rateC = a.rateC;
                        return "";
                    });
                }
            }, false);

            $scope.$watch('goodImportModel.addedSupplie.product', (product: any) => {
                if (product != undefined) {
                    this.$scope.goodImportModel.addedSupplie.priceWithVat = 0;
                    this.$scope.goodImportModel.addedSupplie.count = 0;
                    this.$scope.goodImportModel.addedSupplie.rateC = 0;
                    this.$scope.goodImportModel.addedSupplie.markerCode = '';
                    this.$scope.goodImportModel.addedSupplie.lifedate = '';
                    if (product.PackCount != undefined) {
                        if (product.PackCount === 1) {
                            $scope.countMaskOptions = { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false };
                        }
                        else {
                            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
                        }
                    }
                }
            });

            

            $scope.goodImportModel.addedSupplie = {
                prodPPId: '',
                count: 0,
                vc: 0,
                itemPrice: 0
            };

            $scope.recalculation = this.recalculation;
            $scope.saveDoc = this.saveDoc;

            this.$timeout(() => {
                // $('.k-button.k-button-icontext.k-grid-edit').trigger('click');
                $('.k-button.k-button-icontext.k-grid-edit').click(() => {
                    this.$scope.isBusy = true;
                });
            });

            this.$timeout(() => {
                $('#multiDelete').on('click', () => {
                    this.multiDelete();
                });
            });


            var firstCurrency: any = _.first(this.$scope.currencies);
            $scope.baseModel.currencyID = firstCurrency.CurrencyID;
        }

        private productGridOption(datasource: Array<ISupplieModel>): Components.IApxGrid {
            var gridOptions: Components.IApxGrid = {
                columns: this.productsGridColumns,
                templates: this.productsGridTempalate,
                dataSource: this.suppliesDatasource(datasource),
                settings:
                {
                    selectable: "multiple",
                    gridButtons: {
                        multiDelete: { enabled: true },
                        add: { enabled: true },
                        exportToExcell: true
                    },
                    height: 500,
                    editble: {
                        popupTemplateID: 'good_import_popup_editor',
                        popupWidth: 750
                    }
                },
                parentScope: this.$scope,
                gridEvents: [
                    this.getAddEvent(),
                    this.getCancelEvent(),
                    this.getExportToExcelEvent(),
                    this.getEditEvent()]
            };
            return gridOptions;
        }
        private getAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addEventFunctionality
                };
            return addEvent;
        }
        private getDeleteEvent(): Components.IEvent {
            var deleteEventType: Components.EventType = Components.EventType.Delete;
            var deleteEvent: Components.IEvent =
                {
                    eType: deleteEventType,
                    eFunction: this.deleteEventFunctionality
                };
            return deleteEvent;
        }

        private deleteEventFunctionality = (e) => {
            var deletedRow = e.model;

            for (var i = 0; i < this.$scope.goodImportModel.supplies.length; i++) {
                if (this.$scope.goodImportModel.supplies[i].supplieID === deletedRow.supplieID) {
                    this.$scope.goodImportModel.supplies.splice(i, 1);
                    break;
                }
            }
        }

        multiDelete = () => {
            var selectedRows = this.$scope.productsGrid.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }
            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: ISupplieModel = this.$scope.productsGrid.grid.dataItem(item).toJSON();
                    for (var i = 0; i < this.$scope.goodImportModel.supplies.length; i++) {
                        if (this.$scope.goodImportModel.supplies[i].supplieID === model.supplieID) {
                            this.$scope.goodImportModel.supplies.splice(i, 1);
                            break;
                        }
                    }
                });
                this.$scope.productsGrid.grid.dataSource.data(this.$scope.goodImportModel.supplies);
                this.makeFormDirty();
            });
        }

        bcDropdownOptions = () => {
            var pProductsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 120, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', titleTranslated: 'product' }
                    ],
                    dataSource: this.getDropDownDs(),
                    valueText: 'ProductsNu'
                }

            return pProductsLookup;
        }

        getDropDownDs = () => {
            var dataSourceModel = {
                fields: {
                    BCode: { type: 'string' },
                    ProductsNu: { type: 'string' }
                }
            }
            var r = Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.productsQueryUrl, dataSourceModel);
            return r;
        }

        saveDoc = () => {
            var modalBook = this.$scope.formScope.modalBook;
            if (modalBook.$invalid) {
                this.toaster.pop('error', this.$scope.translateProvider.instant('Attention'), 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი !');
                return;
            }
            var model = this.$scope.goodImportModel;
            var baseModel = this.$scope.baseModel;

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant('Attention'), this.$scope.translateProvider.instant('choosewarehouseandsupplier'));
                return;
            }
            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant('Attention'), this.$scope.translateProvider.instant('choosewarehouse'));
                return;
            }
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant('Attention'), this.$scope.translateProvider.instant('choosesupplier'));
                return;
            }

            var doc: any = {
                DB: this.$scope.baseModel.debet.Acc,
                CR: this.$scope.baseModel.credit.Acc,
                DDate: model.crDate,
                Operid: model.operID,
                NumberIn: model.numberIn,
                NumberOut: model.numberOut,
                WaybillNum: model.waybillNum,
                IsProject: model.isProject,
                RateC: model.rateC,
                Currency_id: baseModel.currencyID
            }

            if (!this.$scope.isCopied)
                doc.DocID = baseModel.docID;


            doc.Supplies = [];

            var supplies = this.$scope.productsGrid.grid.dataSource.data();

            _.forEach(supplies, (item: ISupplieModel) => {
                if (item.count && item.count != 0) {
                    var supplie: Interfaces.ISupplie = {};
                    supplie.ICount = item.count;
                    supplie.Vc = item.vc;
                    if (item.supplieID.length > 10)
                        supplie.ID = null;
                    else {
                        if (!this.$scope.isCopied)
                            supplie.ID = item.supplieID;
                    }

                    supplie.ProdPP_id = item.prodPPId;

                    if (_.isDate(item.lifedate))
                        supplie.LifeDate = item.lifedate.toJSON();

                    supplie.Code = item.markerCode.replace(/\s/g, "");

                    doc.Supplies.push(supplie);
                }
            });

            if (doc.Supplies.length == 0) {
                this.toaster.pop('error', this.$scope.translateProvider.instant('Attention'), this.$scope.translateProvider.instant('productlistisempty'));
                return;
            }
            
            this.saveDocument(doc);
        }

        recalculation = (keycode, val: string): void => {
            if (keycode == 110 || keycode == 188 || keycode == 190)
                return;
            
            var model: ISupplieModel = this.$scope.goodImportModel.addedSupplie;

            switch (val) {
                case 'count':
                case 'itemPrice':
                    {
                        this.calculateFromCountOrItemPrice(model, val);
                        break;
                    }
                case 'priceWithVat':
                    {
                        this.calculateFromPriceWithVat(model, val);
                        break;
                    }
            }

        }

        private calculateFromCountOrItemPrice(model: ISupplieModel, val: string): void {
            if (model.count != undefined && model.count !== 0 && model.itemPrice != undefined && model.itemPrice !== 0) {
                model.vc = parseFloat((model.itemPrice * model.count).toFixed(4));
            } else {
                model.vc = 0;
            }
            if(val != 'count')
                model.count = Number(model.count);
            if (val != 'itemPrice')
                model.itemPrice = Number(model.itemPrice);
        }

        private calculateFromPriceWithVat(model: ISupplieModel, val: string): void {

            if (model.count != undefined && Number(model.count) !== 0) {
                if (model.vc != undefined) {
                    model.itemPrice = parseFloat((model.vc / model.count).toFixed(4));
                } else {
                    model.itemPrice = 0;
                }
            } else {
                if (model.vc != undefined) {
                    model.itemPrice = model.vc;
                } else {
                    model.itemPrice = 0;
                }
            }
            if (val != 'itemPrice')
                model.itemPrice = Number(model.itemPrice);
            if (val != 'count')
                model.count = Number(model.count);
            if (val != 'vc')
                model.vc = Number(model.vc);
        }

        private getCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.cancelEventFunctionality
                };
            return cancelEvent;
        }



        private productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'bCode', titleTranslated: 'barcode', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.bCode ? data.bCode.count : 0) #' },
            { field: 'prodName', titleTranslated: 'product', width: 250 },
            { field: 'categoryName', titleTranslated: 'categoryName', width: 200 },
            { field: 'producer', titleTranslated: 'producer', width: 200 },
            { field: 'unit', titleTranslated: 'unit', width: 50 },
            { field: 'lifedate', titleTranslated: 'lifeDate', width: 80, editable: true, format: Core.Kendo.Format.Date, twoWayBinding: true },
            { field: 'markerCode', titleTranslated: 'goodMarkerCode', width: 90, editable: true },
            { field: 'count', titleTranslated: 'Count', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.count && data.count.sum ? parseFloat(data.count.sum).toFixed(4) : 0) #' },
            { field: 'itemPrice', titleTranslated: 'ItemPrice', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.itemPrice && data.itemPrice.sum ? parseFloat(data.itemPrice.sum).toFixed(4) : 0) #' },
            { field: 'vc', titleTranslated: 'vc', width: 90, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vc && data.vc.sum ? parseFloat(data.vc.sum).toFixed(4) : 0) #' },
            { field: 'vg', titleTranslated: 'vg', width: 90, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vg && data.vg.sum ? parseFloat(data.vg.sum).toFixed(4) : 0) #' }
        ];

        cancelEventFunctionality = (e: kendo.ui.GridEvent) => {
            this.$scope.goodImportModel.addedSupplie = {
                prodPPId: '',
                lifedate: '',
                count: 0,
                vc: 0,
                itemPrice: 0
            };

            this.$timeout(() => { this.$scope.isBusy = false; });
        }
        exportToExcelFunctionality = (e) => {
            var rows = e.workbook.sheets[0].rows;
            for (var ri = 0; ri < rows.length; ri++) {
                var row = rows[ri];
                if (row.type === "header") {
                    _.forEach(row.cells, (item: any) => {
                        item = this.initExcelCaptions(item);
                    });
                }
                
                if (row.type === "group-footer" || row.type === "footer") {
                    for (var ci = 0; ci < row.cells.length; ci++) {
                        var cell = row.cells[ci];
                        if (cell.value) {
                            cell.value = '';
                        }
                    }
                }
            }
        }
        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        initExcelCaptions = (column: any): void => {

            if (column.value.includes("'barcode'")) {
                column.value = this.$scope.translateProvider.instant('barcode');
                return column;
            }
            if (column.value.includes("'product'")) {
                column.value = this.$scope.translateProvider.instant('product');
                return column;
            }
            if (column.value.includes("'categoryName'")) {
                column.value = this.$scope.translateProvider.instant('categoryName');
                return column;
            }
            if (column.value.includes("'producer'")) {
                column.value = this.$scope.translateProvider.instant('producer');
                return column;
            }
            if (column.value.includes("'unit'")) {
                column.value = this.$scope.translateProvider.instant('unit');
                return column;
            }
            if (column.value.includes("'lifeDate'")) {
                column.value = this.$scope.translateProvider.instant('lifeDate');
                return column;
            }
            if (column.value.includes("'goodMarkerCode'")) {
                column.value = this.$scope.translateProvider.instant('goodMarkerCode');
                return column;
            }
            if (column.value.includes("'Count'")) {
                column.value = this.$scope.translateProvider.instant('Count');
                return column;
            }

            if (column.value.includes("'ItemPrice'")) {
                column.value = this.$scope.translateProvider.instant('ItemPrice');
                return column;
            }
            if (column.value.includes("'vc'")) {
                column.value = this.$scope.translateProvider.instant('vc');
                return column;
            }
            if (column.value.includes("'vg'")) {
                column.value = this.$scope.translateProvider.instant('vg');
                return column;
            }

            return column;
        }

        addEventFunctionality = (e) => {
            
            if (e.model != undefined && !e.model.isNew()) {
                var focusedRowModel: ISupplieModel = e.model;
                this.calculationOnEditEvent(e, e.values, focusedRowModel);
                e.sender.dataSource.sync();
                this.makeFormDirty();

            }
            else {
                if (typeof this.$scope.goodImportModel.addedSupplie.product === 'undefined') {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseProductAlert')));
                    e.preventDefault();
                    return;
                }
                if (Number(this.$scope.goodImportModel.addedSupplie.itemPrice) == 0 ||
                    Number(this.$scope.goodImportModel.addedSupplie.vc) == 0) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', 'ყურადღება', 'შეუძლებელია ჩანაწერის დამატება ნულოვანი მნიშვნელობით'));
                    e.preventDefault();
                    return;
                }
                else {
                    this.initSomeProperties(this.$scope.goodImportModel.addedSupplie);
                    this.$scope.goodImportModel.supplies.unshift(this.$scope.goodImportModel.addedSupplie);
                    this.makeFormDirty();
                    
                    ++this.$scope.sameProductsCounter;
                    this.$scope.productsGrid.grid.dataSource.read();
                    this.$scope.goodImportModel.addedSupplie = {
                        prodPPId: '',
                        count: 0,
                        priceWithVat: 0,
                        vc: 0
                    };

                    // this.$timeout(() => { $('.k-button.k-button-icontext.k-grid-edit').trigger('click'); });

                }
            }
        }

        initSomeProperties = (currentSupplie: ISupplieModel): void => {
            currentSupplie.vg = parseFloat((this.$scope.goodImportModel.addedSupplie.vc * this.$scope.goodImportModel.rateC).toFixed(4));
            currentSupplie.prodPPId = this.$scope.goodImportModel.addedSupplie.product.ProdPPId;
            currentSupplie.producer = this.$scope.goodImportModel.addedSupplie.product.ProducerNu;
            currentSupplie.packCount = this.$scope.goodImportModel.addedSupplie.product.PackCount;
            currentSupplie.categoryName = this.$scope.goodImportModel.addedSupplie.product.CategoryNu;
            currentSupplie.unit = this.$scope.goodImportModel.addedSupplie.product.Unit;
            currentSupplie.prodName = this.$scope.goodImportModel.addedSupplie.product.ProductsNu;
            currentSupplie.bCode = this.$scope.goodImportModel.addedSupplie.product.BCode;
            currentSupplie.supplieID = this.$scope.goodImportModel.addedSupplie.prodPPId + '_' + this.$scope.sameProductsCounter;
        }

        private calculationOnEditEvent(e: any, changedValues: any, model: ISupplieModel): void {

            switch (null) {
                case changedValues.count:
                case changedValues.itemPrice:
                    e.preventDefault();
                    return;
                case changedValues.vc:
                    e.preventDefault();
                    return;
            }

            if (changedValues.count != undefined) {
                if (changedValues.count != undefined && changedValues.count !== 0 && model.itemPrice != undefined && model.itemPrice !== 0) {
                    model.vc = parseFloat((model.itemPrice * changedValues.count).toFixed(4));
                    model.vg = parseFloat((model.vc * this.$scope.goodImportModel.rateC).toFixed(4));
                } else {
                    model.vc = 0;
                    model.vg = 0;
                }
                return;
            }

            if (changedValues.itemPrice != undefined) {
                if (model.count != undefined && model.count !== 0 && changedValues.itemPrice != undefined && changedValues.itemPrice !== 0) {
                    model.vc = parseFloat((changedValues.itemPrice * model.count).toFixed(4));
                    model.vg = parseFloat((model.vc * this.$scope.goodImportModel.rateC).toFixed(4));
                } else {
                    model.vc = 0;
                    model.vg = 0;
                }
                return;
            }
            if (changedValues.vc != undefined) {
                if (model.count != undefined && Number(model.count) !== 0) {
                    if (changedValues.vc != undefined) {
                        model.itemPrice = parseFloat((changedValues.vc / model.count).toFixed(4));
                    } else {
                        model.itemPrice = 0;
                    }
                } else {
                    if (changedValues.vc != undefined) {
                        model.itemPrice = changedValues.vc;
                    } else {
                        model.itemPrice = 0;
                    }
                }
                model.vg = parseFloat((changedValues.vc * this.$scope.goodImportModel.rateC).toFixed(4));
                return;
            }

        }

        private productsGridTempalate: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'lifedate',
                editor(container, options) {
                    $('<apx-date ng-model="dataItem.' + options.field + '"></apx-date>')
                        .appendTo(container);
                }
            }
        ];

        suppliesDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate:
                [
                    { field: "vc", aggregate: "sum" },
                    { field: "vg", aggregate: "sum" },
                    { field: "count", aggregate: "sum" },
                    { field: "itemPrice", aggregate: "sum" },
                    { field: "bCode", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "supplieID",
                        fields: {
                            prodName: { editable: false, type: "string" },
                            unit: { editable: false, type: "string" },
                            producer: { editable: false, type: "string" },
                            bCode: { editable: false, type: "string" },
                            categoryName: { editable: false, type: "string" },
                            vg: { editable: false, type: "number" },
                            lifedate: { type: "date" },
                            markerCode: { type: "string" },
                            count: { type: "number" },
                            itemPrice: { type: "number" },
                            vc: { type: "number" },
                        }
                    }
                }
            });
            return ds;
        }

        toGoodImportModel(docInfo: Interfaces.IDoc, operid: any) {
            var importModel: IGoodImportModel = {};
            if (!!docInfo.DocID) {
                importModel.crDate = this.$scope.baseModel.crDate;
                importModel.numberIn = docInfo.NumberIn;
                importModel.numberOut = docInfo.NumberOut;
                importModel.debet = docInfo.DB;
                importModel.credit = docInfo.CR;
                importModel.isProject = docInfo.IsProject;
                importModel.waybillNum = docInfo.WaybillNum;
                importModel.rateC = docInfo.RateC;
                importModel.supplies = [];
                _.forEach(docInfo.Supplies, (item: Interfaces.ISupplie) => {
                    importModel.supplies.push(
                        {

                            prodPPId: item.ProdPP_id,
                            categoryName: item.Product.Category.CategoryName,
                            vg: item.Vg,
                            vc: item.Vc,
                            producer: item.Product.Producer.ProducerName,
                            count: item.ICount,
                            unit: item.Product.Unit,
                            packCount: item.Product.PackCount,
                            prodName: item.Product.Name,
                            bCode: item.Product.BCode,
                            supplieID: item.ID,
                            lifedate: this.todate(item.LifeDate),
                            markerCode: item.Code,
                            itemPrice: parseFloat((item.Vc / item.ICount).toFixed(4))
                        });
                });

            } else {
                importModel.crDate = new Date();
            }
            importModel.operID = operid;
            return importModel;
        }

        getPartial(): string {
            return 'modalsgood.import.html';
        }
    }

}