/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IAddPayRollScope extends angular.IScope {
        formName: string,
        events: {
            save: Function,
            closeForm: Function,
            getRollNumber: Function,
            rollPeriodChange: Function
        },
        model: {
            payRoll?: Apex.Account.Interfaces.IPayRoll
        },
        dataSources: {
            typeOfDocuments?: any
        },
        priorityInputOption: {
            numeric: boolean,
            allowMinus: boolean, //false
            min: number, //0.0001
            numberType: Components.NumberType
        }

    }

    export class AddPayRoll extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService'];
        constructor(private $scope: IAddPayRollScope, private toaster, private $uibModalInstance, data: any, $timeout,
            private $translate, private accountService: Apex.Account.Services.Account) {
            super(arguments, AddPayRoll.$inject)
            this.initialize($scope);

            if (!data) {
                $scope.formName = "დამატება";
                this.getRollNumber();
            }
            else {
                $scope.formName = "რედაქტირება";
                this.getPayRoll(data);

            }

        }

        initialize(scope: IAddPayRollScope) {
            scope.priorityInputOption = {
                allowMinus: false,
                numeric: true,
                min: 0,
                numberType: Components.NumberType.Integer
            }
            scope.dataSources = {};
            scope.model = {
                payRoll: <Apex.Account.Interfaces.IPayRoll>{
                    RollType: null
                }
            };
            scope.events = {
                closeForm: this.closeForm,
                save: this.save,
                getRollNumber: this.getRollNumber,
                rollPeriodChange: (e) => {
                    setTimeout(() => {
                        Apex.Core.Utils.Helper.safeApply(scope, () => {
                            scope.model.payRoll.RollPeriod = new Date(scope.model.payRoll.RollPeriod.getUTCFullYear(), scope.model.payRoll.RollPeriod.getMonth(), 1);
                        });
                    }, 150);
                }
            }
            this.getPayRollTypes();
        }
        getRollNumber = () => {
            this.accountService.getPayRollNumber().then(r => {
                if (r) {
                    this.$scope.model.payRoll.RollNum = r.Result;
                }
            });
        }
        save = () => {
            this.accountService.savePayRoll(this.$scope.model.payRoll).then(r => {
                if (r.success == undefined) {
                    this.toaster.pop("success", "შეტყობინება", "შენახვა წარმატებით დასრულდა!");
                    this.$uibModalInstance.close(true);
                }
            });
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        getPayRollTypes = () => {
            this.accountService.getPayRollType().then(r => {
                if (r) {
                    this.$scope.dataSources.typeOfDocuments = r.Result;
                }
            });
        }
        getPayRoll = (rollId: number) => {
            this.accountService.getPayRoll(rollId).then(r => {
                if (r && r.Result.length == 1) {
                    this.$scope.model.payRoll =r.Result[0];
                }
            });
        }


    }
}