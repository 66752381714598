/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {

    export class BalanceBooksModal extends Controllers.Books {
        public static $inject = [
            Globals.scope, 'accountService', Globals.dialogs, Globals.translate, Globals.toaster, 'data', Globals.modalInstance
        ];
        $uibModalInstance: any;

        constructor($scope: any, accountService: Services.Account, dialogs: any, $translate: any, toaster: any, private data, modalInstance) {
            super($scope, accountService, dialogs, $translate, toaster, true);
            this.$uibModalInstance = modalInstance;
            $scope.close = this.close;
            $scope.isModal = true;
            $scope.filters = {
                dateBetween: {
                    fromDate: data.fromDate,
                    toDate: data.toDate
                },
                acc: {
                    crOrDB: data.filter,
                    value: data.dataRow.Acc
                }
            };

        }
        close = () => {
            this.$uibModalInstance.close();
        }
    }
}