/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IDoc {
        DocID?: string;
        DocsType?: number;
        PDocs_id?: string;
        IsBlocked?: boolean;
        Operid?: string;
        Docs_type_id?: string;
        DB?: string;
        DBView?: IAccountInfo;
        CR?: string;
        CRView?: IAccountInfo;
        IsProject?: boolean;
        Contents?: string;
        DocNote?: string;
        DDate?: Date;
        DDocs?: Date;
        DPay?: Date;
        NumberOut?: string;
        NumberIn?: string;
        WaybillNum?: string;
        RateU?: number;
        RateC?: number;
        Currency_id?: string;
        PresalerId?: number;
        ContractID?: number;
        UNID?: string;
        Books?: Array<IBook>;
        SubDocs?: Array<IDocEx>;
        Supplies?: Array<ISupplie>;
        Orders?: Array<IOrder>;
        Served?: Array<IServed>;
    }
} 