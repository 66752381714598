/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IPayRollDetScope extends angular.IScope {
        formName: string,
        Components: {
            payRollDetGrid: Apex.Components.IApxGrid
        },
        events: {
            closeForm: Function,
            refresh: Function,
            delete: Function,
            edit: Function,
            openPersonsList: Function,
            selectedChange: Function,
            export: Function,
            upload: Function,
            fileSelected: Function,
            oppenFileDialog: Function,
            selectAll: Function,
            unselectAll: Function
        },
        model: {
            payRoll?: Apex.Account.Interfaces.IPayRoll,
            taxPercent?: number
            // payRollId?: number
        },
        dataSources: {
            typeOfDocuments?: any,
            personalStatus?: any
        },
        priorityInputOption: {
            numeric: boolean,
            allowMinus: boolean, //false
            min: number, //0.0001
            numberType: Components.NumberType
        },
        disabled: {
            disableBtn?: boolean,
            editable?: boolean
        },
        selectedItems?: number
        personsOriginData?: any;
    }

    //   data {payRoll:payRoll, editable: true || false}
    export class PayRollDet extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.modalInstance, 'data', '$timeout', '$translate', 'accountService'];
        constructor(private $scope: IPayRollDetScope, private toaster, private $uibModalInstance, data: any, $timeout,
            private $translate, private accountService: Apex.Account.Services.Account) {
            super(arguments, AddPayRoll.$inject)
            $scope.disabled = {
                disableBtn: true,
                editable: data.editable
            };
            this.initialize($scope);
            this.$scope.model.payRoll = data.payRoll;
            $timeout(() => {
                this.refresh();
            });
        }

        initialize(scope: IPayRollDetScope) {
            scope.personsOriginData = {};
            scope.selectedItems = 0;

            scope.Components = {
                payRollDetGrid: this.initPayRollDetGrid()
            };

            scope.priorityInputOption = {
                allowMinus: false,
                numeric: true,
                min: 0,
                numberType: Components.NumberType.Integer
            }
            scope.dataSources = {};
            scope.model = {
                payRoll: <Apex.Account.Interfaces.IPayRoll>{
                    RollType: null
                }
            };
            scope.events = {
                closeForm: this.closeForm,
                refresh: this.refresh,
                delete: this.delete,
                edit: this.edit,
                openPersonsList: this.openPersonsList,
                selectedChange: this.selectedChange,
                export: this.download,
                upload: this.upload,
                fileSelected: this.fileSelected,
                oppenFileDialog: this.oppenFileDialog,
                selectAll: this.selectAll,
                unselectAll: this.unselectAll
            }
            this.getPayRollTypes();
            this.getPersonsStatus();
        }

        selectAll = () => {
            this.$scope.Components.payRollDetGrid.SelectAll();
            this.$scope.disabled.disableBtn = this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>().length == 0;
        }
        unselectAll = () => {
            this.$scope.Components.payRollDetGrid.UnselectAll();
            this.$scope.disabled.disableBtn = this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayRollDet>().length == 0;;
        }

        fileSelected = (input) => {
            this.upload();
        }
        oppenFileDialog = () => {
            document.getElementById('attachement').click();
        }
        upload = () => {
            var fd = new FormData();
            var item = $("#attachement")[0] as HTMLInputElement;
            fd.append("upload", item.files[0]);
            fd.append("RollId", String(this.$scope.model.payRoll.RollId));
            //<input type="checkbox" ng-model="other.denyErrors">  <span>{{'DenyErrors' | translate }}</span></input><br />
            kendo.ui.progress($('.modal-content'), true);
            this.accountService.http.post(
                this.accountService.filterUrl("/Accounting/Crm/Branch/Persons/Documents/", null),
                fd,
                {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                }
            ).then((success) => {
                kendo.ui.progress($('.modal-content'), false);
                if (success.status == 200) {
                    this.toaster.pop("success", "", "ფაილი წარმატებით აიტვირთა!");
                    this.refresh();
                } else {
                    this.toaster.pop("warning", "შეცდომა", "დაფიქსირდა გაუთვალისწინებელი შეცდომა!");
                }
                var $el = $("#attachement");
                (<any>$el).replaceWith((<any>$el).val('').clone(true));
            }, (error) => {
                kendo.ui.progress($('.modal-content'), false);
                this.toaster.pop("error", "შეცდომა", JSON.stringify(error.data.ResponseStatus.Message || ""));
                var $el = $("#attachement");
                (<any>$el).replaceWith((<any>$el).val('').clone(true));
            }, );
        }


        download = () => {
            kendo.ui.progress($('.modal-content'), true);
            this.accountService.http({
                url: this.accountService.filterUrl("/Accounting/Crm/Branch/Persons/Documents/", null),
                method: "GET",
                headers: {
                    'Content-type': "application/json"
                },
                responseType: "arraybuffer"
            }).then((success) => {
                kendo.ui.progress($('.modal-content'), false);
                this.saveFile(success.data, "result.xlsx");
            }, (error) => {
                kendo.ui.progress($('.modal-content'), false);
                this.toaster.pop("error", "შეცდომა", error.Message);
            });
        }
        private saveFile = (content, filename, contentType: string = null) => {
            if (!contentType) contentType = "application/octet-stream";
            var a = <any>document.createElement("a");
            var blob = new Blob([content], { 'type': contentType });
            a.href = window.URL.createObjectURL(blob);
            a.target = "_blank";
            a.download = filename;
            a.style = "visibility:hidden";
            document.body.appendChild(a);
            a.click();
            setTimeout(() => { document.body.removeChild(a) }, 500);
        }

        getPersonsStatus = () => {
            this.accountService.getFixVal("9f47b840-e35d-48c1-b9f3-e35caa70178b").then(r => {
                if (r) {
                    this.$scope.dataSources.personalStatus = r.Result;
                }
            });
        }

        refresh = () => {
            this.$scope.Components.payRollDetGrid.gridView.showLoading();
            this.accountService.getPayRollDet(null, this.$scope.model.payRoll.RollId, null).then(r => {
                _.map(r.Result, (item) => { return item.isSelected = false; })
                this.$scope.Components.payRollDetGrid.dataSource.data(r.Result);
            }).finally(() => {
                this.$scope.Components.payRollDetGrid.gridView.hideLoading();
            });
        }
        delete = () => {
            var selectedItem: Interfaces.IPayRollDet[] = this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayRollDet>();
            
            if (selectedItem) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    var Ids = _.map(selectedItem, (item) => { return item.Id });
                    this.accountService.deletePayRollDet(Ids).then(r => {
                        if (r.success != false) {
                            this.refresh();
                        }
                    })
                });
            }
        }
        edit = () => {
        }


        initPayRollDetGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initPayRollDetGridDataSource(),
                columns: this.payRollDetGridColumns,
                templates: this.payRollDetGridTemplates,
                settings: {
                    editble: {
                        popupTemplateID: 'editor',
                        popupWidth: 700
                    }, height: window.innerHeight - 250
                },
                parentScope: this.$scope,
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                // var selectedItem = this.$scope.Components.payRollDetGrid.gridView.selectedItem();
                                var selectedItem = this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayedPayrollDet>();
                                this.$scope.disabled.disableBtn = selectedItem.length ? false : true;
                            });
                        }
                    },
                    {
                        eType: Components.EventType.ISelectedChange,
                        customFunction: (e) => {
                            this.$scope.events.selectedChange(e.object);
                            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selectedItem = this.$scope.Components.payRollDetGrid.getSelectedOrCurrent<Interfaces.IPayRollDet>();
                                this.$scope.disabled.disableBtn = selectedItem.length == 0;
                            });

                        }
                    },
                    {
                        eType: Components.EventType.Edit,
                        eFunction: (e) => {
                            // console.log("e=",e);
                        }
                    },
                    {
                        eType: Components.EventType.Save,
                        eFunction: (e: any) => {
                            if (e.model == undefined) {
                                e.preventDefault();
                                return;
                            }
                            if (this.$scope.personsOriginData[e.model.PersonId] == undefined) {
                                this.$scope.personsOriginData[e.model.PersonId] = <Interfaces.IPayRollDetView>jQuery.extend(true, {}, _.filter(this.$scope.Components.payRollDetGrid.dataSource.data(), (r) => {
                                    return r.PersonId == e.model.PersonId;
                                })[0]);
                            }

                            if (e.values.Salary) {
                                if (this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount == 0) {
                                    e.model.Amount = (e.values.Salary - this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount) * (1 - this.$scope.model.taxPercent);
                                    e.model.TaxAmount = e.values.Salary * this.$scope.model.taxPercent;
                                } else {
                                    var expresion = this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount - (e.values.Salary - this.$scope.personsOriginData[e.model.PersonId].Salary);
                                    e.model.Amount = expresion >= 0 ? e.values.Salary :
                                        e.values.Salary - ((e.values.Salary - this.$scope.personsOriginData[e.model.PersonId].Salary) - this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount) * this.$scope.model.taxPercent;
                                    e.model.TaxAmount = expresion >= 0 ? 0 : ((e.values.Salary - this.$scope.personsOriginData[e.model.PersonId].Salary) - this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount) * this.$scope.model.taxPercent;
                                    e.model.ExistingExemptIncomeAmount = expresion >= 0 ? expresion : 0;
                                }
                            }
                            if (e.values.Amount) {
                                if (this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount == 0) {
                                    e.model.Salary = (e.values.Amount - this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount) / (1 - this.$scope.model.taxPercent);
                                    e.model.TaxAmount = e.model.Salary * this.$scope.model.taxPercent;
                                } else {
                                    var expresion = this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount - (e.values.Amount - this.$scope.personsOriginData[e.model.PersonId].Amount);
                                    var plusSalary = ((e.values.Amount - this.$scope.personsOriginData[e.model.PersonId].Amount) - this.$scope.personsOriginData[e.model.PersonId].ExistingExemptIncomeAmount) / (1 - this.$scope.model.taxPercent);
                                    e.model.Salary = expresion >= 0 ? e.values.Amount : e.values.Amount + (plusSalary * this.$scope.model.taxPercent);
                                    e.model.TaxAmount = expresion >= 0 ? 0 : plusSalary * this.$scope.model.taxPercent;
                                    e.model.ExistingExemptIncomeAmount = expresion >= 0 ? expresion : 0;
                                }
                            }


                            var dataSource = this.$scope.Components.payRollDetGrid.dataSource.data();
                            for (var index = 0; index < dataSource.length; index++) {
                                if (dataSource[index].Id == e.model.Id)
                                    dataSource[index] = e.model;
                            }

                            this.$scope.Components.payRollDetGrid.dataSource.sync();
                            this.save(e.model);
                        }
                    }
                ]
            }
            return grid;
        }
        initPayRollDetGridDataSource = () => {
            return new kendo.data.DataSource({
                aggregate: [
                    // { field: "PName", aggregate: "count" },
                    { field: "FullName", aggregate: "count" },
                    { field: "Salary", aggregate: "sum" },
                    { field: "Amount", aggregate: "sum" },
                    { field: "TaxAmount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Salary: { type: "number", editable: this.$scope.disabled.editable },
                            TaxPersent: { type: "number", editable: false },
                            Amount: { type: "number", editable: this.$scope.disabled.editable },
                            TaxAmount: { type: "number", editable: false },
                            StafStatus: { type: "number", editable: false },
                            isSelected: { type: "boolean", editable: false },
                            // PName: { type: "string", editable: false },
                            FullName: { type: "string", editable: false },
                            PersonSN: { type: "string", editable: false },
                            BranchName: { type: "string", editable: false },
                            DepName: { type: "string", editable: false },
                            PostName: { type: "string", editable: false },
                            ExistingExemptIncomeAmount: { type: "string", editable: false },
                            TotalPayedSalary: { type: "string", editable: false },
                            RemainingAmount: { type: "number", editable: false },
                            TotalSalary: { type: "number", editable: false }
                        }
                    }
                }
            });
        }

        selectedChange = (item: Apex.Core.Interfaces.ISelected) => {
            Core.Utils.Helper.safeApply(this.$scope, () => {
                if (item.isSelected)
                    this.$scope.selectedItems++;
                else this.$scope.selectedItems--;
            });
        }

        private payRollDetGridTemplates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                editformat: "{0:##.####}",
                fieldName: 'Salary', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' style='text-align:right !important;' name='" + options.field + "' />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 2,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'Amount', editor: function (container, options) {
                    $("<input class='apx-color-lemonchiffon' style='text-align:right !important;' name='" + options.field + "'/>")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 2,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            }
        ];


        private payRollDetGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "Id", hidden: true },
            { field: "RollId", hidden: true },
            {
                field: 'isSelected', title: ' ', editable: false, titleTranslated: "isSelected", width: 80, filterable: 'boolean', template: `
                <div class="checkbox checkbox-styled checkbox-danger" style="margin-bottom: 0px; text-align: center;">
                    <label>
                        <input type="checkbox"  ng-model="dataItem.isSelected" ng-true-value="true" ng-false-value="false" ng-change="options.parentScope.events.selectedChange(dataItem)">
                        <span ></span>
                    </label>
                </div>`
            },
            {
                field: "StafStatus", titleTranslated: "status", width: 60, editable: false,
                filterable: "custom",
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: "ყველა" },
                            { value: "2", text: "რეზერვში მყოფი" },
                            { value: "1", text: "თანამშრომელი" },
                            { value: "0", text: "არა თანამშრომელი" }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",
                        open: (e) => {
                            var listContainer = e.sender.list.closest(".k-list-container");
                           
                            listContainer.width(listContainer.width() + 100);
                        }
                    })
                },
                template: `
                <div ng-repeat="item in options.parentScope.dataSources.personalStatus">
                    <div ng-if="item.HCode==dataItem.StafStatus" popover-placement="right" popover-trigger="mouseenter" uib-popover="{{item.Name}}">
                        <i class="fa fa-circle fa-align-center" ng-class="item.HCode==0? 'text-danger' : (item.HCode==1? 'text-success' : (item.HCode==2? 'text-warning':'text-muted'))"></i>
                    </div>
                </div>
                `
            },
            {
                field: "FullName", titleTranslated: "namelastname", editable: false, width: 150,
                template: "<span>{{dataItem.FirstName}} {{dataItem.LastName}}</span>"
                , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.FullName? data.FullName.count:0) #'
            },
            // {
            //     field: "PName", titleTranslated: "namelastname", editable: false, width: 150
            //     , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.PName? data.PName.count:0) #'
            // },
            { field: "PersonSN", editable: false, titleTranslated: "personalNumber", width: 100 },
            {
                field: "Salary", titleTranslated: "accruedSalary", editable: true, width: 80, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Salary ? data.Salary.sum.toFixed(2) : 0) #'
            },
            { field: "TaxPersent", editable: false, titleTranslated: "TaxPersent", width: 80, format: Core.Kendo.Format.Percent },
            {
                field: "Amount", titleTranslated: "HAmount", editable: true, width: 80, format: Core.Kendo.Format.Price2
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount ? data.Amount.sum.toFixed(2) : 0) #'
            },

            {
                field: "ExistingExemptIncomeAmount", editable: false, titleTranslated: "remain_ex_in_am", width: 80, format: Core.Kendo.Format.Price2
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.ExistingExemptIncomeAmount ? data.ExistingExemptIncomeAmount.sum.toFixed(2) : 0) #'
            },
            {
                field: "TotalPayedSalary", editable: false, titleTranslated: "totalPayedSalary", width: 80, format: Core.Kendo.Format.Price2
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.TotalPayedSalary ? data.TotalPayedSalary.sum.toFixed(2) : 0) #'
            },
            {
                field: "RemainingAmount", editable: false, titleTranslated: "RemainingAmount", width: 80, format: Core.Kendo.Format.Price2
            },
            {
                field: "TotalSalary", editable: false, titleTranslated: "salary", width: 80, format: Core.Kendo.Format.Price2
            },
            { field: "BranchName", editable: false, titleTranslated: "branch", width: 200 },
            { field: "DepName", editable: false, titleTranslated: "dep", width: 150 },
            { field: "PostName", editable: false, titleTranslated: "Position", width: 150 },
            {
                field: "TaxAmount", editable: false, titleTranslated: "incom", width: 80, format: Core.Kendo.Format.Price2
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.TaxAmount ? data.TaxAmount.sum.toFixed(2) : 0) #'
            }
        ];

        save = (payRollDet: Interfaces.IPayRollDet) => {
            this.accountService.updatePayRollDets(payRollDet).then(r => {
                if (r.success != false) {
                    this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successSaved'));
                }
                this.refresh();
            }, (error) => {
                this.toaster.pop("error", "შეცდომა", "მონაცემების შენახვისას დაფიქსირდა შეცდომა!");
            });
        }
        closeForm = () => {
            this.accountService.deleteEmptyPayRollDets(this.$scope.model.payRoll.RollId).then(r => {
                this.$uibModalInstance.close(false);
            }, error => { });

        }

        getPayRollTypes = () => {
            this.accountService.getPayRollType().then(r => {
                if (r) {
                   
                    this.$scope.dataSources.typeOfDocuments = r.Result;
                    this.$scope.model.taxPercent = (<any>_.filter(r.Result, (q) => {
                        return (<any>q).Id == this.$scope.model.payRoll.RollType
                    })[0]).TaxPercent;

                }
            });
        }
        openPersonsList = () => {
            this.openPersonsModalForm({ payRollId: this.$scope.model.payRoll.RollId, personsModalFor: "payRollDet" });
        }
        openPersonsModalForm = (data) => {
            var dialog = Apex.Core.Utils.ModalForm.openModalForm("persons.modal.html", Apex.Account.Controllers.PersonsByBranchModal.getName(), data, Core.Utils.ModalSize.Large);
            dialog.result.then(r => {
                if (r) {
                    this.refresh();
                }
            });
        }

    }
}