/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IService {
        ServiceID?: string;
        Code?: string;
        Name?: string;
        Price?: number;
        Vat?: number;
        Spend?: boolean;
    }
} 