/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {

    export interface IProductRecipeExt extends Interfaces.IProductRecipe {
        MisCount: number;
    }

    export interface IOrderModelLocal {
        Supplies_id?: string;
        SCount?: number;
        ID?: string;
        RCount?: number;
        Code?: string;
        Name?: string;
        ProducerName?: string;
        CategoryName?: string;
        BCode?: string;
        ProdPP_id?: string;
        PriceG?: number;
        Vg?: number;
        SuppDate?: Date;
        SuppliesName?: string;
    }

    export interface IProductionModel {
        supplie?: any;
        debet?: string;
        dDate?: Date;
        credit?: string;
        operID?: any;
        crDate?: Date;
        numberIn?: string;
        numberOut?: string;
        products?: Array<IOrderModelLocal>;
        addedProduct?: any;
        isExpanded?: boolean;
    }

    export interface IProductionScope extends IBaseBookScope {
        model: IProductionModel;
        productForProductionLookup: Components.IApxLookup;
        productForAddLookup: Components.IApxLookup;
        ordersGrid: Components.IApxGrid;
        recipeGrid: Components.IApxGrid;
        expandCollapse: Function;
        calculateRecipe: Function;
        countInputOptions: Components.IApxNumber;
        noRcount: boolean;
        counter: number;
    }

    export class Production extends BaseBook {
        $scope: IProductionScope;
        constructor($scope: IProductionScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            $scope.productForProductionLookup = this.getProductForProductionLookUp();
            $scope.ordersGrid = this.getOrdersGridConfig();
            $scope.recipeGrid = this.getRecipesGridConfig();
            $scope.model = this.convertToProductionModel($scope.docInfo, data.Operation.OperID);
            
            $scope.counter = 0;
            $scope.productForAddLookup = this.getProductForAddLookUp();
            $scope.expandCollapse = this.expandCollapse;
            $scope.calculateRecipe = this.calculateRecipe;
            $scope.saveDoc = this.saveDoc;
            $scope.countInputOptions = {
                allowMinus: false,
                min: 0.0001,
                decimals: 4,
                numberType: Components.NumberType.Decimal
            };
            $scope.noRcount = false;
            if ($scope.baseModel.credit != undefined) {
                this.fillAvaliablesupplies($scope.baseModel.credit.Acc);
            }

            $scope.model.isExpanded = false;
            $scope.ordersGrid.dataSource.data(this.groupProducts($scope.model.products));

            $scope.$watch('ordersGrid.grid', (ordersgrid: kendo.ui.Grid) => {
                if (ordersgrid != undefined) {
                    ordersgrid.hideColumn('SuppliesName');
                    ordersgrid.hideColumn('SuppDate');
                }
            });

            $scope.$watch('model.supplie.Product', (product: any) => {
                if (product != undefined && !$scope.docInfo.DocID) {
                    this.fillRecipe(product.ProdPPId, this.$scope.model.supplie.ICount);
                }
            });

            this.$timeout(() => {
                if (!$scope.docInfo.DocID)
                    $('#addProduct').addClass("k-state-disabled");
            })

            this.$timeout(() => {
                $('#multiDeleteProduct').on('click', () => {
                    this.multiDeleteProduct();
                });
            });


            $scope.$watch('baseModel.credit', (credit: Interfaces.IAccountInfo) => {
                if (credit != undefined) {
                    this.fillAvaliablesupplies(credit.Acc);
                    $('#addProduct').removeClass("k-state-disabled");
                }
            });

            $scope.$watch('model.supplie.ICount', (ICount: any) => {
                if (ICount != undefined) {

                    if ($scope.model.supplie.Product != undefined) {
                        this.fillRecipe($scope.model.supplie.Product.ProdPPId, ICount);
                    }
                }
                else {
                    $scope.model.supplie = { ICount: 0 };
                    if ($scope.model.supplie.Product != undefined) {
                        this.fillRecipe($scope.model.supplie.Product.ProdPPId, 0);
                    }
                }
            });
        }

        fillRecipe = (prodPPId: string, count: number) => {
            this.accountService.getProductRecipe(prodPPId, count).then(resp => {
                _.forEach(resp.Result, (item: IProductRecipeExt) => {
                    item.MisCount = parseFloat(this.getMisCount(item.MCount, item.Materrial.ID).toFixed(4))
                });
                this.$scope.recipeGrid.dataSource.data(resp.Result);
            });
        }

        expandCollapse = () => {
            if (this.$scope.model.isExpanded) {
                this.$scope.ordersGrid.dataSource.data(this.groupProducts(this.$scope.model.products));
                this.$scope.ordersGrid.grid.hideColumn('SuppliesName');
                this.$scope.ordersGrid.grid.hideColumn('SuppDate');
            }
            else {
                this.$scope.ordersGrid.dataSource.data(this.$scope.model.products);
                this.$scope.ordersGrid.grid.showColumn('SuppliesName');
                this.$scope.ordersGrid.grid.showColumn('SuppDate');
            }

            this.$scope.model.isExpanded = !this.$scope.model.isExpanded;

            if (this.$scope.baseModel.credit != undefined)
                this.fillAvaliablesupplies(this.$scope.baseModel.credit.Acc);

        };

        multiDeleteProduct = () => {
            var selectedRows: JQuery = this.$scope.ordersGrid.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {

                if (this.$scope.model.isExpanded) {
                    _.forEach(selectedRows, (item) => {
                        var model: IOrderModelLocal = this.$scope.ordersGrid.grid.dataItem(item).toJSON();
                        for (var i = 0; i < this.$scope.model.products.length; i++) {
                            if (this.$scope.model.products[i].Supplies_id === model.Supplies_id) {
                                this.$scope.model.products.splice(i, 1);
                                break;
                            }
                        }
                    });
                    this.$scope.ordersGrid.grid.dataSource.data(this.$scope.model.products);
                }
                else {
                    _.forEach(selectedRows, (item) => {
                        var model: IOrderModelLocal = this.$scope.ordersGrid.grid.dataItem(item).toJSON();
                        for (var i = 0; i < this.$scope.model.products.length; i++) {
                            if (this.$scope.model.products[i].ProdPP_id === model.ProdPP_id) {
                                this.$scope.model.products.splice(i, 1);
                            }
                        }
                    });
                    this.$scope.ordersGrid.grid.dataSource.data(this.groupProducts(this.$scope.model.products));
                }
                this.makeFormDirty();
                this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
            });
        }


        calculateRecipe = () => {
            if (this.$scope.model.supplie.ICount == 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'მიუთითეთ გამოსაშვები რაოდენობა.');
                return;
            }

            if (this.$scope.recipeGrid.dataSource.data().length == 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'საქონელზე რეცეპტი არაა დადგენილი.');
                return;
            }

            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosecredit'));
                return;
            }

            if (this.$scope.model.supplie.Product == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseProductAlert'));
                return;
            }



            var r = Core.Utils.ModalForm.openConfirmation(this.translate.instant("calculateRecipeConfirmation"));

            r.result.then(() => {
                this.$scope.noRcount = false;
                this.accountService.getProductRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount).then(resp => {
                    var data = resp.Result;

                    _.forEach(data, (item: IProductRecipeExt) => {
                        item.MisCount = parseFloat(this.getMisCount(item.MCount, item.Materrial.ID).toFixed(4));
                    });
                    this.$scope.recipeGrid.dataSource.data(data);

                    if (data.length != 0) {
                        var i = 0;
                        _.forEach(data, (item: IProductRecipeExt) => {
                            if (item.MisCount == item.MCount) {
                                this.accountService.getAvaliableSupplies(this.$scope.model.dDate, this.$scope.baseModel.credit.Acc).then(r => {
                                    i++;
                                    var obj: Interfaces.ISupplie = _.first(_.where(r.Result, { ProdPP_id: item.Materrial.ID }));

                                    if (obj != undefined && obj.RCount > 0) {
                                        var ord: IOrderModelLocal = {};

                                        if (item.MisCount > obj.RCount) {
                                            ord.SCount = obj.RCount;
                                            this.$scope.noRcount = true;
                                        }
                                        else {
                                            ord.SCount = item.MisCount;
                                        }

                                        ord.BCode = obj.Product.BCode;
                                        ord.CategoryName = obj.Product.Category.CategoryName;
                                        ord.Code = obj.Code;
                                        ord.ProdPP_id = obj.ProdPP_id;
                                        ord.Name = obj.Product.Name;
                                        ord.ProducerName = obj.Product.Producer.ProducerName;
                                        ord.PriceG = obj.Vg;
                                        ord.Vg = obj.Vg * ord.SCount
                                        ord.RCount = obj.RCount - ord.SCount;                                       
                                        ord.PriceG = ord.Vg / ord.SCount;
       
                                        ord.ID = 'local_id_' + this.$scope.counter;
                                        this.$scope.model.products.push(ord);

                                        this.$scope.counter++;
                                    }
                                    else {
                                        this.$scope.noRcount = true;
                                    }

                                    if (data.length == i) {
                                        if (this.$scope.noRcount)
                                            this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notEnoughRcount'));

                                        this.$timeout(() => {
                                            this.$scope.ordersGrid.dataSource.data(this.$scope.model.products);
                                            this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
                                        });
                                    }

                                });

                            }
                            else {
                                i++;
                                var ord = _.first(_.where(this.$scope.model.products, { ProdPP_id: item.Materrial.ID }));
                                var oldScount = ord.SCount;
                                if (item.MisCount > ord.RCount) {
                                    ord.SCount = ord.SCount + ord.RCount;
                                    ord.RCount = (ord.RCount + oldScount) - ord.SCount;
                                    this.$scope.noRcount = true;
                                }
                                else {
                                    ord.SCount = ord.SCount + item.MisCount;
                                    ord.RCount = (ord.RCount - item.MisCount);
                                }
                            
                                ord.Vg = ord.PriceG * ord.SCount;
                                if (data.length == i) {
                                    if (this.$scope.noRcount)
                                        this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notEnoughRcount'));

                                    this.$timeout(() => {
                                        this.$scope.ordersGrid.dataSource.data(this.$scope.model.products);
                                        this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
                                    });
                                }
                            }

                        });

                    }
                });
            });
        };

        getMisCount = (Amount: number, ProdPPID: string): number => {
            var orders = _.where(this.$scope.model.products, { ProdPP_id: ProdPPID })

            var scountSum = 0;
            _.forEach(orders, (itm) => {
                scountSum += itm.SCount;
            });

            return orders.length > 0 ? Amount - scountSum : Amount;
        }


        fillAvaliablesupplies = (creditAcc: string) => {
            if (this.$scope.model.isExpanded) {
                this.accountService.getAvaliableSuppliesNotGrouped(this.$scope.model.dDate, creditAcc).then(a => {
                    this.$scope.productForAddLookup.dataSource.data(a.Result);
                });
            }
            else {
                this.accountService.getAvaliableSupplies(this.$scope.model.dDate, creditAcc).then(a => {
                    this.$scope.productForAddLookup.dataSource.data(a.Result);
                });
            }
        }


        convertToProductionModel = (docInfo: Interfaces.IDoc, operid: any) => {
            var model: IProductionModel = { addedProduct: {} };
            model.products = [];
            if (!!docInfo.DocID) {
                model.dDate = this.$scope.baseModel.crDate;
                model.supplie = docInfo.Supplies[0];
                model.supplie.Product.ProductsNu = docInfo.Supplies[0].Product.Name;
                model.supplie.Product.ProdPPId = model.supplie.Product.ID;
                model.supplie.LifeDate = this.todate(docInfo.Supplies[0].LifeDate);
                model.crDate = this.$scope.baseModel.crDate;
                model.numberIn = docInfo.NumberIn;
                model.numberOut = docInfo.NumberOut;
                model.debet = docInfo.DB;
                model.credit = docInfo.CR;
                this.fillRecipe(model.supplie.Product.ProdPPId, model.supplie.ICount);
                _.forEach(docInfo.Orders, (item: Interfaces.IOrder) => {
                    model.products.push(
                        {
                            BCode: item.Supplie.Product.BCode,
                            CategoryName: item.Supplie.Product.Category.CategoryName,
                            Code: item.Supplie.Code,
                            ID: item.ID,
                            Supplies_id: item.Supplies_id,
                            Name: item.Supplie.Product.Name,
                            ProdPP_id: item.Supplie.ProdPP_id,
                            ProducerName: item.Supplie.Product.Producer.ProducerName,
                            RCount: item.Supplie.RCount,
                            SCount: item.SCount,
                            PriceG: item.PriceG,
                            Vg: item.Supplie.Vg,
                            SuppDate: item.Supplie.SuppDate,
                            SuppliesName: item.Supplie.SuppliesName
                        });

                });
            } else {
                model.crDate = model.dDate = new Date();
            }
            model.operID = operid;
            return model;
        };

        groupProducts = (orders: Array<IOrderModelLocal>): Array<IOrderModelLocal> => {
            var result: Array<IOrderModelLocal> = [];
            var groupedObject = _.groupBy(orders, (i => i.ProdPP_id));
            _.forEach(_.values(groupedObject), (item: any) => {
                if (item.length > 1) {
                    var sumVg = 0;
                    var sumPriceG = 0;
                    var sumScount = 0;
                    var sumRcount = 0;
                    _.forEach(item, (t: any) => {
                        sumVg += t.Vg;
                        sumPriceG += t.PriceG;
                        sumScount += t.SCount;
                        sumRcount += t.RCount;
                    });
                    var t = item[0];
                    var obj: IOrderModelLocal = {
                        BCode: t.BCode,
                        CategoryName: t.CategoryName,
                        Code: t.Code,
                        Vg: parseFloat(sumVg.toFixed(4)),
                        Name: t.Name,
                        ID: "groupedListID" + t.ProdPP_id,
                        ProdPP_id: t.ProdPP_id,
                        ProducerName: t.ProducerName,
                        RCount: parseFloat(sumRcount.toFixed(4)),
                        SCount: sumScount,
                        Supplies_id: t.Supplies_id,
                        PriceG: (sumPriceG / item.length),
                        SuppDate: t.SuppDate,
                        SuppliesName: t.SuppliesName
                    };
                    result.push(obj);
                }
                else {
                    result.push(<IOrderModelLocal>_.first(item));
                }
            });
            return result;
        }

        getProductForProductionLookUp = () => {
            var productsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 120, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', titleTranslated: 'product' }
                    ],
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.productsQueryUrl + '?lown=true'),
                    valueText: 'ProductsNu'
                }

            return productsLookup;
        }


        ordersGridEditEventFunctionality = (e: any) => {

            var focusedRowModel: IOrderModelLocal = e.model;
            switch (null) {
                case e.values.SCount:
                    e.preventDefault();
                    return;
            }
            if (e.values.SCount != undefined) {
                if (this.$scope.model.isExpanded) {
                    for (var i = 0; i < this.$scope.model.products.length; i++) {
                        var product: IOrderModelLocal = this.$scope.model.products[i];
                        if (product.Supplies_id == focusedRowModel.Supplies_id) {

                            var RCount = ((product.SCount + product.RCount) - e.values.SCount);
                            product.RCount = RCount;
                            product.SCount = e.values.SCount;
                            product.Vg = product.PriceG * product.SCount;
                            break;
                        }
                    };

                    this.$timeout(() => {
                        this.$scope.ordersGrid.dataSource.data(this.$scope.model.products);

                        if (this.$scope.model.supplie.Product != undefined) {
                            this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
                        }
                    });
                }
                else {
                    var fullScount = e.values.SCount;
                    var groupedList = this.groupProducts(this.$scope.model.products);

                    for (var i = 0; i < groupedList.length; i++) {
                        var groupedFocusedRowModel = groupedList[i];
                        if (groupedFocusedRowModel.ProdPP_id == focusedRowModel.ProdPP_id) {

                            if ((groupedFocusedRowModel.RCount + groupedFocusedRowModel.SCount) < fullScount) {
                                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notenoughremains'));
                                e.preventDefault();
                                return;
                            }

                            if (groupedFocusedRowModel.SCount /* <- ძველი (დაჯგუფებული), ახალი (დაჯგუფებული) -> */ > fullScount) {
                                // რაოდენობამ დაიკლო, გაიზარდა ნაშთი

                                var countForAdd = groupedFocusedRowModel.SCount - fullScount;

                                for (var j = 0; j < this.$scope.model.products.length; j++) {

                                    var product = this.$scope.model.products[j];
                                    if (product.ProdPP_id == groupedFocusedRowModel.ProdPP_id) {
                                        if (product.SCount >= countForAdd) {
                                            product.SCount = product.SCount - countForAdd;
                                            product.RCount = product.RCount + countForAdd;
                                            product.Vg = product.SCount * product.PriceG;
                                            countForAdd = -1;
                                        }
                                        else {
                                            countForAdd -= product.SCount;
                                            product.RCount += product.SCount;
                                            product.SCount = 0;
                                            product.Vg = product.SCount * product.PriceG;
                                        }

                                        if (countForAdd <= 0) {
                                            break;
                                        }
                                    }
                                }
                            }

                            if (groupedFocusedRowModel.SCount /* <- ძველი (დაჯგუფებული), ახალი (დაჯგუფებული) -> */ < fullScount) {
                                // რაოდენობამ მოიმატა, ნაშთმა დაიკლო 

                                var countForAdd = fullScount - groupedFocusedRowModel.SCount;

                                for (var j = 0; j < this.$scope.model.products.length; j++) {

                                    var product = this.$scope.model.products[j];
                                    if (product.ProdPP_id == groupedFocusedRowModel.ProdPP_id) {

                                        if (product.RCount >= countForAdd) {
                                            product.RCount = product.RCount - countForAdd;
                                            product.SCount = product.SCount + countForAdd;
                                            product.Vg = product.SCount * product.PriceG;
                                            countForAdd = -1;
                                        }
                                        else {
                                            countForAdd -= product.RCount;
                                            product.SCount += product.RCount;
                                            product.RCount = 0;
                                            product.Vg = product.SCount * product.PriceG;
                                        }

                                        if (countForAdd <= 0) {
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    var d = this.groupProducts(this.$scope.model.products);
                    this.$timeout(() => {
                        this.$scope.ordersGrid.dataSource.data(d);

                        if (this.$scope.model.supplie.Product != undefined) {
                            this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
                        }
                    });


                }
                this.makeFormDirty();
            }

        }

        getProductForAddLookUp = () => {
            var productsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'Product.BCode', width: 100, titleTranslated: 'barcode' },
                        { field: 'Product.Name', titleTranslated: 'product' },
                        { field: 'RCount', titleTranslated: 'rest', format: Core.Kendo.Format.Price4, width: 70, }
                    ],
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        schema: {
                            model: {
                                fields: {
                                    'Product.BCode': { type: 'string' },
                                    'Product.Name': { type: 'string' },
                                    'RCount': { type: 'number' }
                                }
                            }
                        }

                    }),
                    valueText: 'Product.Name'
                }

            return productsLookup;
        }



        getOrdersGridConfig = (): Components.IApxGrid => {
            var ordersGrid: Components.IApxGrid =
                {
                    columns: [
                        { field: 'BCode', titleTranslated: 'barcode', width: 150 },
                        { field: 'Name', titleTranslated: 'goods' },
                        { field: 'CategoryName', titleTranslated: 'categoryName', width: 250 },
                        { field: 'ProducerName', titleTranslated: 'producer', width: 250 },
                        { field: 'Code', titleTranslated: 'code', width: 100 },
                        { field: 'RCount', titleTranslated: 'rest', width: 100, format: Core.Kendo.Format.Price4 },
                        { field: 'SCount', titleTranslated: 'Count', editable: true, width: 100, format: Core.Kendo.Format.Price4 },
                        { field: 'Vg', titleTranslated: 'vg', width: 100, format: Core.Kendo.Format.Price4 },
                        { field: 'SuppliesName', titleTranslated: 'supplier', width: 200 },
                        { field: 'SuppDate', titleTranslated: 'suppDate', format: Core.Kendo.Format.Date, width: 100 }
                    ],
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Apex.Components.EventType.Cancel, eFunction: (e: any) => {
                                this.$scope.model.addedProduct = { count: 0, RCount: 0 };
                            }
                        },
                        {
                            eType: Apex.Components.EventType.Save, eFunction: (e: any) => {
                                if (e.model != undefined) {
                                    this.ordersGridEditEventFunctionality(e);
                                }
                                else {
                                    if (this.$scope.model.addedProduct.Product == undefined) {
                                        Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseProductAlert')));
                                        e.preventDefault();
                                        return;
                                    }
                                    if (this.$scope.model.addedProduct.count == undefined || this.$scope.model.addedProduct.count == 0) {
                                        Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'შეუძლებელია ჩანაწერის დამატება ნულოვანი მნიშვნელობით'));
                                        e.preventDefault();
                                        return;
                                    }


                                    var addedProduct: any = (this.$scope.model.addedProduct);

                                    if (addedProduct.count > addedProduct.RCount) {
                                        Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notenoughremains')));

                                        e.preventDefault();
                                        return;
                                    }

                                    var product: IOrderModelLocal = {
                                        ID: 'local_id_' + this.$scope.counter,
                                        Code: addedProduct.Code,
                                        BCode: addedProduct.Product.BCode,
                                        CategoryName: addedProduct.Product.Category.CategoryName,
                                        Name: addedProduct.Product.Name,
                                        ProducerName: addedProduct.Product.Producer.ProducerName,
                                        ProdPP_id: addedProduct.ProdPP_id,
                                        RCount: addedProduct.RCount - addedProduct.count,
                                        SuppDate: addedProduct.SuppDate,
                                        SuppliesName: addedProduct.SuppliesName,
                                        SCount: addedProduct.count
                                    };

                                    if (this.$scope.model.isExpanded) {

                                        product.Supplies_id = addedProduct.ID;

                                        for (var i = 0; i < this.$scope.model.products.length; i++) {
                                            var obj = this.$scope.model.products[i];

                                            if (obj.Supplies_id == product.Supplies_id || (obj.Supplies_id == undefined && obj.ProdPP_id == product.ProdPP_id)) {
                                                Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'მსგავსი ჩანაწერი უკვე დამატებულია'));
                                                e.preventDefault();
                                                return;
                                            }
                                        }

                                        product.Vg = addedProduct.Vg;
                                        product.PriceG = addedProduct.Vg / addedProduct.count;

                                        this.$scope.model.products.unshift(product);
                                        this.$scope.ordersGrid.dataSource.data(this.$scope.model.products);
                                    }
                                    else {

                                        for (var i = 0; i < this.$scope.model.products.length; i++) {
                                            if (this.$scope.model.products[i].ProdPP_id == product.ProdPP_id) {
                                                Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'მსგავსი ჩანაწერი უკვე დამატებულია'));
                                                e.preventDefault();
                                                return;
                                            }
                                        }

                                        product.Vg = addedProduct.Vg * addedProduct.count;
                                        product.PriceG = addedProduct.Vg;

                                        this.$scope.model.products.unshift(product);
                                        this.$scope.ordersGrid.dataSource.data(this.groupProducts(this.$scope.model.products));
                                    }

                                    if (this.$scope.model.supplie.Product != undefined) {
                                        this.fillRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount);
                                    }

                                    this.$scope.ordersGrid.grid.dataSource.sync();
                                    this.makeFormDirty();
                                    this.$scope.counter++;
                                    this.$scope.model.addedProduct = { count: 0, RCount: 0 };
                                }
                            }
                        }],
                    settings: {
                        selectable: 'multiple',
                        editble: {
                            popupTemplateID: 'production_popup_editor',
                            popupWidth: 600
                        },
                        height: 300,
                        gridButtons: {
                            add: {
                                enabled: true,
                                id: "Product"
                            },
                            multiDelete: {
                                enabled: true,
                                id: "Product"
                            }
                        }
                    },
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        schema: {
                            model: {
                                id: "ID",
                                fields: {
                                    'BCode': { editable: false, type: 'string' },
                                    'Name': { editable: false, type: 'string' },
                                    'CategoryName': { editable: false, type: 'string' },
                                    'ProducerName': { editable: false, type: 'string' },
                                    'Code': { editable: false, type: 'string' },
                                    'RCount': { editable: false, type: 'number' },
                                    'Vg': { editable: false, type: 'number' },
                                    'SuppliesName': { editable: false, type: 'string' },
                                    'SuppDate': { editable: false, type: 'date' },
                                    'SCount': { type: 'number' }
                                }
                            }
                        }
                    })
                }
            return ordersGrid;
        }


        getRecipesGridConfig = (): Components.IApxGrid => {
            var recipesGrid: Components.IApxGrid =
                {
                    columns: [{
                        field: 'Materrial.BCode', titleTranslated: 'barcode', width: 150,
                        template: '<span style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: Materrial.BCode #</span>'
                    },
                        {
                            field: 'Materrial.Name', titleTranslated: 'goods',
                            template: '<span style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: Materrial.Name #</span>'
                        },
                        {
                            field: 'Materrial.Category.CategoryName', width: 250, titleTranslated: 'categoryName',
                            template: '<span style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: Materrial.Category.CategoryName #</span>'
                        },
                        {
                            field: 'Materrial.Producer.ProducerName', width: 250, titleTranslated: 'producer',
                            template: '<span style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: Materrial.Producer.ProducerName #</span>'
                        },
                        {
                            field: 'Materrial.InCode', titleTranslated: 'code', width: 200,
                            template: '<span style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: Materrial.InCode #</span>'
                        },
                        {
                            field: 'MCount', titleTranslated: 'recipe', width: 100, format: Core.Kendo.Format.Price4,
                            template: '<span ng-hide="#=MCount==0#" style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: MCount.toFixed(4) #</span>'
                        },
                        {
                            field: 'MisCount', titleTranslated: 'miscount', width: 100, format: Core.Kendo.Format.Price4,
                            template: '<span ng-hide="#=MisCount==0#" style="#= (MisCount!=0) ? "color: \\#FF0000;" : "" #">#: MisCount #</span>'
                        }
                    ],
                    settings: { height: 300 },
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        schema: {
                            model: {
                                id: "Materrial.ID",
                                fields: {
                                    'Materrial.BCode': { type: 'string' },
                                    'Materrial.Name': { type: 'string' },
                                    'Materrial.Category.CategoryName': { type: 'string' },
                                    'Materrial.Producer.ProducerName': { type: 'string' },
                                    'Materrial.InCode': { type: 'string' },
                                    'MCount': { type: 'number' },
                                    'MisCount': { type: 'number' }
                                }
                            }
                        }
                    })
                }
            return recipesGrid;
        }


        saveDoc = () => {
            var modalBook = this.$scope.formScope.modalBook;
            if (modalBook.$invalid) {
                this.toaster.pop('error', this.translate.instant('DIALOGS_ERROR'), 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი !');
                return;
            }
            var model: IProductionModel = this.$scope.model;

            if (this.$scope.model.supplie.Product == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'აირჩიეთ გამოსაშვები პროდუქტი.');
                return;
            }

            if (this.$scope.model.supplie.ICount == 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'მიუთითეთ გამოსაშვები რაოდენობა.');
                return;
            }

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosedebetcredit'));
                return;
            }

            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosedebet'));
                return;
            }

            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosecredit'));
                return;
            }

            var doc: Interfaces.IDoc = {
                DB: this.$scope.baseModel.debet.Acc,
                CR: this.$scope.baseModel.credit.Acc,
                DDate: model.crDate,
                Operid: model.operID,
                NumberIn: model.numberIn,
                NumberOut: model.numberOut,
                Supplies: [{
                    ProdPP_id: this.$scope.model.supplie.Product.ProdPPId,
                    ICount: this.$scope.model.supplie.ICount,
                    Code: this.$scope.model.supplie.Code
                }]
            }

            if (_.isDate(this.$scope.model.supplie.LifeDate)) {
                doc.Supplies[0].LifeDate = this.$scope.model.supplie.LifeDate;
            }

            if (this.$scope.baseModel.docID != undefined) {
                doc.Supplies[0].ID = this.$scope.model.supplie.ID;
            }

            if (!this.$scope.isCopied)
                doc.DocID = this.$scope.baseModel.docID;
            doc.Orders = [];

            _.forEach(this.$scope.model.products, (item: IOrderModelLocal) => {
                if (item.SCount != undefined && item.SCount != null && item.SCount > 0) {
                    var order: Interfaces.IOrder = {};
                    order.SCount = item.SCount;
                    order.Supplie = {
                        ProdPP_id: item.ProdPP_id
                    };
                    if (item.Supplies_id != undefined && item.Supplies_id.length > 0) {
                        order.Supplies_id = item.Supplies_id;
                    }
                    if (item.ID.toLowerCase().indexOf("local_id_") < 0) {
                        order.ID = item.ID;
                    }

                    doc.Orders.unshift(order);
                }
            });

            if (doc.DocID != undefined && doc.Orders.length === 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('productlistisempty'));
                return;
            }
            
            if (doc.DocID == undefined) {
                var orders = this.groupProducts(this.$scope.model.products);

                this.accountService.getProductRecipe(this.$scope.model.supplie.Product.ProdPPId, this.$scope.model.supplie.ICount).then(resp => {
                    var result: string = "რეცეპტი დარღვეულია: <br>";
                    var makeAlert = false;
                    if (resp.Result.length > 0) {
                        _.forEach(resp.Result, (item: IProductRecipeExt) => {
                            item.MisCount = parseFloat(this.getMisCount(item.MCount, item.Materrial.ID).toFixed(4));
                        });

                        var target = resp.Result;

                        _.forEach(target, (recipeItem: IProductRecipeExt) => { //changed or deleted
                            if (recipeItem.MisCount != 0) {
                                result += "საქონელი: " + recipeItem.Materrial.Name + ". სხვაობა: " + ((-1) * recipeItem.MisCount) + ". <br> ";
                                if (makeAlert == false) makeAlert = true;
                            }
                        });

                        _.forEach(orders, (order: IOrderModelLocal) => { //added
                            var obj: IProductRecipeExt = <IProductRecipeExt>_.first(_.reject(target, function (itm: IProductRecipeExt) { return itm.Materrial.ID != order.ProdPP_id; }));
                            if (obj == undefined) {
                                result += "საქონელი: " + order.Name + ". სხვაობა: " + order.SCount + ". <br>";
                                if (makeAlert == false) makeAlert = true;
                            }
                        });
                    }

                    if (makeAlert) {
                        result += "<br> გსურთ გაგრძელება? "
                        var r = Core.Utils.ModalForm.openConfirmation(result);
                        r.result.then(() => {
                            this.saveDocument(doc);
                        });
                    }
                    else {
                        this.saveDocument(doc);
                    }
                });
            }
            else {
                this.saveDocument(doc);
            }
        }



        getPartial(): string {
            return 'modalsproduction.html';
        }
    }

}