/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IGood {
        ID?: string;
        BCode?: string;
        BBCode?: string;
        InCode?: string;
        Name?: string;
        NameEn?: string;
        PackBCount?: number;
        PackCount?: number;
        Netto?: number;
        ByWeight?: number;
        CategoryID?: string;
        Category?: IGoodCategory;
        PPCatID?: string;
        PCatID?: number;
        ProducerID?: string;
        Producer?: IProducer;
        Vat?: number;
        LifeDays?: number;
        Unit?: string;
        ParentID?: string;
        MaxDiscount?: number;
        IsImported?: boolean;
        Dimensions?: IProdDimensions;
        PackCCount?: number;
        PDose?: string;
        IntCodeID?: string;
        IntCode?: IIntCode;
        ExciseID?: string;
        Excise?: number;
        DestNumber?: string;
        PackTypeID?: number;
        PriceGR?: number;
        PriceGT?: number;
    }


    export interface IGoodSelectable extends IGood {
        isSelected: boolean;
    }

} 