/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {

    export interface IGoodReturnModel {
        operID?: string;
        crDate?: Date;
        numberIn?: string;
        numberOut?: string;
        isProject?: boolean;
        supplies?: any;
        newProduct?: any;
        presaler?: any;
    }
    export interface IGoodReturnScope extends IBaseBookScope {
        model: IGoodReturnModel;
        isDisabled: boolean;
        documentsActiveInModal: boolean;
        tabEvents: { tabindexchanged: Function };
        hasPresaler: boolean;
        components: {
            productsGridOptions: Components.IApxGrid;
            productsGridOptionsForModal: Components.IApxGrid;
            documentsGridOptionsForModal: Components.IApxGrid;
            presalerLookup?: Components.IApxLookup;
        };

        events: { refresh: Function };

        tempModelForAdd: {
            date: any;
            bCode?: string;
            productName?: string;
        };
    }

    export class GoodReturn extends BaseBook {
        $scope: IGoodReturnScope;
        distributionService: any;
        constructor($scope: IGoodReturnScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            
            this.$scope = $scope;

            if ($scope.docInfo.DocID) {
                $scope.isDisabled = true;
            }
            else {
                $scope.isDisabled = false;
            }

            $scope.model = this.toGoodReturnModel($scope.docInfo, data.Operation.OperID);
            if (data.Operation.OperID == 309) {
                $scope.hasPresaler = true;

            }
            else {
                $scope.hasPresaler = false;

            }

            $scope.tempModelForAdd = {
                date: $scope.model.crDate
            };

            $scope.components = {
                documentsGridOptionsForModal: this.documentGridOptionForModal(),
                productsGridOptions: this.productGridOption($scope.model.supplies),
                productsGridOptionsForModal: this.productGridOptionForModal()

            };

            if ($scope.hasPresaler) {
                $scope.components.presalerLookup = this.getPresalerLookupOptions();
            }

            $scope.saveDoc = this.saveDoc;

            $scope.events = {
                refresh: () => {
                    if ($scope.documentsActiveInModal) {
                        accountService.getReturnableDocuments(
                            $scope.tempModelForAdd.date.fromDate,
                            $scope.tempModelForAdd.date.toDate,
                            $scope.baseModel.credit.Acc
                        )
                            .then
                            (
                            res => {
                                this.$scope.components.documentsGridOptionsForModal.dataSource.data(res.Result);
                            }
                            );
                    }
                    else {
                        accountService.getReturnableProducts
                            ($scope.baseModel.credit.Acc,
                            $scope.tempModelForAdd.date.fromDate,
                            $scope.tempModelForAdd.date.toDate,
                            $scope.tempModelForAdd.bCode,
                            $scope.tempModelForAdd.productName
                            )
                            .then
                            (
                            res => {
                                var data = this.getConvertedReturnableProducts(res.Result);
                                this.$scope.components.productsGridOptionsForModal.dataSource.data(data);
                            }
                            );
                    }
                }
            };

            $scope.tabEvents = {
                tabindexchanged: (index) => {
                    switch (index) {
                        case 0:
                            {
                                $scope.documentsActiveInModal = true;
                                break;
                            }
                        case 1:
                            {
                                $scope.documentsActiveInModal = false;
                                break;
                            }
                    }
                }
            };

            $scope.$watch("baseModel.credit", (credit: any) => {
                if (credit != undefined) {
                    $('.k-button.k-button-icontext.k-grid-edit').removeClass("k-state-disabled");
                }
            });


            this.$timeout(() => {
                $('#multiDelete').on('click', () => {
                    this.multiDeleteProductsGrid();
                });
            });

            this.$timeout(() => {
                if (!$scope.docInfo.DocID)
                    $('.k-button.k-button-icontext.k-grid-edit').addClass("k-state-disabled");

                $('.k-button.k-button-icontext.k-grid-edit').click(() => {
                    this.$scope.documentsActiveInModal = true;

                    this.$timeout(() => {
                        $('#dateBetweenGoodReturn').find('.btn').css("width", "35px");
                    });

                    $scope.components.documentsGridOptionsForModal.dataSource.data([]);
                    $scope.components.productsGridOptionsForModal.dataSource.data([]);
                    $scope.tempModelForAdd.bCode = $scope.tempModelForAdd.productName = '';

                    var date;
                    if ($scope.docInfo.DocID) {
                        date = new Date(this.todate($scope.docInfo.DDate));

                    }
                    else {
                        date = new Date();
                    }

                    $scope.tempModelForAdd.date.toDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                    $scope.tempModelForAdd.date.fromDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), 0, 0, 0, 0);

                });
            });

        }


        getConvertedReturnableProducts = (returnableProducts) => {
            var result = [];

            _.forEach(returnableProducts, (r: any) => {
                var soldQuantitySum = 0;
                var quantitySum = 0;
                _.forEach(r.Sales, (sale: any) => {
                    soldQuantitySum += sale.SoldQuantity;
                    quantitySum += sale.Quantity;
                });

                var temp: any = {
                    SCount: soldQuantitySum,
                    ICount: soldQuantitySum - quantitySum,
                    RCount: soldQuantitySum - (soldQuantitySum - quantitySum),
                    Price: r.PriceG,
                    Vat: r.Vat,
                    BCode: r.ProdInfo.BCode,
                    BBCode: r.ProdInfo.BBCode,
                    InCode: r.ProdInfo.InCode,
                    Products_nu: r.ProdInfo.Name,
                    Unit: r.ProdInfo.Unit,
                    Producer_nu: r.ProdInfo.Producer.ProducerName,
                    PackCount: r.ProdInfo.PackCount,
                    PackBCount: r.ProdInfo.PackBCount,
                    Category_nu: r.ProdInfo.Category == undefined ? '' : r.ProdInfo.Category.CategoryName,
                    PrCountry: r.ProdInfo.Producer == undefined ? '' : r.ProdInfo.Producer.ProducerCountry
                }

                var firstSale = r.Sales[0];

                if (firstSale != undefined) {
                    temp.OrdersID = firstSale.SaleId;
                    temp.BookID = firstSale.SaleBookId;
                    temp.DDate = firstSale.DDate;
                    temp.PresalerName = firstSale.PresalerName;
                    temp.PresalerId = firstSale.PresalerId;
                    temp.NumberIn = firstSale.NumberIn;
                    temp.NumberOut = firstSale.NumberOut;
                }

                result.push(temp);

            });

            return result;
        }

        getPartial(): string {
            return 'modalsgood.return.html';
        }

        saveDoc = () => {

            var modalBook = this.$scope.formScope.modalBook;
            if (modalBook.$invalid) {
                this.toaster.pop('error', this.translate.instant('DIALOGS_ERROR'), 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი !');
                return;
            }
            var model = this.$scope.model;

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosedebetcredit'));
                return;
            }
            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosedebet'));
                return;
            }
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('choosecredit'));
                return;
            }

            var doc: Interfaces.IDoc = {
                DB: this.$scope.baseModel.debet.Acc,
                CR: this.$scope.baseModel.credit.Acc,
                DDate: model.crDate,
                Operid: model.operID,
                NumberIn: model.numberIn,
                NumberOut: model.numberOut,
                IsProject: model.isProject
            }
            if (!this.$scope.isCopied)
                doc.DocID = this.$scope.baseModel.docID;

            if (this.$scope.hasPresaler) {
                if (this.$scope.model.presaler != undefined && this.$scope.model.presaler != null)
                    doc.PresalerId = this.$scope.model.presaler.Id;
            }

            doc.Supplies = [];

            var supplies = _.values(this.$scope.model.supplies);

            _.forEach(supplies, (item: any) => {
                var supplie: Interfaces.ISupplie = {};
                supplie.ICount = item.count;

                if (!this.$scope.isCopied)
                    supplie.ID = item.supplieID;

                supplie.ProdPP_id = item.prodPPId;
                supplie.Orders_id = item.orderID;
                if (supplie.ICount > 0)
                    doc.Supplies.push(supplie);
            });

            if (supplies.length === 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('productlistisempty'));
                return;
            }

            if (doc.Supplies.length === 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), "გთხოვთ შეავსოთ პროდუქტის(ების) რაოდენობა");
                return;
            }

            
            this.saveDocument(doc);


        }

        suppliesDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,

                aggregate:
                [
                    { field: "vgWithOutVat", aggregate: "sum" },
                    { field: "outcome", aggregate: "sum" },
                    { field: "vg", aggregate: "sum" },
                    { field: "count", aggregate: "sum" },
                    { field: "cost", aggregate: "sum" },
                    { field: "priceWithVat", aggregate: "sum" },
                    { field: "itemPrice", aggregate: "sum" },
                    { field: "itemPriceWithoutVat", aggregate: "sum" },
                    { field: "bCode", aggregate: "count" }
                ],
                schema: {
                    parse: this.getProductsDatasource,
                    model: {
                        id: "orderID",
                        fields: {
                            prodName: { editable: false, type: "string" },
                            unit: { editable: false, type: "string" },
                            producer: { editable: false, type: "string" },
                            bCode: { editable: false, type: "string" },
                            categoryName: { editable: false, type: "string" },
                            vgWithOutVat: { editable: false, type: "number" },
                            outcome: { editable: false, type: "number" },
                            rCount: { editable: false, type: "number" },
                            vg: { editable: false, type: "number" },
                            itemPrice: { editable: false, type: "number" },
                            priceWithVat: { editable: false, type: "number" },
                            lifedate: { editable: false, type: "date" },
                            markerCode: { editable: false, type: "string" },
                            realizationDate: { editable: false, type: "date" },
                            realizationDocIncode: { editable: false, type: "string" },
                            vat: { editable: false, type: "number" },
                            count: {type: "number" }
                        }
                    }
                }
            });
            return ds;
        }

        getProductsDatasource = (val) => {    
            return _.values(val);
        }

        private productGridOption(datasource: any): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: this.productsGridColumns,
                    dataSource: this.suppliesDatasource(datasource),
                    settings:
                    {
                        selectable: "multiple",
                        gridButtons: {
                            multiDelete: { enabled: true },
                            add: { enabled: true },
                            exportToExcell: true
                        },
                        height: 500,
                        editble: {
                            popupTemplateID: 'good_return_popup_editor',
                            popupWidth: 1200
                        }
                    },
                    parentScope: this.$scope,
                    gridEvents: [
                        this.getEditEvent(),
                        this.getProductAddEvent(),
                        this.getProductsCancelEvent()
                    ]
                };
            return gridOptions;
        }

        private getPresalerLookupOptions(): Components.IApxLookup {
            return {
                columns: [{ field: 'Code', width: 50, titleTranslated: 'code' },
                    { field: 'FullName', titleTranslated: 'name' }
                ],
                valueText: 'FullName',
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            var scope = this.$scope;
                            var distributionService: any = Core.Utils.Helper.getServiceFromJs("distributionService");
                            var fn = distributionService.getPresalers();
                            fn.then(result => {
                                if (scope.docInfo.DocID != undefined) {
                                    var presaler = _.where(result.Result, { Id: scope.docInfo.PresalerId })[0];
                                    scope.model.presaler = presaler;
                                }
                                options.success(result.Result || []);
                            });
                        }
                    }
                })
            };
        }

        private productGridOptionForModal(): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: [

                        { field: 'DDate', titleTranslated: 'DATE', width: 80, format: Core.Kendo.Format.Date },
                        { field: 'NumberIn', titleTranslated: 'numberin', width: 80 },
                        { field: 'NumberOut', titleTranslated: 'numberout', width: 80 },
                        { field: 'PresalerName', titleTranslated: 'presaler', width: 120 },
                        { field: 'BCode', titleTranslated: 'barcode', width: 110 },
                        { field: 'Products_nu', titleTranslated: 'caption' },
                        { field: 'Unit', titleTranslated: 'unit', width: 60 },
                        { field: 'Price', titleTranslated: 'price', width: 60, format: Core.Kendo.Format.Price2 },
                        { field: 'Vat', titleTranslated: 'vat', width: 60, format: Core.Kendo.Format.Percent },
                        { field: 'SCount', titleTranslated: 'sold', width: 60, format: Core.Kendo.Format.Price2 },
                        { field: 'ICount', titleTranslated: 'returned', width: 60, format: Core.Kendo.Format.Price2 },
                        { field: 'RCount', titleTranslated: 'rest', width: 60, format: Core.Kendo.Format.Price2 }

                    ],
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        schema: {
                            model: {
                                fields: {
                                    DDate: { type: "date" },
                                    NumberIn: { type: "string" },
                                    NumberOut: { type: "string" },
                                    PresalerName: { type: "string" },
                                    BCode: { type: "string" },
                                    Products_nu: { type: "string" },
                                    Unit: { type: "string" },
                                    Price: { type: "number" },
                                    Vat: { type: "number" },
                                    SCount: { type: "number" },
                                    ICount: { type: "number" },
                                    RCount: { type: "number" },
                                }
                            }
                        }
                    }),
                    settings:
                    {
                        selectable: "multiple",
                        height: 400
                    }

                };
            return gridOptions;
        }
        private documentGridOptionForModal(): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: [
                        { field: 'DDate', titleTranslated: 'DATE', width: 50, format: Core.Kendo.Format.Date },
                        { field: 'NumberIn', titleTranslated: 'numberin', width: 50 },
                        { field: 'NumberOut', titleTranslated: 'numberout', width: 50 },
                        { field: 'WaybillNum', titleTranslated: 'WaybillNum', width: 50 },
                        { field: 'PresalerName', titleTranslated: 'presaler', width: 150 }
                    ],
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        schema: {
                            model: {
                                fields: {
                                    DDate: { type: 'date' },
                                    NumberIn: { type: 'string' },
                                    NumberOut: { type: 'string' },
                                    WaybillNum: { type: 'string' },
                                    PresalerName: { type: 'string' }
                                }
                            }
                        }

                    }),
                    settings:
                    {
                        selectable: "multiple",
                        height: 400
                    }

                };
            return gridOptions;
        }

        private getProductsCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.productsGridCancelEventFunctionality
                };
            return cancelEvent;
        }

        multiDeleteProductsGrid = () => {
            var selectedRows = this.$scope.components.productsGridOptions.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                for (var i = 0; i < selectedRows.length; i++) {
                    var model: any = this.$scope.components.productsGridOptions.grid.dataItem(selectedRows[i]);
                    delete this.$scope.model.supplies['"' + model.orderID + '"'];
                }
                this.makeFormDirty();
                this.$scope.components.productsGridOptions.grid.dataSource.read();
            });
        }


        productsGridCancelEventFunctionality = (e: kendo.ui.GridEvent) => {
            // this.$scope.components.productsGridOptions.grid.dataSource.read();
            this.$timeout(() => { this.$scope.isBusy = false; });
        }

        private getProductAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.productsGridAddEventFunctionality
                };
            return addEvent;
        }


        productsGridAddEventFunctionality = (e) => {
            if (e.model != undefined) {
                switch (null) {
                    case e.values.count:
                        e.preventDefault();
                        return;
                }
                if (e.values.count != undefined) {
                    var oldcount = this.$scope.model.supplies['"' + e.model.orderID + '"'].count;
                    if (e.values.count > (oldcount + e.model.rCount)) {
                        this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notenoughremains'));
                        e.preventDefault();
                        return;
                    }

                    e.model.count = e.values.count;
                    e.model.rCount = (e.model.rCount + oldcount) - e.values.count;
                    e.model.vg = e.values.count * e.model.vgForCalc;
                    e.model.priceWithVat = e.model.itemPrice * e.values.count;
                    e.model.vgWithOutVat = (e.model.itemPrice * e.values.count) / (1 + e.model.vat);

                    this.$scope.model.supplies['"' + e.model.orderID + '"'] = e.model;
                    this.$scope.components.productsGridOptions.dataSource.read();
                    this.makeFormDirty();
                }
            }
            else {
                var bookIds: Array<string> = [];
                if (this.$scope.documentsActiveInModal) {
                    kendo.ui.progress($('.k-widget.k-window'), true);
                    var selectedRows: any = this.$scope.components.documentsGridOptionsForModal.grid.select();

                    if (selectedRows.length == 0) {
                        Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.toaster.pop('error', this.translate.instant('Attention'), "აირჩიეთ დოკუმენტი(ები).");
                            e.preventDefault();
                            return;
                        });
                    }

                    _.forEach(selectedRows, (i: any) => {
                        var model: any = this.$scope.components.documentsGridOptionsForModal.grid.dataItem(i);
                        bookIds.push(model.BookId);
                    });

                    var addedOrderIds = _.values(this.$scope.model.supplies).map(function (a) { return a.orderID; });

                    this.accountService.getReturnableProducts(this.$scope.baseModel.credit.Acc, null, null, null, null, bookIds).then(r => {
                        var newList = r.Result;

                        _.forEach(newList, (itm: any) => {
                            if (!_.contains(addedOrderIds, itm.Sales[0].SaleId)) {

                                var sumCostG = 0;
                                var sumQuantity = 0;
                                var sumSoldQuantity = 0;
                                _.forEach(itm.Sales, (sale: any) => {
                                    sumCostG += sale.CostG;
                                    sumQuantity += sale.Quantity;
                                    sumSoldQuantity += sale.SoldQuantity;
                                });

                                var averageCostG = sumCostG / itm.Sales.length;

                                this.$scope.model.supplies['"' + itm.Sales[0].SaleId + '"'] = {
                                    prodPPId: itm.ProdInfo.ID,
                                    vgForCalc: averageCostG,
                                    //vg: averageCostG.toFixed(4),
                                    producer: itm.ProdInfo.Producer != undefined ? itm.ProdInfo.Producer.ProducerName : '',
                                    count: 0,
                                    outcome: sumSoldQuantity,
                                    unit: itm.ProdInfo.Unit,
                                    rCount: sumQuantity,
                                    packCount: itm.ProdInfo.PackCount,
                                    itemPrice: itm.PriceG,
                                    //itemPrice: sumSoldQuantity == 0 ? 0 : (averageCostG / sumSoldQuantity).toFixed(4),
                                    //  priceWithVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount) * item.ICount).toFixed(4),
                                    prodName: itm.ProdInfo.Name,
                                    bCode: itm.ProdInfo.BCode,
                                    //supplieID: item.ID,
                                    vat: itm.Vat,
                                    // vgWithOutVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount * item.ICount) / (1 + item.ReturnedSale.Vat)).toFixed(4),
                                    lifedate: this.todate(itm.LifeDate),
                                    markerCode: itm.Code,
                                    orderID: itm.Sales[0].SaleId,
                                    realizationDate: itm.Sales[0].DDate,
                                    realizationDocIncode: itm.Sales[0].NumberIn
                                };
                            }
                        });
                        e.sender.dataSource.read();
                        kendo.ui.progress($('.k-widget.k-window'), false);
                    });
                    e.preventDefault();
                }
                else {
                    kendo.ui.progress($('.k-widget.k-window'), true);
                    var selectedRows: any = this.$scope.components.productsGridOptionsForModal.grid.select();

                    if (selectedRows.length == 0) {
                        Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.toaster.pop('error', this.translate.instant('Attention'), "აირჩიეთ პროდუქტი(ები).");
                            e.preventDefault();
                            return;
                        });
                    }



                    var orderIds: Array<string> = [];
                    _.forEach(selectedRows, (i: any) => {
                        var model: any = this.$scope.components.productsGridOptionsForModal.grid.dataItem(i);
                        bookIds.push(model.BookID);
                        orderIds.push(model.OrdersID);
                    });

                    var addedOrderIds = _.values(this.$scope.model.supplies).map(function (a) { return a.orderID; });

                    this.accountService.getReturnableProducts(this.$scope.baseModel.credit.Acc, null, null, null, null, bookIds).then(r => {
                        var newList = r.Result;


                        var result = [];
                        _.forEach(newList, (itm: any) => {
                            if (_.contains(orderIds, itm.Sales[0].SaleId) == true) {
                                result.push(itm);
                            }
                        });

                        

                        _.forEach(result, (itm: any) => {
                            if (!_.contains(addedOrderIds, itm.Sales[0].SaleId)) {

                                var sumCostG = 0;
                                var sumQuantity = 0;
                                var sumSoldQuantity = 0;
                                _.forEach(itm.Sales, (sale: any) => {
                                    sumCostG += sale.CostG;
                                    sumQuantity += sale.Quantity;
                                    sumSoldQuantity += sale.SoldQuantity;
                                });

                                var averageCostG = sumCostG / itm.Sales.length;

                                this.$scope.model.supplies['"' + itm.Sales[0].SaleId + '"'] = {
                                    prodPPId: itm.ProdInfo.ID,
                                    //vg: averageCostG.toFixed(4),
                                    producer: itm.ProdInfo.Producer != undefined ? itm.ProdInfo.Producer.ProducerName : '',
                                    count: 0,
                                    outcome: sumSoldQuantity,
                                    unit: itm.ProdInfo.Unit,
                                    rCount: sumQuantity,
                                    packCount: itm.ProdInfo.PackCount,
                                    itemPrice: itm.PriceG,
                                    //itemPrice: sumSoldQuantity == 0 ? 0 : (averageCostG / sumSoldQuantity).toFixed(4),
                                    //  priceWithVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount) * item.ICount).toFixed(4),
                                    prodName: itm.ProdInfo.Name,
                                    bCode: itm.ProdInfo.BCode,
                                    vgForCalc: averageCostG.toFixed(4),
                                    //supplieID: item.ID,
                                    vat: itm.Vat,
                                    // vgWithOutVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount * item.ICount) / (1 + item.ReturnedSale.Vat)).toFixed(4),
                                    lifedate: this.todate(itm.LifeDate),
                                    markerCode: itm.Code,
                                    orderID: itm.Sales[0].SaleId,
                                    realizationDate: itm.Sales[0].DDate,
                                    realizationDocIncode: itm.Sales[0].NumberIn
                                };
                            }
                        });
                        e.sender.dataSource.read();
                        kendo.ui.progress($('.k-widget.k-window'), false);
                    });
                    e.preventDefault();
                }
            }
        }       

        private productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'bCode', titleTranslated: 'barcode', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.bCode ? data.bCode.count : 0) #' },
            { field: 'prodName', titleTranslated: 'product', width: 200 },
            { field: 'producer', titleTranslated: 'producer', width: 150 },
            { field: 'unit', titleTranslated: 'unit', width: 50 },
            { field: 'vat', titleTranslated: 'vat', width: 40, format: Core.Kendo.Format.Percent },
            { field: 'outcome', titleTranslated: 'outcome', width: 70, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.outcome && data.outcome.sum ? parseFloat(data.outcome.sum).toFixed(4) : 0) #' },
            { field: 'rCount', titleTranslated: 'rest', width: 50, format: Core.Kendo.Format.Price4 },
            { field: 'count', titleTranslated: 'Count', width: 70, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.count && data.count.sum ? parseFloat(data.count.sum).toFixed(4) : 0) #' },
            { field: 'vg', titleTranslated: 'cost', width: 90, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vg ? parseFloat(data.vg.sum).toFixed(4) : 0) #' },
            { field: 'itemPrice', titleTranslated: 'PriceG', width: 90, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.itemPrice && data.itemPrice.sum ? parseFloat(data.itemPrice.sum).toFixed(4) : 0) #' },
            { field: 'vgWithOutVat', titleTranslated: 'vgWithOutVat', width: 90, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vgWithOutVat  && data.vgWithOutVat.sum  ? parseFloat(data.vgWithOutVat.sum).toFixed(4) : 0) #' },
            { field: 'priceWithVat', titleTranslated: 'PriceWithVat', width: 90, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.priceWithVat &&  data.priceWithVat.sum ? parseFloat(data.priceWithVat.sum).toFixed(4) : 0) #' },
            { field: 'lifedate', titleTranslated: 'lifeDate', width: 80, format: Core.Kendo.Format.Date },
            { field: 'markerCode', titleTranslated: 'goodMarkerCode', width: 90 },
            { field: 'realizationDate', titleTranslated: 'realizationDate', width: 80, format: Core.Kendo.Format.Date },
            { field: 'realizationDocIncode', titleTranslated: 'realizationDocIncode', width: 80 }
        ];

        toGoodReturnModel(docInfo: Interfaces.IDoc, operid: any) {
            var model: IGoodReturnModel = { supplies: {} };
            if (!!docInfo.DocID) {
                model.crDate = this.$scope.baseModel.crDate;
                model.numberIn = docInfo.NumberIn;
                model.numberOut = docInfo.NumberOut;
                model.isProject = docInfo.IsProject;
                _.forEach(docInfo.Supplies, (item: Interfaces.ISupplie) => {
                    var supplieModel =
                        {
                            prodPPId: item.ProdPP_id,
                            vg: item.Vg,
                            producer: item.Product.Producer.ProducerName,
                            count: item.ICount,
                            outcome: item.ReturnedSale.SCount,
                            vgForCalc: item.Vg / item.ICount,
                            unit: item.Product.Unit,
                            rCount: item.ReturnedSale.Supplie.RCount,
                            packCount: item.Product.PackCount,
                            itemPrice: item.ReturnedSale.SCount == 0 ? 0 : (item.ReturnedSale.Vg / item.ReturnedSale.SCount),
                            priceWithVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount) * item.ICount),
                            prodName: item.Product.Name,
                            bCode: item.Product.BCode,
                            supplieID: item.ID,
                            orderID: item.Orders_id,
                            vat: item.ReturnedSale.Vat,
                            vgWithOutVat: item.ReturnedSale.SCount == 0 ? 0 : ((item.ReturnedSale.Vg / item.ReturnedSale.SCount * item.ICount) / (1 + item.ReturnedSale.Vat)),
                            lifedate: this.todate(item.ReturnedSale.Supplie.LifeDate),
                            markerCode: item.ReturnedSale.Supplie.Code,
                            realizationDate: item.ReturnedBook.DDate,
                            realizationDocIncode: item.ReturnedBook.NumberIn
                        };
                    model.supplies['"' + item.Orders_id + '"'] = supplieModel;
                });

            } else {
                model.crDate = new Date();
            }
            model.operID = operid;
            return model;
        }


    }
}