/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {

    export class PrivateCard extends Core.Controller {

        public static $inject = [
            Globals.scope, 'accountService',
            Globals.translate,
            '$timeout'
        ];

        private accountsGrid: Core.Kendo.Grid;
        private acc = '';
        private balance: any;

        constructor(private $scope: any, private accountService: Services.Account, private $translate, private $timeout) {
            super(arguments, PrivateCard.$inject);
            $scope.privateCard =
                {
                    changeStartBalanceName: '',
                    changeEndBalanceName: '',
                    showRefreshInfo: true,
                    selected: {
                        acc: {},
                        transactions: {}
                    },
                    dateBetween: {
                    },
                    balance: {
                        transactions: {
                            start: {},
                            end: {}
                        },
                        startAmount: '',
                        startSufix: '',
                        endAmount: '',
                        endSufix: ''
                    }
                }
            $scope.canDelete = false;
            $scope.canEdit = false;
            $scope.components = {
                accountsGrid: this.initAccountsGrid(),
                transactionsGrid: this.initTransactionsGrid(),
                events: {
                    delete: () => {
                        Utils.Documents.deleteDoc($scope.selectedDoc.DocID, $scope.components.events.refresh);
                        // this.$scope.canEdit = true;
                        // this.$scope.canDelete = true; 
                    },
                    edit: () => {
                        Utils.Documents.openDoc($scope.selectedDoc.DocID, false, $scope.components.events.refresh);
                        // delete this.$scope.selectedDoc;
                    },
                    copy: () => {
                        Utils.Documents.openDoc($scope.selectedDoc.DocID, true, $scope.components.events.refresh);
                        // delete this.$scope.selectedDoc;
                    },
                    refresh: () => {
                        if (this.$scope.privateCard.selected.acc != undefined) {
                            $scope.components.transactionsGrid.grid.dataSource.filter([]);
                            $scope.components.transactionsGrid.gridView.showLoading();
                            $scope.components.transactionsGrid.gridView.refresh();
                            $scope.privateCard.showRefreshInfo = false;
                        }
                    },
                    changeTransactionsView: (toView) => {
                        $scope.components.transactionsGrid.gridView.showLoading();
                        var pr = $scope.components.viewOptions;
                        this.reconfigColumns(toView);
                        $scope.components.transactionsGrid.gridView.hideLoading();
                    }
                },
                viewOptions: {
                    Vg: true,
                    Vu: false,
                    Vc: false
                }
            }

            this.$timeout(() => {
                this.$scope.privateCard.changeStartBalanceName = this.$translate.instant('startBalanceL');
                this.$scope.privateCard.changeEndBalanceName = this.$translate.instant('endBalanceL');
            });

            $scope.PrivateCardGridOptions = [{ val: "Vg", title: "(ლ)" }, { val: "Vu", title: "(პ)" }, { val: "Vc", title: "(ვ)" }];

            $scope.PrivateCardValue = { val: "Vg" };

            $scope.$watch('PrivateCardValue.val', (value) => {
                this.changeBalanceName(value);
            });

            $scope.accountBalance = {
                Vg: true,
                Vu: false,
                Vc: false
            }

            $scope.$watch('privateCard.dateBetween', () => {
                delete this.$scope.selectedDoc;
                $scope.privateCard.showRefreshInfo = true;
            }, true);

            $scope.navEvents = {
                delete: () => {
                    Utils.Documents.deleteDoc($scope.selectedDoc.DocID, this.refreshTransactionsGrid);
                    delete this.$scope.selectedDoc;
                },
                edit: () => {
                    Utils.Documents.openDoc($scope.selectedDoc.DocID, false, this.refreshTransactionsGrid);
                },
                copy: () => {
                    Utils.Documents.openDoc($scope.selectedDoc.DocID, true, this.refreshTransactionsGrid);
                }
            }

            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.transactionsGrid.grid.dataSource.data([]);
                $scope.components.accountsGrid.gridView.showLoading();
                $scope.components.accountsGrid.gridView.refresh();
            }
        }

        initAccountsGrid = () => {
            var accountsGrid: Components.IApxGrid = {
                columns: this.accountsColumns,
                settings: {
                    virtualScroll: true
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selectedacc = accountsGrid.grid.dataItem(accountsGrid.grid.select());
                                delete this.$scope.selectedDoc;
                                this.$scope.privateCard.balance.startAmount = '';
                                this.$scope.privateCard.balance.endAmount = '';
                                if (selectedacc != null) {
                                    this.$scope.privateCard.selected.acc = selectedacc;
                                    this.$scope.components.transactionsGrid.dataSource.data([]);
                                    if (this.$scope.privateCard.showRefreshInfo != true)
                                        this.$scope.privateCard.showRefreshInfo = true;
                                }
                                else {
                                    this.$scope.privateCard.showRefreshInfo = false;
                                    this.$scope.components.transactionsGrid.dataSource.data([]);
                                    delete this.$scope.privateCard.selected.acc;
                                }
                            });
                        }
                    },
                    {
                        eType: Components.EventType.DataBoud,
                        eFunction: (e) => {
                            if (this.$scope.privateCard.selected.acc == undefined || this.$scope.privateCard.selected.acc == null || this.$scope.privateCard.selected.acc.Acc === undefined) {
                                e.sender.select(e.sender.tbody.find('tr:first'));
                                e.sender.select(e.sender.tbody.find("tr[role='row']:first"));
                            }
                        }
                    }]
            };


            var datasourceModel = {
                fields: {
                    AccFullName: { type: 'string' }
                }
            }

            accountsGrid.dataSource = Core.Kendo.GridView.initAutoQueryDataSource('/Accounting/AccountsQuery', datasourceModel);
            return accountsGrid;
        }
        initTransactionsGrid = () => {
            var transactionsGrid: Components.IApxGrid = {
                columns: this.transactionsColumns,
                templates: this.templates,
                settings: {
                    autoBind: false,
                    virtualScroll: true
                },
                dataSource: this.transactionsDS(),
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedDoc = this.$scope.components.transactionsGrid.grid.dataItem(this.$scope.components.transactionsGrid.grid.select());
                                if (this.$scope.selectedDoc) {
                                    this.$scope.canEdit = false;
                                }
                                else
                                    this.$scope.canEdit = true;
                                if (this.$scope.selectedDoc) {
                                    if (this.$scope.selectedDoc.Blocking == true) {
                                        this.$scope.canDelete = true;
                                    }
                                    else
                                        this.$scope.canDelete = false;
                                }
                            });
                        }
                    },
                    {
                        eType: Components.EventType.DataBoud,
                        eFunction: (e) => {
                            if (this.$scope.selectedDoc != undefined || this.$scope.selectedDoc != null) {
                                var pageData = this.$scope.components.transactionsGrid.grid.dataSource.data();
                                for (var i = 0; i < pageData.length; i++) {
                                    if (pageData[i].BookID == this.$scope.selectedDoc.BookID) {
                                        $("[data-uid='" + pageData[i].uid + "']").addClass('k-state-selected');
                                        break;
                                    }
                                }
                            }
                            else if (this.$scope.selectedDoc === null || this.$scope.selectedDoc === undefined) {
                                e.sender.select(e.sender.tbody.find('tr:first'));
                                e.sender.select(e.sender.tbody.find("tr[role='row']:first"));
                            }
                        }
                    }]
            };
            return transactionsGrid;
        }

        refreshTransactionsGrid = () => {
            delete this.$scope.selectedDoc;
            this.$scope.trGridOptions.dataSource.read();
        }

        transactionsDS = () => {
            var ds = new kendo.data.DataSource({
                pageSize: 100,
                transport: {
                    read: (options) => {
                        var model = this.$scope;
                        this.getTransactiosn(options);
                    }
                },
                schema: {
                    model: {
                        fields: {
                            Blocking: { type: 'boolean' },
                            RecStatus: { type: 'number' },
                            dbVg: { type: "number" },
                            crVg: { type: "number" },
                            trVg: { type: "number" },
                            dbVu: { type: "number" },
                            crVu: { type: "number" },
                            trVu: { type: "number" },
                            dbVc: { type: "number" },
                            crVc: { type: "number" },
                            trVc: { type: "number" },
                            DDate: { type: "date" },
                            NID: { type: "number" },
                            OpDetId: { type: "string" },
                            correspondentTemplate: { type: "string" },
                            Contents: { type: "string" },
                            NumberIn: { type: "string" },
                            NumberOut: { type: "string" },
                            CUser: { type: "string" },
                            CRTime: { type: "date" }
                        }
                    }
                },
                aggregate:
                [
                    { field: "dbVg", aggregate: "sum" },
                    { field: "crVg", aggregate: "sum" },
                    { field: "dbVu", aggregate: "sum" },
                    { field: "crVu", aggregate: "sum" },
                    { field: "dbVc", aggregate: "sum" },
                    { field: "crVc", aggregate: "sum" }
                ]
            });
            return ds;
        }
        changeBalanceName = (value) => {
            this.$scope.privateCard.balance.startSufix = this.$scope.privateCard.balance.transactions.start[value] < 0 ? "კრ." : "დბ.";;
            this.$scope.privateCard.balance.endSufix = this.$scope.privateCard.balance.transactions.end[value] < 0 ? "კრ." : "დბ.";
            this.$scope.privateCard.balance.startAmount = Math.abs(this.$scope.privateCard.balance.transactions.start[value]);
            this.$scope.privateCard.balance.endAmount = Math.abs(this.$scope.privateCard.balance.transactions.end[value]);

            if (value == "Vg") {
                this.$scope.privateCard.changeStartBalanceName = this.$translate.instant('startBalanceL');
                this.$scope.privateCard.changeEndBalanceName = this.$translate.instant('endBalanceL');
                return;
            }
            if (value == "Vu") {
                this.$scope.privateCard.changeStartBalanceName = this.$translate.instant('startBalanceU');
                this.$scope.privateCard.changeEndBalanceName = this.$translate.instant('endBalanceU');
                return;
            }
            if (value == "Vc") {
                this.$scope.privateCard.changeStartBalanceName = this.$translate.instant('startBalanceC');
                this.$scope.privateCard.changeEndBalanceName = this.$translate.instant('endBalanceC');
                return;
            }
        }
        getTransactiosn = (options) => {
            var acc = this.$scope.privateCard.selected.acc.Acc;
            var dStart = Core.Utils.Helper.toLocalTimeISO(this.$scope.privateCard.dateBetween.fromDate);
            var dEnd = Core.Utils.Helper.toLocalTimeISO(this.$scope.privateCard.dateBetween.toDate);
            this.accountService.getTransactions(acc, dStart, dEnd).then((a: any) => {
                _.each(a.Transactions, (x: any) => {
                    x.correspondentTemplate = x.CorrespondAcc.Acc + ' - ' + x.CorrespondAcc.Name;
                    if (typeof x.DBAmount !== "undefined") {
                        x.dbVg = x.DBAmount.Vg;
                        x.dbVu = x.DBAmount.Vu;
                        x.dbVc = x.DBAmount.Vc;
                        x.crVg = x.crVu = x.crVc = 0;
                    }
                    if (typeof x.CRAmount !== "undefined") {
                        x.crVg = x.CRAmount.Vg;
                        x.crVu = x.CRAmount.Vu;
                        x.crVc = x.CRAmount.Vc;
                        x.dbVg = x.dbVu = x.dbVc = 0;
                    }
                    x.trVg = parseFloat(x.TransactionBalance.Vg);
                    x.trVu = x.TransactionBalance.Vu;
                    x.trVc = x.TransactionBalance.Vc;
                });
                var balance = this.$scope.privateCard.balance;
                balance.transactions.start = a.StartBalance;
                balance.transactions.end = a.EndBalance;

                var selectedBal = '';
                if (this.$scope.components.viewOptions.Vg) {
                    selectedBal = "Vg";
                } else if (this.$scope.components.viewOptions.Vu) {
                    selectedBal = "Vu";
                } else if (this.$scope.components.viewOptions.Vc) {
                    selectedBal = "Vc";
                }

                balance.startSufix = balance.transactions.start[selectedBal] < 0 ? "კრ." : "დბ.";;
                balance.endSufix = balance.transactions.end[selectedBal] < 0 ? "კრ." : "დბ.";
                balance.startAmount = Math.abs(balance.transactions.start[selectedBal]);
                balance.endAmount = Math.abs(balance.transactions.end[selectedBal]);
               

                options.success(a.Transactions);
                return a;
            });
        }

        reconfigColumns = (lastColmn) => {
            var doReconfig = () => {
                var transGrid = this.$scope.components.transactionsGrid.grid;
                if (this.$scope.components.viewOptions.Vg && !this.$scope.components.viewOptions.Vu && !this.$scope.components.viewOptions.Vc) {
                    this.$scope.PrivateCardValue = { val: "Vg" };
                } else if (!this.$scope.components.viewOptions.Vg && this.$scope.components.viewOptions.Vu && !this.$scope.components.viewOptions.Vc) {
                    this.$scope.PrivateCardValue = { val: "Vu" };
                } else if (!this.$scope.components.viewOptions.Vg && !this.$scope.components.viewOptions.Vu && this.$scope.components.viewOptions.Vc) {
                    this.$scope.PrivateCardValue = { val: "Vc" };
                }
                if (!this.$scope.components.viewOptions.Vg && !this.$scope.components.viewOptions.Vu && !this.$scope.components.viewOptions.Vc) {
                    this.$scope.components.viewOptions.Vg = true;
                    this.$scope.PrivateCardValue = { val: "Vg" };
                    transGrid.showColumn("dbVg");
                    transGrid.showColumn("crVg");
                    transGrid.showColumn("trVg");
                }
                switch (lastColmn) {
                    case 'Vg':
                        if (this.$scope.components.viewOptions.Vg) {
                            transGrid.showColumn("dbVg");
                            transGrid.showColumn("crVg");
                            transGrid.showColumn("trVg");
                        }
                        else {
                            transGrid.hideColumn("dbVg");
                            transGrid.hideColumn("crVg");
                            transGrid.hideColumn("trVg");
                        }
                        break;
                    case 'Vu':
                        if (this.$scope.components.viewOptions.Vu) {
                            transGrid.showColumn("dbVu");
                            transGrid.showColumn("crVu");
                            transGrid.showColumn("trVu");
                            if (typeof this.balance !== "undefined") {
                                this.$scope.startBalance = this.balance.startBal.Vu;
                                this.$scope.endBalance = this.balance.endBal.Vu;
                            }
                        }
                        else {
                            transGrid.hideColumn("dbVu");
                            transGrid.hideColumn("crVu");
                            transGrid.hideColumn("trVu");
                        }
                        break;;
                    case 'Vc':
                        if (this.$scope.components.viewOptions.Vc) {
                            transGrid.showColumn("dbVc");
                            transGrid.showColumn("crVc");
                            transGrid.showColumn("trVc");
                            if (typeof this.balance !== "undefined") {
                                this.$scope.startBalance = this.balance.startBal.Vc;
                                this.$scope.endBalance = this.balance.endBal.Vc;
                            }
                        }
                        else {
                            transGrid.hideColumn("dbVc");
                            transGrid.hideColumn("crVc");
                            transGrid.hideColumn("trVc");
                        }
                        break;
                }

                this.$scope.startBalanceIdentity = this.$scope.startBalanceIdentity < 0 ? "კრ" : "დბ";
                this.$scope.endBalanceIdentity = this.$scope.endBalance < 0 ? "კრ" : "დბ";

                this.$scope.startBalance = Math.abs(this.$scope.startBalance);
                this.$scope.endBalance = Math.abs(this.$scope.endBalance);

            }
            this.$scope.components.transactionsGrid.gridView.showLoading();
            doReconfig();
            this.$timeout(() => {
                this.$scope.components.transactionsGrid.gridView.hideLoading();
            });
        }

        templates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'Blocking', template: '<div class="text-center" ng-if="dataItem.Blocking"><i class="fa fa-lock" style="font-size: 14px;"></i></div>' },
            { fieldName: 'RecStatus', template: '<div class="text-center" ng-if="dataItem.RecStatus"><img src="./img/fx.png" width="16"></div>' },
        ];

        private transactionsColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'NID', title: '№', width: 30, filterable: false },
            {
                field: 'Blocking', width: 40,
                filterable: 'custom',
                titleTranslated: 'Blocking',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: " " },
                            { value: true, text: " + " },
                            { value: false, text: " - " }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"

                    })
                },
            },
            {
                field: 'RecStatus', width: 40,
                filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: " " },
                            { value: 1, text: " + " },
                            { value: 0, text: " - " }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"
                    })
                },
                titleTranslated: 'FX'
            },
            { field: 'DDate', titleTranslated: 'DATE', width: 80, format: Core.Kendo.Format.Date },
            { field: 'OpDetId', titleTranslated: 'OpDetId', width: 50 },
            { field: 'correspondentTemplate', width: 200, titleTranslated: 'Correspond' },
            {
                field: 'dbVg', titleTranslated: 'DBL', width: 100, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.dbVg ? data.dbVg.sum.toFixed(2) : 0) #'
            },
            {
                field: 'crVg', titleTranslated: 'CRL', width: 100, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.crVg ? data.crVg.sum.toFixed(2) : 0) #'
            },
            {
                field: 'trVg', titleTranslated: 'TransactionBalanceL', width: 100, format: Core.Kendo.Format.Price2
            },
            {
                field: 'dbVu', titleTranslated: 'DBU', width: 100, hidden: true, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.dbVu ? data.dbVu.sum.toFixed(2) : 0) #'
            },
            {
                field: 'crVu', titleTranslated: 'CRU', width: 100, hidden: true, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.crVu ? data.crVu.sum.toFixed(2) : 0) #'
            },
            {
                field: 'trVu', titleTranslated: 'TransactionBalanceU', width: 100, hidden: true, format: Core.Kendo.Format.Price2
            },
            {
                field: 'dbVc', titleTranslated: 'DBC', width: 100, hidden: true, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.dbVc ? data.dbVc.sum.toFixed(2) : 0) #'
            },
            {
                field: 'crVc', titleTranslated: 'CRC', width: 100, hidden: true, format: Core.Kendo.Format.Price2,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.crVc ? data.crVc.sum.toFixed(2) : 0) #'
            },
            {
                field: 'trVc', titleTranslated: 'TransactionBalanceC', width: 100, hidden: true, format: Core.Kendo.Format.Price2
            },
            { field: 'Contents', titleTranslated: 'Contents', width: 200 },
            { field: 'NumberIn', titleTranslated: 'numberin', width: 80 },
            { field: 'NumberOut', titleTranslated: 'numberout', width: 80 },
            { field: 'CUser', titleTranslated: 'user', width: 70 },
            { field: 'CRTime', titleTranslated: 'crtime', width: 140, format: Core.Kendo.Format.DateTime_S }
        ];

        private accountsColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "AccFullName", titleTranslated: "acc" },
            { field: 'Card', title: 'საბალანსო ანგარიში', hidden: true }
        ];

    }
}
