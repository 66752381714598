/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IAccountInfo {
        Acc?: string;
        Name?: string;
        SN?: string;
        WarehouseAddress?: string;
        Address?: string;
        Phone?: string;
        AccWithName?: string;
    }
} 