/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface IMemorialScope extends IBaseBookScope {
        operInfo: any;
        memorialModel: IMemorialModel;
        recalc: Function;
        currencies: any;
        components: any;
    }
    export interface IMemorialModel {
        bookID?: any;
        rate?: any;
        rateU?: any;
        rateC?: any;
        bookNote?: any;
        book?: {
            vg?: any;
            vu?: any;
            vc?: any;
            opDetID?: any;
            bookId?: any;
            content?: any;
        };
        calcRate?: boolean;
        autoClose?: boolean;
        blocking?: boolean;
    }

    export class MemorialOrder extends BaseBook {
        data: any;
        constructor($scope: IMemorialScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            var scope = this.$scope;
            this.fillMemorial(scope.docInfo, data.Operation);
            this.data = data;
            this.$scope.memorialModel.autoClose = true;

            this.$scope.currencies = data.Currency;


            this.$scope.$watch('[baseModel.crDate, baseModel.currencyID]', () => {
                var dt = scope.baseModel.crDate;

                this.getRates(dt, scope.baseModel.currencyID).then(a => {
                    $scope.memorialModel.rate = a.rate;
                    $scope.memorialModel.rateC = a.rateC;
                    if ($scope.memorialModel.bookID !== '')
                        scope.recalc('lari');
                }
                );
            }, false);


            scope.recalc = this.recalc;
            scope.saveDoc = this.saveDoc;

        }

        $scope: IMemorialScope;

        getPartial(): string {
            return 'modalsmemorial.order.html';
        }

        fillMemorial = (docInfo: Interfaces.IDoc, operations) => {
            if (typeof docInfo.Books !== "undefined") {
                if (this.$scope.isCopied) {
                    this.$scope.memorialModel = {                        
                        rate: docInfo.RateU,
                        rateC: docInfo.RateC,
                        bookNote: docInfo.DocNote,
                        calcRate: false,
                        autoClose: true,
                        book: {
                            bookId: '',
                            content: docInfo.Books[0].Contents,
                        }
                    }

                } else {
                    this.$scope.memorialModel = {
                        bookID: docInfo.Books[0].BookID,
                        rate: docInfo.RateU,
                        rateC: docInfo.RateC,
                        bookNote: docInfo.DocNote,
                        calcRate: false,
                        autoClose: true,
                        blocking: docInfo.Books[0].Blocking,
                        book: {
                            vg: docInfo.Books[0].VG,
                            vc: docInfo.Books[0].VC,
                            vu: docInfo.Books[0].VU,
                            bookId: docInfo.Books[0].BookID,
                            content: docInfo.Books[0].Contents,
                        }
                    }
                }
            } else {
                this.$scope.memorialModel = {
                    book: {
                        bookId: '',
                        content: operations.Name
                    }
                }
            }


        }

        recalc = (param: any) => {


            var oper = this.$scope.memorialModel;

            var rate = parseFloat(oper.rate);
            var rateC = parseFloat(oper.rateC);
            var lari;
            var curr;
            var eq;

            if (typeof this.$scope.formScope === "undefined")
                return;
            var memOrder = this.$scope.formScope.modalBook;

            switch (param) {
                case 'lari':

                    lari = parseFloat(oper.book.vg);

                    if (oper.calcRate) {
                        curr = parseFloat(oper.book.vc);
                        oper.rateC = !isNaN(curr) ? (lari / curr).toFixed(2) : "";
                    } else {
                        oper.book.vu = !isNaN(rate) ? (lari / rate).toFixed(4) : "";
                        oper.book.vc = !isNaN(rateC) ? (lari / rateC).toFixed(2) : "";
                    }
                    break;
                case 'cur':

                    if (memOrder.VCInput.$invalid || memOrder.reteCInput.$invalid)
                        return;

                    curr = parseFloat(oper.book.vc);
                    lari = curr * rateC;
                    if (oper.calcRate) {
                        oper.rateC = !isNaN(curr) ? (lari / curr).toFixed(2) : "";
                    } else {
                        eq = !isNaN(rateC) ? (lari / rate) : 0;
                        oper.book.vg = lari.toFixed(2);
                        oper.book.vu = eq.toFixed(4);
                    }
                    break;
                case 'eq':

                    if (memOrder.VUInput.$invalid)
                        return;
                    eq = parseFloat(oper.book.vu);
                    lari = eq * rate;

                    var cur = !isNaN(rateC) ? (lari / rateC) : 0;

                    if (oper.calcRate) {
                        curr = parseFloat(oper.book.vc);
                        oper.rateC = !isNaN(curr) ? (lari / curr).toFixed(2) : "";
                        oper.book.vg = eq * oper.rate;
                    } else {
                        oper.book.vg = lari.toFixed(2);
                        oper.book.vc = cur.toFixed(2);
                    }
                    break;
            }

        }

        saveDoc = () => {
            var memOrder = this.$scope.formScope.modalBook;
            if (memOrder.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი!');
                return;
            } else if (this.$scope.baseModel.debet.Acc === this.$scope.baseModel.credit.Acc) {
                this.toaster.pop('error', 'შეცდომა!', 'კრედიტი არ უნდა უდრიდეს დებეტს!');
                return;
            } else if (!(this.$scope.memorialModel.book.vg > 0)) {
                this.toaster.pop('error', 'შეცდომა!', 'თანხა უნდა იყოს 0.00-ზე მეტი');
                return;
            }

            var mOrder = this.$scope.memorialModel;

            var baseModel = this.$scope.baseModel;

            var doc: any = {
                DDate: baseModel.crDate,
                DB: baseModel.debet.Acc,
                CR: baseModel.credit.Acc,
                Operid: baseModel.operId,
                Currency_id: baseModel.currencyID,
                NumberIn: baseModel.numberIn,
                NumberOut: baseModel.numberOut,
                RateU: mOrder.rate,
                RateC: mOrder.rateC,
                IsProject: baseModel.isProject,
                DocNote: mOrder.bookNote,
                DocID: baseModel.docID,
                BookType: 0,
                Contents: mOrder.book.content
            }

            var book = {
                VG: mOrder.book.vg,
                VU: mOrder.book.vu,
                VC: mOrder.book.vc,
                OpDetID: baseModel.operId === '000' ? baseModel.operId + '00' : baseModel.operId + '01',
                BookID: mOrder.book.bookId,
                Contents: mOrder.book.content
            }

            doc.Books = [book];
            if (book.VG > 0) {
                this.saveDocument(doc, mOrder.autoClose);

                if (!mOrder.autoClose) {
                    var baseOperID = baseModel.operId;
                    var currency = baseModel.currencyID;

                    this.$scope.baseModel = {
                        crDate: new Date(),
                        operId: baseOperID,
                        currencyID: currency
                    }
                    this.$scope.memorialModel = {
                        book: {
                            bookId: '',
                            content: this.data.Operation.Name
                        },
                        autoClose: false
                    }
                }

            } else {
                this.toaster.pop('error', this.translate.instant('Info'), this.translate.instant('vgGreatThen0)'));
            }

        }
    }

}