/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {

    export class Balance extends Core.Controller {

        public static $inject = [
            Globals.scope, 'accountService', "$timeout"
        ];

        constructor(private $scope: any, private accountService: Services.Account, private $timeout) {
            super(arguments, Balance.$inject);
            $scope.showRefreshInfo = true;
            $scope.Visible = {
                VisibleDetails: true
            };
            $scope.loadingVisibled = false;
            $scope.components = {
                events: {
                    refresh: this.refresh,
                    changeBalanceView: this.changeBalanceView,
                    openDebetModal: (data) => {
                        this.showModalForm(data, "DB");
                    },
                    openCreditModal: (data) => {
                        this.showModalForm(data, "CR");
                    },
                    showNullableAcc: () => {
                        $scope.showRefreshInfo = true;
                    }
                },
                balanceGrid: this.initBalanceGrid(),
                balanceDetailGrid: this.initBalanceDetailGrid(),
                filters: {
                    dateBetween: {}
                },
                balGridOptions: [
                    { val: 1, title: 1 }, { val: 2, title: 2 }, { val: 3, title: 3 }, { val: 4, title: 4 }, { val: 10, title: 'ყველა' }
                ]
            }

            $scope.model = {
                balanceOptions: { Vg: true, Vu: false, Vc: false },
                balanceValue: { val: 4 },
                nullableAcc: false

            }

            $scope.$watch('components.filters.dateBetween', (val) => {
                if (typeof val.fromDate !== "undefined") {
                    $scope.showRefreshInfo = true;
                }
            }, true);

            $scope.oldBalanceValue = {
                val: {}
            };

            $scope.$watch('model.balanceValue', (value, oldValue) => {
                if (typeof $scope.model.balanceValue === "undefined" || $scope.showRefreshInfo === true)
                    return;

                $scope.loadingVisibled = true;

                $scope.oldBalanceValue = oldValue;
                this.changeBalanceDataSource();
            }, true);

            $scope.openDB = (data) => {
                this.showModalForm(data, "DB");
            }
            $scope.openCR = (data) => {
                this.showModalForm(data, "CR");
            }

            this.onCompanyChange = (newCompany, oldCompany) => {
                this.refresh();
            }
        }

        changeBalanceDataSource = () => {

            var balanceGrid = this.$scope.components.balanceGrid;
            var data = _.filter(this.balanceData, (x: any) => { return x.Level === this.$scope.model.balanceValue.val });
            var dataSource = new kendo.data.DataSource({
                data: data,
                pageSize: 35,
                schema: {
                    model: {
                        fields: {
                            "StartBalance.Vg": { type: "number" },
                            // "StartBalance.Vu": { type: "number" },
                            // "StartBalance.VC": { type: "number" },
                            "DB.Vg": { type: "number" },
                            // "DB.Vu": { type: "number" },
                            // "DB.Vc": { type: "number" },
                            "CR.Vg": { type: "number" },
                            // "CR.Vu": { type: "number" },
                            // "CR.Vc": { type: "number" },
                            "EndBalance.Vg": { type: "number" },
                            // "EndBalance.Vu": { type: "number" },
                            // "EndBalance.Vc": { type: "number" }

                        }
                    }
                }
            });
            balanceGrid.grid.setDataSource(dataSource);


            // if (this.$scope.model.balanceValue.val === 10) {
                //     var forHide: JQuery = $(".k-icon");;
                //     forHide.each((key, elem) => {
                //         $(elem).css('visibility', 'hidden');
                //     });
                // } else if (this.$scope.oldBalanceValue.val === 10) {
                //     var forShow: JQuery = $(".k-icon");;
                //     forShow.each((key, elem) => {
                //         $(elem).css('visibility', 'visible');
                //     });;
            // }

            this.$timeout(() => {
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.loadingVisibled = false;
                });
            });
        }

        refresh = () => {
            this.$scope.loadingVisibled = true;
            this.getBalanceTurnover().then(() => {
                this.$scope.balSumRow = _.filter(this.balanceData, (x: any) => { return x.Level === 0 })[0];
                this.changeBalanceDataSource();
            });

            this.$scope.showRefreshInfo = false;
        }

        private lastColumn = 'Vg';

        reconfigColumns = (lastColmn) => {

            var balanceTreeList = this.$scope.components.balanceGrid.grid;
            var balanceTreeList1 = this.$scope.components.balanceDetailGrid.grid;


            if (lastColmn !== this.lastColumn) {
                this.lastColumn = lastColmn;
                var reconfColm = () => {
                    balanceTreeList.hideColumn("StartBalance.Vg");
                    // balanceTreeList.hideColumn("StartBalance.Vu");
                    // balanceTreeList.hideColumn("StartBalance.Vc");

                    balanceTreeList1.hideColumn("StartBalance.Vg");
                    // balanceTreeList1.hideColumn("StartBalance.Vu");
                    // balanceTreeList1.hideColumn("StartBalance.Vc");


                    balanceTreeList.hideColumn("DB.Vg");
                    // balanceTreeList.hideColumn("DB.Vu");
                    // balanceTreeList.hideColumn("DB.Vc");


                    balanceTreeList1.hideColumn("DB.Vg");
                    // balanceTreeList1.hideColumn("DB.Vu");
                    // balanceTreeList1.hideColumn("DB.Vc");


                    balanceTreeList.hideColumn("CR.Vg");
                    // balanceTreeList.hideColumn("CR.Vu");
                    // balanceTreeList.hideColumn("CR.Vc");

                    balanceTreeList1.hideColumn("CR.Vg");
                    // balanceTreeList1.hideColumn("CR.Vu");
                    // balanceTreeList1.hideColumn("CR.Vc");


                    balanceTreeList.hideColumn("EndBalance.Vg");
                    // balanceTreeList.hideColumn("EndBalance.Vu");
                    // balanceTreeList.hideColumn("EndBalance.Vc");

                    balanceTreeList1.hideColumn("EndBalance.Vg");
                    // balanceTreeList1.hideColumn("EndBalance.Vu");
                    // balanceTreeList1.hideColumn("EndBalance.Vc");

                    switch (lastColmn) {
                        case 'Vg':
                            balanceTreeList.showColumn("StartBalance.Vg");
                            balanceTreeList1.showColumn("StartBalance.Vg");

                            balanceTreeList.showColumn("DB.Vg");
                            balanceTreeList1.showColumn("DB.Vg");

                            balanceTreeList.showColumn("CR.Vg");
                            balanceTreeList1.showColumn("CR.Vg");

                            balanceTreeList.showColumn("EndBalance.Vg");
                            balanceTreeList1.showColumn("EndBalance.Vg");

                            //
                            break;
                        //     case 'Vu':
                        //         balanceTreeList.showColumn("StartBalance.Vu");
                        //         balanceTreeList1.showColumn("StartBalance.Vu");

                        //         balanceTreeList.showColumn("DB.Vu");
                        //         balanceTreeList1.showColumn("DB.Vu");

                        //         balanceTreeList.showColumn("CR.Vu");
                        //         balanceTreeList1.showColumn("CR.Vu");

                        //         balanceTreeList.showColumn("EndBalance.Vu");
                        //         balanceTreeList1.showColumn("EndBalance.Vu");

                        //         break;
                        //     case 'Vc':
                        //         balanceTreeList.showColumn("StartBalance.Vc");
                        //         balanceTreeList1.showColumn("StartBalance.Vc");

                        //         balanceTreeList.showColumn("DB.Vc");
                        //         balanceTreeList1.showColumn("DB.Vc");


                        //         balanceTreeList.showColumn("CR.Vc");
                        //         balanceTreeList1.showColumn("CR.Vc");

                        //         balanceTreeList.showColumn("EndBalance.Vc");
                        //         balanceTreeList1.showColumn("EndBalance.Vc");

                        //         break;
                    }
                }
                Core.Utils.Spinner.animateWithTimer(reconfColm);
            }
        }

        changeBalanceView = (toView: string) => {
            var pr = this.$scope.model.balanceOptions;
            pr.Vg = pr.Vu = pr.Vc = false;
            pr[toView] = true;

            this.reconfigColumns(toView);
        }

        private showModalForm = (data, filter) => {
            var formData = {
                fromDate: Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.components.filters.dateBetween.fromDate),
                toDate: Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.components.filters.dateBetween.toDate),
                filter: filter,
                dataRow: data
            }
            

            var modalForm = Core.Utils.ModalForm.openModalForm("books.html", Controllers.BalanceBooksModal.getName(), formData, Core.Utils.ModalSize.Large);
            modalForm.closed.then(() => {
                $(window).trigger('resize');
            });

        }

        private balanceData: any;
        private getBalanceTurnover(): angular.IPromise<any> {
            var that = this;
            var dateBetween = {
                fromDate: Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.components.filters.dateBetween.fromDate),
                toDate: Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.components.filters.dateBetween.toDate)
            }
            

            return this.accountService.getBalanceTurnover(dateBetween.fromDate, dateBetween.toDate, that.$scope.model.nullableAcc).then(result => {
                that.balanceData = result.Result;
                return 1;
            });
        }

        private balanceColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "Acc", titleTranslated: "acc", width: 100 },
            { field: "Name", titleTranslated: "name" },
            { field: "StartBalance.Vg", titleTranslated: "startBalance", width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "StartBalance.Vu", titleTranslated: "startBalance", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "StartBalance.Vc", titleTranslated: "startBalance", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            { field: "DB.Vg", titleTranslated: "DB", width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "DB.Vu", titleTranslated: "DB", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "DB.Vc", titleTranslated: "DB", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            { field: "CR.Vg", titleTranslated: "CR", width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "CR.Vu", titleTranslated: "CR", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "CR.Vc", titleTranslated: "CR", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            { field: "EndBalance.Vg", titleTranslated: "endBalance", width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "EndBalance.Vu", titleTranslated: "endBalance", hidden: true, width: 200, format: Core.Kendo.Format.Price2 },
            // { field: "EndBalance.Vc", titleTranslated: "endBalance", hidden: true, width: 200, format: Core.Kendo.Format.Price2 }
        ];

        private balanceTemplates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            //    { fieldName: 'StartBalance.Vg', template: '<div ng-hide="(dataItem.StartBalance.Vg ==0)" class="apx-grid-cell-number">{{::dataItem.StartBalance.Vg | number:2}}</div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.StartBalance.Vg | number:2}}</div>' },
            // { fieldName: 'StartBalance.Vu', template: '<div ng-hide="(dataItem.StartBalance.Vu ==0)" class="apx-grid-cell-number">{{::dataItem.StartBalance.Vu | number:2}}</div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.StartBalance.Vu | number:2}}</div>' },
            // { fieldName: 'StartBalance.Vc', template: '<div ng-hide="(dataItem.StartBalance.Vc ==0)" class="apx-grid-cell-number">{{::dataItem.StartBalance.Vc | number:2}}</div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.StartBalance.Vc | number:2}}</div>' },
            //    { fieldName: 'DB.Vg', template: '<div ng-dblclick="components.events.openDebetModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.DB.Vg ==0)">{{::dataItem.DB.Vg | number:2}}</span></div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.DB.Vg | number:2}}</div>' },
            // { fieldName: 'DB.Vu', template: '<div ng-dblclick="components.events.openDebetModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.DB.Vu ==0)">{{::dataItem.DB.Vu | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.DB.Vu | number:2}}</div>' },
            // { fieldName: 'DB.Vc', template: '<div ng-dblclick="components.events.openDebetModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.DB.Vc ==0)">{{::dataItem.DB.Vc | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.DB.Vc | number:2}}</div>' },
            //    { fieldName: 'CR.Vg', template: '<div ng-dblclick="components.events.openCreditModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.CR.Vg ==0)">{{::dataItem.CR.Vg | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.CR.Vg | number:2}}</div>' },
            // { fieldName: 'CR.Vu', template: '<div ng-dblclick="components.events.openCreditModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.CR.Vu ==0)">{{::dataItem.CR.Vu | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.CR.Vu | number:2}}</div>' },
            // { fieldName: 'CR.Vc', template: '<div ng-dblclick="components.events.openCreditModal(dataItem)"  class="apx-grid-cell-number apx-selected"> <span ng-hide="(dataItem.CR.Vc ==0)">{{::dataItem.CR.Vc | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.CR.Vc | number:2}}</div>' },
            //    { fieldName: 'EndBalance.Vg', template: '<div  class="apx-grid-cell-number"> <span ng-hide="(dataItem.EndBalance.Vg ==0)">{{::dataItem.EndBalance.Vg | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.EndBalance.Vg | number:2}}</div>' },
            // { fieldName: 'EndBalance.Vu', template: '<div  class="apx-grid-cell-number"> <span ng-hide="(dataItem.EndBalance.Vu ==0)">{{::dataItem.EndBalance.Vu | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.EndBalance.Vu | number:2}}</div>' },
            // { fieldName: 'EndBalance.Vc', template: '<div  class="apx-grid-cell-number"> <span ng-hide="(dataItem.EndBalance.Vc ==0)">{{::dataItem.EndBalance.Vc | number:2}}</span> </div>', footerTemplate: '<div class="apx-grid-cell-number">{{balSumRow.EndBalance.Vc | number:2}}</div>' }
        ];

        private initBalanceDetailGrid = () => {
            var detailTempaltes = this.balanceTemplates;
            _.each(detailTempaltes, (x: any) => {
                delete x.footerTemplate;
            });
            var gridOptions = Core.Kendo.GridView.initGridOptions(this.balanceColumns, this.balanceTemplates, { virtualScroll: true });

            return gridOptions;
        }

        private initBalanceGrid = () => {
            var gridOptions = Core.Kendo.GridView.initGridOptions(this.balanceColumns, /*this.balanceTemplates,*/null, { virtualScroll: true });
            gridOptions.dataBound = (e) => {
                
                if (this.$scope.model.balanceValue.val === 10) {
                    var forHide: JQuery = $(e.sender.element).find(".k-i-expand");
                    forHide.each((key, elem) => {
                        $(elem).css('visibility', 'hidden');
                    });
                } else if (this.$scope.oldBalanceValue.val === 10) {
                    var forShow: JQuery = $(e.sender.element).find(".k-i-expand");;
                    forShow.each((key, elem) => {
                        $(elem).css('visibility', 'visible');
                    });;
                }
            }
            gridOptions.dataSource = {
                data: [],
                pageSize: 35
            };

            gridOptions.detailExpand = (e) => {
                var expendDetails = () => {
                    var gr: any = gridOptions.grid;
                    var data: any = gridOptions.grid.dataItem(e.masterRow);
                    var filtered = _.filter(this.balanceData, (x: any) => {
                        var acc = data.Acc.slice(0, data.Acc - 4);

                        return x.Acc.startsWith(acc) && x.Level === 10;
                    });
                    this.$scope.components.balanceDetailGrid.grid.setDataSource(new kendo.data.DataSource({
                        data: filtered,
                        pageSize: 35,
                        schema: {
                            model: {
                                fields: {
                                    "StartBalance.Vg": { type: "number" },
                                    // "StartBalance.Vu": { type: "number" },
                                    // "StartBalance.VC": { type: "number" },
                                    "DB.Vg": { type: "number" },
                                    // "DB.Vu": { type: "number" },
                                    // "DB.Vc": { type: "number" },
                                    "CR.Vg": { type: "number" },
                                    // "CR.Vu": { type: "number" },
                                    // "CR.Vc": { type: "number" },
                                    "EndBalance.Vg": { type: "number" },
                                    // "EndBalance.Vu": { type: "number" },
                                    // "EndBalance.Vc": { type: "number" }

                                }
                            }
                        }
                    }));

                    gr._rowHeight = undefined;
                    gr.virtualScrollable.refresh();
                }
                Core.Utils.Spinner.animateWithTimer(expendDetails);
            }
            gridOptions.detailCollapse = () => {
                var gr: any = gridOptions;
                gr.grid._rowHeight = undefined;
                gr.grid.virtualScrollable.refresh();
            }

            return gridOptions;
        }
    }
}
