/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {
    export interface AccountsScope extends angular.IScope {
        model: {
            selectedAccount: any,
            selectedAcc: any,
            deleteMode: boolean,
            treeDataSource?: any

        };
        components: {
            accountsTree: Apex.Components.IApxTreeList,
            detalAccounts: Apex.Components.IApxGrid
        };
        events: {
            refresh: Function,
            add: Function,
            addBal: Function,
            edit: Function,
            editBal: Function,
            delete: Function,
            deleteBal: Function,
            expand: Function,
            collapse: Function,
            refreshBalance: Function
        }
        treeListState: any;
        isCollapsed: boolean;
        Deptreelist: any;
        isDefault: boolean;
        carsDataSource: any;
    }
    export class Accounts extends Core.Controller {
        public static $inject = [
            Globals.scope, 'accountService', Globals.translate, '$q', '$timeout'
        ];
        constructor(private $scope: AccountsScope, private accountService: Services.Account, private $translate, private $q: angular.IQService, private $timeout) {
            super(arguments, Accounts.$inject);
            $scope.treeListState = {};
            $scope.model = {
                selectedAccount: {},
                selectedAcc: {},
                deleteMode: false
            }

            $scope.components = {
                accountsTree: this.initTestTree(),
                detalAccounts: this.detailAccounts(),
            };
            $scope.events = {
                expand: this.expandAll,
                collapse: this.collapseAll,
                add: () => {
                    var model: any = {
                        Date_Start: new Date()
                    }

                    if ($scope.model.selectedAcc.Acc !== undefined) {
                        model.Card = $scope.model.selectedAcc.Acc;
                    }

                    this.openAccountModal(model);
                },
                addBal: () => {
                    this.openAccountCardModal({}, false);
                },
                edit: () => { this.openAccountModal($scope.model.selectedAccount) },
                editBal: () => {
                    this.openAccountCardModal($scope.model.selectedAcc, true);
                },
                delete: this.deleteAcc,
                deleteBal: this.deleteAccCard,
                refresh: () => {
                    this.$scope.model.selectedAccount = {};
                    if (!!this.$scope.model.selectedAcc && !!this.$scope.model.selectedAcc.Acc) {
                        this.$scope.components.detalAccounts.gridView.showLoading();
                        this.$scope.components.detalAccounts.gridView.setFilter(this.getAccCardFilters());
                        this.$scope.components.detalAccounts.gridView.refresh();
                    }
                },
                refreshBalance: () => {
                    this.$scope.model.selectedAccount = {};
                    this.$scope.components.accountsTree.showLoading();
                    var expandedItemsIds = {};
                    var treeview = this.$scope.components.accountsTree.treeList;
                    // treeview.element.find("tr:visible").each(function () {
                    //     var item = treeview.dataItem(this);
                    //     if (item) {
                    //         expandedItemsIds[item.id] = true;
                    //         console.log(item);
                    //     }
                    // });

                    this.$scope.treeListState = expandedItemsIds;
                    this.$scope.components.accountsTree.treeList.dataSource.read();

                    this.accountService.getAccountsTree().then((a: any) => {
                        if (a) {
                            _.forEach(a.Result, (item: any) => {
                                // item["expanded"] = this.$scope.treeListState[item.Acc];
                                item["NameEx"] = item.Acc + " - " + item.Name; 
                                if(!item.ParentAcc){
                                    item.ParentAcc=null; 
                                }  
                            });
                            this.$scope.carsDataSource = a.Result;
                            this.$scope.model.treeDataSource = a.Result; 

                            this.$scope.components.accountsTree.treeList.setDataSource(this.convertToTreeListDataSource(this.$scope.model.treeDataSource));
                            this.$scope.components.accountsTree.hideLoading();
                        }
                    });
                }
            }
            $timeout(() => {
                this.$scope.events.refresh();
                this.$scope.events.refreshBalance();
            })

            // this.$scope.components.detalAccounts.grid.dataSource.data([]);
        }


        onCompanyChange = (newCompany, oldCompany) => {
            this.$scope.events.refreshBalance();
            this.$scope.events.refresh();
        }

        collapseAll = () => {
            this.$scope.components.accountsTree.showLoading();
            this.$timeout(() => {
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.components.accountsTree.hideLoading();
                    this.$scope.components.accountsTree.treeList.setDataSource(this.convertToTreeListDataSource(this.$scope.model.treeDataSource, false));
                    this.$scope.isCollapsed = false;
                });
            });

        }
        expandAll = () => {
            this.$scope.components.accountsTree.showLoading();
            this.$timeout(() => {
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.components.accountsTree.hideLoading();
                    this.$scope.components.accountsTree.treeList.setDataSource(this.convertToTreeListDataSource(this.$scope.model.treeDataSource, true));
                    this.$scope.isCollapsed = true;
                });
            });
        }
        deleteAcc = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.accountService.deleteAccount(this.$scope.model.selectedAccount.Acc).then((result: any) => {
                    if (result === undefined) {
                        toaster.pop('error', "შეცდომა!", translate.instant('dontDelete'));
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.events.refresh();
                    }
                });
            });
        }

        expandItems = (items, timeout: number = 0): angular.IPromise<any> => {
            var defer = this.$q.defer();
            setTimeout(() => {
                for (var index = 0; index < items.length; index++) {
                    this.$scope.components.accountsTree.treeList.expand(items[index]);
                }
                defer.resolve("data");
            }, timeout);
            return defer.promise;
        }

        deleteAccCard = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.accountService.deleteAccCard(this.$scope.model.selectedAcc.Acc).then((result: any) => {
                    if (result.success === false) {
                        return;
                    }

                    if (result === undefined) {
                        toaster.pop('error', "შეცდომა!", translate.instant('dontDelete'));
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.events.refreshBalance();
                    }
                });
            });
        }

        getAccCardFilters = () => {
            var acc = this.$scope.model.selectedAcc.Acc;
            if (this.$scope.model.selectedAcc.hasChildren) {
                acc = acc.split("0").join("");
            }
            var filters: Array<Core.Interfaces.IGridFilter> = [
                { filterName: 'Card', filterOperator: 'StartsWith', filterValue: acc }
            ];
            return filters;
        }
        detailAccounts = () => {

            var datasourceModel = {
                fields: {
                    Acc: { type: "string" },
                    Acc_nu: { type: "string" },
                    CurrencyCurrency_Code: { type: "string" },
                    Date_Start: { type: "date" },
                    AccountDetailssn: { type: "string" },
                    Spend: { type: "boolean" }
                }
            };

            var grid: Components.IApxGrid = {
                columns: [
                    {  field: "Acc", titleTranslated: "acc", width: 90},
                    {  field: "Acc_nu", titleTranslated: "nameCaption"},
                    { field: "CurrencyCurrency_Code", titleTranslated: "Currency", width: 70 },
                    { field: "AccountDetailssn", titleTranslated: "SN", width: 90 },
                    {  field: "Date_Start", titleTranslated: "openDate", format: Core.Kendo.Format.Date, width: 75},
                    {
                        field: "Spend", titleTranslated: "Spend", width: 90, filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('isSelected') },
                                    { value: 0, text: this.$translate.instant('isNotSelected') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.Spend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Spend'></span>"
                    }
                ],
                settings: {
                    autoBind: false,
                    pageable: true
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.model.selectedAccount = grid.gridView.selectedItem();
                            });
                        }
                    }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.cardAccountsQueryUrl, datasourceModel)
            };
            return grid;
        }

        // test container 
        convertToTreeListDataSource(data: any, isExpand = false): kendo.data.TreeListDataSource {
            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded: isExpand,
                        id: "Acc",
                        fields: {
                            parentId: { field: "ParentAcc", nullable: true },
                            Name: { nullable: true, type: "string" },
                            Spend: { nullable: true, type: "boolean" }
                        },
                    }
                }
            });
        }


        initTestTree = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    columns: this.TestTreeListColumns,
                    dataSource: this.convertToTreeListDataSource([]),
                    change: this.TestTreeListSelectedChange,
                    editable: { mode: "inline" },
                    filterable: true,
                    resizable: true,
                    sortable: { mode: "single" },
                    selectable: true
                },
            }
            return _treeList;
        }



        TestTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Acc", width: 100, titleTranslated: "acc" },
            { field: "Name", titleTranslated: "caption" },
            {
                field: "Spend", titleTranslated: "Spend", width: 70,
                template: "<div ng-if='dataItem.Spend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Spend'></span>"
            }
        ];



        TestTreeListSelectedChange = (args: any) => {
            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                this.$scope.model.selectedAcc = args != null ? args.sender.dataItem(args.sender.select()) : null;
                if (this.$scope.model.selectedAcc === undefined || this.$scope.model.selectedAcc == null) {
                    this.$scope.components.detalAccounts.grid.dataSource.data([])
                }
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    if (this.$scope.model.selectedAcc) {
                        if (this.$scope.model.selectedAcc.hasChildren == true || this.$scope.model.selectedAcc.can_delete == false) {
                            this.$scope.model.deleteMode = false;
                        }
                        else {
                            this.$scope.model.deleteMode = true;
                        }
                    }
                    this.$scope.events.refresh();
                });
            });
        }

        openAccountModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Medium;
            Apex.Account.Utils.Documents.getCurrencies().then(a => {
                var formData = {
                    model: data,
                    currencies: a,
                    cards: this.$scope.carsDataSource
                }
                var dlg = Core.Utils.ModalForm.openModalForm("account.modal.html", "accountModal" + 'Controller', formData, modalSize);
                dlg.result.then(result => {
                    if (result === true) {
                        this.$scope.events.refresh();
                    }
                });
            });
        }
        openAccountCardModal = (data, editeMode) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var formData = {
                model: data,
                formMode: editeMode
            }
            var dlg = Core.Utils.ModalForm.openModalForm("account.card.modal.html", "accountCardModal" + 'Controller', formData, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined" && result === true) {
                    this.$scope.events.refreshBalance();
                    // this.$scope.components.accountsTree.treeList.dataSource.sync(); 
                }
            });

        }
    }
    export interface AccountModalScope extends angular.IScope {
        model: {
            Data: any
        };
        components: {
            cardLookup: Components.IApxLookup
        };
        events: {
            save: Function,
            close: Function
        }
        formName: string;
        formScope: any;
        showMultiSelect: boolean;
        getFreeAccount: any;

        numMask: any;
        currencies: any;
        accCards: any;
        loadingVisibled: boolean;
        setForm: Function
    }
    export class AccountModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'accountService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: AccountModalScope, data: any, private accountService: Services.Account,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, AccountModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.components = {
                cardLookup: this.cardLookupInit()
            };
            $scope.numMask = this.numMask;
            $scope.model = {
                Data: data.model
            };
            $scope.currencies = data.currencies;
            $scope.accCards = data.cards;
            if ($scope.model.Data.Card !== undefined) {
                var getObject = _.findWhere($scope.accCards, { Acc: $scope.model.Data.Card });
                $scope.model.Data.cardModel = getObject;
            }
            this.getFreeAccount();

            $scope.showMultiSelect = false;
            $timeout(() => {
                if ($scope.model.Data.Acc !== undefined) {
                    $scope.formName = "ანგარიშის რედაქტირება - " + $scope.model.Data.Acc;
                } else {
                    $scope.formName = "ანგარიშის დამატება";
                    $scope.showMultiSelect = true;
                    $scope.model.Data.currencyIds = ["00"];
                    $scope.model.Data.Spend = false;
                    $scope.model.Data.Lap = false;
                    $scope.model.Data.AccAlt = "";
                    $scope.model.Data.LOff = false;
                    //bogdan
                    $scope.$watch("model.Data.cardModel", (item) => {
                        this.getFreeAccount();
                    }, true);

                }
            });
            $scope.events = {
                save: this.save,
                close: this.closeForm
            }
        }
        numMask = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;
            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys //-=chubro=-//
                return;
            }
            if (!/^[A-Za-z_0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        getFreeAccount = () => {
            if (this.$scope.model.Data.cardModel !== undefined && this.$scope.model.Data.cardModel.Acc !== undefined) {
                this.accountService.getFreeAccount(this.$scope.model.Data.cardModel.Acc).then(result => {
                    this.$scope.model.Data.Accounts = result.Result;
                });
            }
        }

        cardLookupInit = () => {
            var that = this;
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.accountService.getAccountsTree().then((a: any) => {
                            _.forEach(a.Result, (item: any) => {
                                item["NameEx"] = item.Acc + " - " + item.Name;
                            });
                            options.success(a.Result || []);
                        });
                    }
                }
            });
            return {
                columns: [
                    { field: 'Acc', titleTranslated: 'CardBalance', width: 120 },
                    { field: 'Name', titleTranslated: 'nameCaption' }
                ],
                dataSource: dataSource,
                valueText: 'NameEx'
            }
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        saveBulkAcc = (arrayAcc, error, translate) => {
            this.accountService.saveAccount(JSON.parse(JSON.stringify(arrayAcc[0]))).then(result => {
                if (result !== null && (result.success === false)) {
                    error = true;
                    this.$scope.loadingVisibled = false;
                }
                arrayAcc.shift();
                if (arrayAcc.length > 0 && !error) {
                    this.saveBulkAcc(arrayAcc, error, translate);
                } else if (result !== undefined && !error && !result.success) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                    this.$scope.loadingVisibled = false;
                }
            });
        }

        save = () => {

            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ, შეავსოთ ყველა სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model.Data;
            var modelForSave = {
                Acc: model.Acc,
                PlaceId: model.Place_id,
                Accounts: model.Accounts,
                Lap: model.Lap,
                AccAlt: model.AccAlt,
                LQuantitative: model.lQuantitative,
                DateStart: model.Date_Start,
                DateEnd: model.Date_End,
                LOff: model.LOff,
                ReCalc: model.Recalc,
                Name: model.Acc_nu,
                NameEn: model.acc_ne || "",
                ClientID: model.Clients_id,
                Card: model.cardModel.Acc,
                CurrencyID: model.CurrencyCurrency_Id,
                Spend: model.Spend,
                unid: model.unid,
                prsID: model.prsID,
                Details: {
                    Address: model.AccountDetailsaddress,
                    JurAddress: model.AccountDetailsjur_address,
                    SN: model.AccountDetailssn,
                    OwnerName: model.AccountDetailsowner_name,
                    DataType: model.AccountDetailsdata_type
                }
            }
            if (this.$scope.showMultiSelect) {
                if (this.$scope.model.Data.currencyIds == undefined || this.$scope.model.Data.currencyIds == null || this.$scope.model.Data.currencyIds.length == 0) {
                    this.toaster.pop('error', 'შეცდომა!', 'ანგარიშის შესანახად აირჩიეთ ვალუტა!');
                    return;

                }
                this.$scope.loadingVisibled = true;

                var currenciesCount = this.$scope.model.Data.currencyIds.length;
                var arrayOfAcc = [];
                for (var i = 0; i < currenciesCount; i++) {
                    var item = this.$scope.model.Data.currencyIds[i];
                    modelForSave.CurrencyID = item;
                    modelForSave.Acc = modelForSave.Card + modelForSave.Accounts + modelForSave.CurrencyID;
                    var error = false;
                    arrayOfAcc.push(JSON.parse(JSON.stringify(modelForSave)));
                }

                this.saveBulkAcc(arrayOfAcc, error, translate);

            } else {
                this.$scope.loadingVisibled = true;
                this.accountService.updateAccount(modelForSave).then(result => {
                    if (result !== undefined) {
                        if (result.success !== undefined && !result.success) {
                            this.$scope.loadingVisibled = false;
                            return;
                        }
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$uibModalInstance.close(true);

                    } else {
                        this.$scope.loadingVisibled = false;
                    }
                    return " ";
                }, () => {
                    this.$scope.loadingVisibled = false;
                });
            }
        }
    }


    export interface AccountCardModalScope extends angular.IScope {
        model: {
            Data: any
        };
        components: {
            cardLookup: Components.IApxLookup
        };
        events: {
            save: Function,
            close: Function
        }
        formName: string;
        formScope: any;
        showMultiSelect: boolean;
        getFreeAccount: any;

        numMask: any;
        currencies: any;
        accCards: any;
        loadingVisibled: boolean;
        formMode: any;
        setForm: Function
    }
    export class AccountCardModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'accountService', Globals.modalInstance, Globals.toaster, Globals.http
        ];

        constructor(private $scope: AccountCardModalScope, data: any, private accountService: Services.Account,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService) {
            super(arguments, AccountCardModal.$inject);
            $scope.loadingVisibled = false;
            $scope.setForm = (formScope) => {
                setTimeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = {
                Data: data.model
            }
            $scope.events = {
                save: this.save,
                close: this.closeForm
            }
            $scope.formMode = data.formMode;
            $scope.numMask = this.numMask;
            setTimeout(() => {
                if ($scope.model.Data.Acc !== undefined) {
                    $scope.formName = "საბალანსო ანგარიშის რედაქტირება - " + $scope.model.Data.Acc;
                    if ($scope.model.Data.Lap === 1) {
                        $scope.model.Data.Lap = true;
                    } else {
                        $scope.model.Data.Lap = false;
                    }
                } else {
                    $scope.formName = "საბალანსო ანგარიშის დამატება";
                    $scope.model.Data.NameEn = "";
                    $scope.model.Data.CardAlt = "";
                    $scope.model.Data.Lquantitative = false;
                    $scope.model.Data.Spend = false;
                    $scope.model.Data.Lap = false;

                }
            });
        }
        numMask = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;
            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys //-=chubro--=\\
                return;
            }
            if (!/^[0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            if (this.$scope.model.Data.Acc && this.$scope.model.Data.Acc.length < 4) {
                this.toaster.pop('error', 'შეცდომა!', 'ანგარიშის ნომერი არ უნდა იყოს 4 სიმბოლოზე ნაკლები!');
                return;
            }
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid || !this.$scope.model.Data) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ, შეავსოთ ყველა სავალდებულო ველი!');
                return;
            }
            this.$scope.loadingVisibled = true;
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model.Data;
            var modelForSave = {
                card: model.Acc,
                card_ne: model.NameEn,
                cardAlt: model.CardAlt,
                lquantitative: model.Lquantitative,
                lap: model.Lap,
                Spend: model.Spend,
                Card_nu: model.Name,
                can_delete: model.can_delete
            }

            if (modelForSave.can_delete === undefined)
                modelForSave.can_delete = true;

            var action;
            if (this.$scope.formMode)
                action = this.accountService.updateAccCard(modelForSave);
            else
                action = this.accountService.saveAccCard(modelForSave);
            action.then(result => {
                if (result !== undefined) {
                    if (typeof (result.success) === "boolean" && !result.success) {
                        this.$scope.loadingVisibled = false;
                        return;
                    }
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                } else {
                    this.$scope.loadingVisibled = false;
                }
                return " ";
            }, () => {
                this.$scope.loadingVisibled = false;
            });
        }
    }
}