/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IProductRecipe {
        Materrial: IGood;
        MCount: number;
        BCount: number;
        Note: string;
        CanExpand: boolean;
    }
} 