/// <reference path='../_reference.ts' />
module Apex.Account.Controllers {
    export class Books extends Core.Controller {
        booksGrid: Core.Kendo.Grid;
        private currencies = [];
        public static $inject = [
            Globals.scope, 'accountService', 'dialogs', Globals.translate, Globals.toaster, "$rootScope"
        ];
        constructor(private $scope: any, private accountService: Services.Account, private dialogs: any, private $translate: any, private toaster: any, private ismodal: boolean = false) {
            super(arguments, Books.$inject);
            this.$scope.filters = {
                dateBetween: {},
                oper: []
            };
            this.$scope.showRefreshInfo = true;

            this.$scope.$watch('filters', () => {
                this.$scope.showRefreshInfo = true;
                delete this.$scope.selectedDoc;
            }, true);
            this.$scope.components = {
                bookOptions: this.booksOptions(),
                events: {
                    delete: () => {
                        Utils.Documents.deleteDoc(this.$scope.selectedDoc.DocID, this.$scope.components.events.refresh);
                    },
                    edit: () => {
                        Utils.Documents.openDoc(this.$scope.selectedDoc.DocID, false, this.$scope.components.events.refresh);
                    }, copy: () => {
                        Utils.Documents.openDoc(this.$scope.selectedDoc.DocID, true, this.$scope.components.events.refresh);
                    },
                    refresh: () => {
                        this.$scope.components.bookOptions.gridView.showLoading();
                        this.$scope.components.bookOptions.gridView.setFilter(this.getBookFilters());
                        this.$scope.components.bookOptions.gridView.refresh();
                        this.$scope.showRefreshInfo = false;
                    },
                    blockUnblock: () => {
                        var selectedItem = this.$scope.selectedDoc;

                        var blockUnblockText = "blockRow";
                        if (selectedItem.blocking === true) {
                            blockUnblockText = "unBlockRow";
                        }

                        var confirmDialog = Core.Utils.ModalForm.openConfirmation(blockUnblockText, true);

                        confirmDialog.result.then(() => {
                            selectedItem.blocking = !selectedItem.blocking;
                            accountService.docBlocking(selectedItem.DocID, selectedItem.blocking).then((res) => {
                                if (res.Result === true) {
                                    var successText = "successBlocked";
                                    if (selectedItem.blocking === false) {
                                        successText = "successUnBlocked";
                                    }
                                    toaster.success($translate.instant('Info'), $translate.instant(successText));
                                    $scope.isBlock = !$scope.isBlock;
                                    $scope.components.events.refresh();
                                }
                            });
                        });
                    }
                }
            };
            $scope.isBlock = false;
            this.allowedOpersDs();
            this.onCompanyChange = (newCompany, oldCompany) => {
                this.allowedOpersDs();
                $scope.components.events.refresh();
            }
        }
        blockUnblock = () => {


        }

        checkeNullAndUndefined(val) {
            
            return typeof val !== "undefined" && val !== null;
        }
        getBookFilters = () => {
                        
            var filters: Array<Core.Interfaces.IGridFilter> = [
                { filterName: 'OperId', filterOperator: 'IN', filterValue: this.$scope.filters.oper }
            ];

            if (this.checkeNullAndUndefined(this.$scope.filters.acc) && this.checkeNullAndUndefined(this.$scope.filters.acc.crOrDB)) {
                filters.push({ filterName: this.$scope.filters.acc.crOrDB, filterOperator: 'StartsWith', filterValue: this.$scope.filters.acc.value });
            }

            if (this.checkeNullAndUndefined(this.$scope.filters.dateBetween.fromDate)  &&
                this.checkeNullAndUndefined(this.$scope.filters.dateBetween.toDate)) {
                var from = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.fromDate);
                var to = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.toDate);
               
                filters.push({
                    filterName: 'Date',
                    filterOperator: 'Between',
                    filterValue: [from, to]
                });
                return filters;
            }
            return [];
        }
        booksOptions = () => {

            var datasourceModel = {
                id: "Id",
                fields: {
                    blocking: { type: 'boolean' },
                    recstatus: { type: 'number' },
                    Date: { type: "date" },
                    OpDetId: { type: "string" },
                    DB: { type: "string" },
                    CR: { type: "string" },
                    CurrencyId: { type: "string" },
                    Amount: { type: "number" },
                    numberin: { type: "string" },
                    numberout: { type: "string" },
                    code: { type: "string" },
                    cuser: { type: "string" },
                    crtime: { type: "date" }
                }
            }
            var booksOptions: Components.IApxGrid = {
                columns: this.columns,
                templates: this.templates,
                settings: {
                    autoBind: false,
                    pageable: true
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change, eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedDoc = booksOptions.selectedObject;
                                if (!!this.$scope.selectedDoc && this.$scope.selectedDoc.blocking === true) {
                                    this.$scope.isBlock = true;
                                } else {
                                    this.$scope.isBlock = false;
                                }
                            });
                        }
                    }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.booksQueryUrl, datasourceModel)
            };




            // booksOptions.dataSource.sort([
            //     { field: "Date", dir: "desc" }, { field: "DB", dir: "asc" }, { field: "CR", dir: "asc" }, { field: "crtime", dir: "asc" }]
            // );

            if (typeof (this.ismodal) === "boolean" && this.ismodal) {
                booksOptions.settings.height = 600;
                booksOptions.settings.autoBind = true;
            }

            return booksOptions;
        }
        allowedOpersDs = () => {
            Utils.Documents.allOperations().then((result: any) => {
                this.$scope.allOperations = result;
                return "";
            });
            this.accountService.getOpers().then((response) => {
                _.each(response.Result, (x: any) => { x.templateName = x.OperID + ' - ' + x.Name });
                this.$scope.allowedOpers = _.filter(response.Result, (x: any) => (typeof x.WebFormName !== "undefined"));
                this.$scope.allOeprs = response.Result;
            });
        }
        templates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'blocking', template: '<div class="text-center" ng-if="dataItem.blocking"><i class="fa fa-lock" style="font-size: 14px;"></i></div>' },
            { fieldName: 'recstatus', template: '<div class="text-center" ng-if="dataItem.recstatus"><img src="./img/fx.png" width="16"></div>'},
            { fieldName: 'Date', template: '<span>{{::dataItem.Date | dateFormat | date:"dd/MM/yyyy":"+0800"}}</span>' },
            { fieldName: 'Amount', template: '<div class="apx-grid-cell-number">{{::dataItem.Amount| number:2}}</div>' },
            { fieldName: 'crtime', template: '<span>{{::dataItem.crtime | dateFormat | date:"dd/MM/yyyy HH:mm":"+0800"}}</span>' }
        ];
        columns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'blocking', width: 40,
                filterable: 'custom',
                titleTranslated: 'Blocking',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: " " },
                            { value: true, text: "+" },
                            { value: false, text: " - " }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"

                    })
                },
            },
            {
                field: 'recstatus', width: 40,
                filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: " " },
                            { value: 1, text: " + " },
                            { value: 0, text: " - " }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"
                    })
                },
                titleTranslated: 'FX'
            },
            { field: 'Date', titleTranslated: 'DATE', width: 90, filterable: false },
            { field: 'OpDetId', titleTranslated: 'OpDetId', width: 75, isId: true },
            { field: 'DB', titleTranslated: 'DB' },
            { field: 'CR', titleTranslated: 'CR' },
            { field: 'CurrencyId', titleTranslated: 'Currency', width: 60, filterOperator: 'eq' },
            { field: 'Amount', titleTranslated: 'amount', width: 90, filterOperator: 'eq' },
            { field: 'numberin', titleTranslated: 'numberin', filterOperator: 'contains', width: 80 },
            { field: 'numberout', titleTranslated: 'numberout', filterOperator: 'eq', width: 80 },
            { field: 'code', titleTranslated: 'waybillNumShort', filterOperator: 'eq', width: 100 },
            { field: 'cuser', titleTranslated: 'cuser', filterOperator: 'eq', width: 70 },
            { field: 'crtime', titleTranslated: 'crtime', width: 105, filterable: false }
        ];

    }
}
