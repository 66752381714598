/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface ISupplie {
        ID?: string;
        Book_id?: string;
        Vg?: number;
        Vu?: number;
        Vc?: number;
        Vat?: number;
        ProdPP_id?: string;
        Product?: IGood;
        Code?: string;
        ICount?: number;
        RCount?: number;
        LifeDate?: string;
        Orders_id?: string;
        ReturnedSale?: IOrder;
        ReturnedBook?: IBook;
        SuppliesAcc?: string;
        SuppliesName?: string;
        NumberIn?: string;
        NumberOut?: string;
        SuppDate?: Date;
        Discount?: number;
        VCustomG?: number;
        VCustomU?: number;
        VCustomC?: number;
        DestNumber?: string;
    }
} 