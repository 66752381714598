/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {

    export interface IRealizationModel {
        paymentDate?: Date;
        products?: Array<any>;
        dDate?: Date;
        numberIn?: string;
        numberOut?: string;
        debet?: string;
        credit?: string;
    }

    export interface IRealizationScope extends IBaseBookScope {
        realizationModel: IRealizationModel;
        productsGridOptions: Components.IApxGrid;
        productGridOptionsForModal: Components.IApxGrid;
        translateProvider: any;
        isDisabled: boolean;
        expand: Function;
        IsDirty: boolean;
        VatText: string;
        collapse: Function;
        isExpanded: boolean;
    }


    export class RealizationByPrice extends BaseBook {
        $scope: IRealizationScope;

        constructor($scope: IRealizationScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            this.$scope.IsDirty = false;
            $scope.VatText = (this.$scope.vat * 100).toString() + '%';
            this.$scope.realizationModel = this.initRealizationModel($scope.docInfo, data.Operation.OperID);
            if ($scope.docInfo.DocID) $scope.isDisabled = true;
            else $scope.isDisabled = false;
            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.saveDoc = this.saveDoc;
            $scope.productsGridOptions = this.productGridOptions($scope.realizationModel.products, $scope.isDisabled);
            $scope.productGridOptionsForModal = this.productGridOptionsForModal();

            this.$scope.$watch('productsGridOptions.grid', (grid) => {
                if (grid != undefined) {
                    this.$scope.productsGridOptions.grid.hideColumn('LifeDate');
                    this.$scope.productsGridOptions.grid.hideColumn('Code');
                }
            });

            $scope.$watch("baseModel.credit", (credit: any) => {
                if (credit != undefined && !$scope.isDisabled) {
                    if ($scope.isExpanded)
                        this.getAvailableProductsNotGrouped($scope.realizationModel.dDate, credit.Acc);
                    else
                        this.getAvailableProductsGrouped($scope.realizationModel.dDate, credit.Acc);
                }
            });

            $scope.expand = this.expand;
            $scope.collapse = this.collapse;

            this.$timeout(() => {
                $('#addProduct').click(() => { 
                    $scope.productGridOptionsForModal.dataSource.data([]);
                    this.accountService.getAvaliableSuppliesNotGrouped(this.$scope.realizationModel.dDate, this.$scope.baseModel.credit.Acc).then(a => {

                        this.$scope.productGridOptionsForModal.dataSource.data(a.Result); 
                    });
                });
            });
            
        }


        private getColumnTemplates(isDisabled: boolean): Array<Core.Interfaces.IGridColumnTemplate> {
            if (isDisabled) {
                return [
                    {
                        fieldName: 'Vat',
                        editor: (container: JQuery, options: any) => {
                            $('<input data-text-field="VatText" data-value-field="Id" data-bind="value:' + options.field + '"/>')
                                .appendTo(container)
                                .kendoDropDownList({
                                    dataSource: [{ Id: 0, VatText: '0%' }, { Id: this.$scope.vat, VatText: this.$scope.VatText }],
                                    dataTextField: "VatText",
                                    dataValueField: "Id"
                                });
                        }
                    }
                ];
            } else {
                return [
                    {
                        fieldName: 'Product.Vat',
                        editor: (container: JQuery, options: any) => {
                            $('<input data-text-field="VatText" data-value-field="Id" data-bind="value:' + options.field + '"/>')
                                .appendTo(container)
                                .kendoDropDownList({
                                    dataSource: [{ Id: 0, VatText: '0%' }, { Id: this.$scope.vat, VatText: this.$scope.VatText }],
                                    dataTextField: "VatText",
                                    dataValueField: "Id"
                                });
                        }
                    }
                ];
            }
        }

         getAggregates = (isDisabled: boolean) => {
            if (isDisabled)
                return [
                    { field: 'SCount', aggregate: 'sum' },
                    { field: 'Vg', aggregate: 'sum' }
                ];
            else
                return [
                    { field: 'Count', aggregate: 'sum' },
                    { field: 'vgLocal', aggregate: 'sum' }
                ];
        }

        getOrdersDatasource(dataSource: any, isDisabled: boolean): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate: this.getAggregates(isDisabled),
                pageSize: 50,
                schema: {
                    model: {
                        id: this.getGridId(isDisabled),
                        fields: this.getFields(isDisabled)
                    }
                }
            });
            return ds;
        }

        private getColumns(isDisabled: boolean): Array<Core.Interfaces.IGridColumn> {
            var columns: Array<Core.Interfaces.IGridColumn>;
            if (isDisabled) {
                columns = [
                    { field: 'ID', titleTranslated: 'orderId', width: 120, hidden: true },
                    { field: 'BCode', titleTranslated: 'barcode', width: 130 },
                    { field: 'Name', titleTranslated: 'product', width: 400 },
                    { field: 'Unit', titleTranslated: 'unit', width: 80 },
                    { field: 'RCount', titleTranslated: 'rest', format: Core.Kendo.Format.Price4, width: 120 },
                    { field: 'PriceG', titleTranslated: 'PriceG', width: 120, format: Core.Kendo.Format.Price4, editable: true },
                    {
                        field: 'SCount', titleTranslated: 'Count', width: 120, format: Core.Kendo.Format.Price4, editable: true,
                        footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.SCount && data.SCount.sum ? parseFloat(data.SCount.sum).toFixed(4) : 0) #'
                    },
                    { field: 'ProdPP_id', titleTranslated: 'prodppId', width: 120, hidden: true },
                    { field: 'Vat', titleTranslated: 'vat', width: 60, editable: true, format: Core.Kendo.Format.Percent },
                    { field: 'Discount', titleTranslated: 'discount', width: 60, editable: true, format: Core.Kendo.Format.Number },
                    {
                        field: 'Vg', titleTranslated: 'vg', format: Core.Kendo.Format.Price4, width: 120, editable: true,
                        footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.Vg && data.Vg.sum ? parseFloat(data.Vg.sum).toFixed(4) : 0) #'
                    },
                    { field: 'MaxDiscount', titleTranslated: 'maxDiscount', width: 120, format: Core.Kendo.Format.Percent },
                    { field: 'Unit', titleTranslated: 'unit', width: 120 },
                    { field: 'ProducerName', titleTranslated: 'producer', width: 400 },
                    { field: 'CategoryName', titleTranslated: 'categoryName', width: 400 }
                ];
            } else {
                columns = [
                    { field: 'Product.BCode', titleTranslated: 'barcode', width: 130 },
                    { field: 'Product.Name', titleTranslated: 'product', width: 400 },
                    { field: 'Product.Unit', titleTranslated: 'unit', width: 80 },
                    { field: 'RCount', titleTranslated: 'rest', format: Core.Kendo.Format.Price4, width: 120 },
                    { field: 'LifeDate', titleTranslated: 'lifeDate', width: 120, format: Core.Kendo.Format.Date },
                    { field: 'Code', titleTranslated: 'goodMarkerCode', width: 120 },
                    { field: 'ProdPP_id', titleTranslated: 'prodppId', width: 120, hidden: true },
                    { field: 'ID', titleTranslated: 'supplieId', width: 120, hidden: true }, 
                    { field: 'Product.PriceGT', titleTranslated: 'PriceG', format: Core.Kendo.Format.Price4, width: 120, editable: true },
                    {
                        field: 'Count', titleTranslated: 'Count', width: 120, format: Core.Kendo.Format.Price4, editable: true,
                        footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.Count && data.Count.sum ? parseFloat(data.Count.sum).toFixed(4) : 0) #'
                    },
                    { field: 'Product.Vat', titleTranslated: 'vat', width: 60, editable: true, format: Core.Kendo.Format.Percent },
                    { field: 'Discount', titleTranslated: 'discount', width: 60, editable: true, format: Core.Kendo.Format.Number },
                    {
                        field: 'vgLocal', titleTranslated: 'vg', format: Core.Kendo.Format.Price4, width: 120, editable: true,
                        footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.vgLocal && data.vgLocal.sum ? parseFloat(data.vgLocal.sum).toFixed(4) : 0) #'
                    },
                    { field: 'Product.MaxDiscount', titleTranslated: 'maxDiscount', width: 50, format: Core.Kendo.Format.Percent },
                    { field: 'Product.Producer.ProducerName', titleTranslated: 'producer', width: 400 },
                    { field: 'Product.Category.CategoryName', titleTranslated: 'categoryName', width: 400 }
                ];
            }
            return columns;
        }
        private getGridId(isDisabled: boolean): string {
            if (isDisabled) {
                return 'ID';
            }
            else
                return 'ProdPP_id';
        }


        private getFields(isDisabled: boolean): any {
            var fields: any;
            if (isDisabled) {
                fields = {
                    'ID': { editable: false, type: 'string' },
                    'Discount': { type: 'number' },
                    'Vg': { type: 'number' },
                    'BCode': { editable: false, type: 'string' },
                    'Name': { editable: false, type: 'string' },
                    'Unit': { editable: false, type: 'string' },
                    'ProducerName': { editable: false, type: 'string' },
                    'CategoryName': { editable: false, type: 'string' },
                    'MaxDiscount': { editable: false, type: 'number' },
                    'RCount': { editable: false, type: 'number' },
                    'PriceG': { type: 'number' },
                    'SCount': { type: 'number' },
                    'ProdPP_id': { type: 'string' },
                    'Vat': { type: 'number' }
                }
            } else {
                fields = {
                    'Product.BCode': { editable: false, type: 'string' },
                    'Product.Name': { editable: false, type: 'string' },
                    'Product.Unit': { editable: false, type: 'string' },
                    'Product.Producer.ProducerName': { editable: false, type: 'string' },
                    'Product.Category.CategoryName': { editable: false, type: 'string' },
                    'Product.MaxDiscount': { editable: false, type: 'number' },
                    'RCount': { editable: false, type: 'number' },
                    'LifeDate': { editable: false, type: 'date' },
                    'Code': { editable: false, type: 'string' },
                    'ProdPP_id': { type: 'string' },
                    'ID': { type: 'string' },
                    'Product.PriceGT': { type: 'number' },
                    'Count': { type: 'number' },
                    'Product.Vat': { type: 'number' },
                    'Discount': { type: 'number' },
                    'vgLocal': { type: 'number' }
                }
            }
            return fields;
        }

        private productGridOptions(datasource: Array<IOrderModel>, isDisabled: boolean): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: this.getColumns(isDisabled),
                    templates: this.getColumnTemplates(isDisabled),
                    dataSource: this.getOrdersDatasource(datasource, isDisabled),
                    settings:
                    {
                        virtualScroll: true,
                        editble: {
                            popupTemplateID: 'good_realization_popup_editor',
                            popupWidth: 1200
                        },
                        gridButtons: {
                            exportToExcell: true,
                            add: { enabled: this.$scope.isDisabled, id: 'Product' }
                        },
                        height: 500
                    },
                    parentScope: this.$scope,
                    gridEvents: [
                        this.getAddEvent(),
                        this.getCellFocusEvent(),
                        this.getExportToExcelEvent()
                    ]
                };
            return gridOptions;
        }
        private productGridOptionsForModal(): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: [
                        { field: 'Product.BCode', titleTranslated: 'barcode', width: 100 },
                        { field: 'Product.Name', titleTranslated: 'product', width: 200 },
                        { field: 'Product.Unit', titleTranslated: 'unit', width: 80 },
                        { field: 'RCount', titleTranslated: 'rest', format: Core.Kendo.Format.Price4, width: 80 },
                        { field: 'LifeDate', titleTranslated: 'lifeDate', width: 100, format: Core.Kendo.Format.Date },
                        { field: 'Code', titleTranslated: 'goodMarkerCode', width: 100 },
                        { field: 'ProdPP_id', titleTranslated: 'prodppId', hidden: true },
                        { field: 'ID', titleTranslated: 'supplieId', hidden: true },
                        { field: 'Product.PriceGT', titleTranslated: 'PriceG', format: Core.Kendo.Format.Price4, width: 80 },
                        { field: 'Count', titleTranslated: 'Count', width: 80, format: Core.Kendo.Format.Price4, editable: true },
                        { field: 'Product.Vat', titleTranslated: 'vat', width: 80, format: Core.Kendo.Format.Percent },
                        { field: 'Discount', titleTranslated: 'discount', width: 80, format: Core.Kendo.Format.Percent },
                        { field: 'Product.MaxDiscount', titleTranslated: 'maxDiscount', width: 80, format: Core.Kendo.Format.Percent },
                        { field: 'Product.Producer.ProducerName', titleTranslated: 'producer', width: 200 },
                        { field: 'Product.Category.CategoryName', titleTranslated: 'categoryName', width: 200 }
                    ],
                    templates: this.getColumnTemplates(false),
                    dataSource: new kendo.data.DataSource({
                        data: [],
                        pageSize: 50,
                        schema: {
                            model: {
                                fields: {
                                    'Product.PriceGT': { editable: false, type: 'number' },
                                    'Product.Vat': { editable: false, type: 'number' },
                                    'Discount': { editable: false, type: 'number' },
                                    'Product.BCode': { editable: false, type: 'string' },
                                    'Product.Name': { editable: false, type: 'string' },
                                    'Product.Unit': { editable: false, type: 'string' },
                                    'Product.Producer.ProducerName': { editable: false, type: 'string' },
                                    'Product.Category.CategoryName': { editable: false, type: 'string' },
                                    'Product.MaxDiscount': { editable: false, type: 'number' },
                                    'RCount': { editable: false, type: 'number' },
                                    'LifeDate': { editable: false, type: 'date' },
                                    'Code': { editable: false, type: 'string' },
                                    'ProdPP_id': { type: 'string' },
                                    'ID': { type: 'string' },
                                    'Count': { type: 'number' }
                                }
                            }
                        }
                    }),
                    gridEvents: [
                        {
                            eType: Components.EventType.Edit, eFunction: (e: any) => {
                                var options: any = { numeric: true, min: 0, allowMinus: false }
                                if (e.model.Product.PackCount === 1) {
                                    $(".apx-col-edit-Count").find("input").inputmask('integer', options);
                                }
                            }
                        },
                        {
                            eType: Components.EventType.Save, eFunction: (e: any) => {
                                if (e.model != undefined) {
                                    if (e.values.Count == null) {
                                        e.preventDefault();
                                        return;
                                    }

                                    if (e.values.Count != undefined) {
                                        if (e.model.RCount < e.values.Count) {
                                            this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("notenoughremains"));
                                            e.preventDefault();
                                        }
                                    }
                                }
                            }
                        }
                    ],
                    settings:
                    {
                        pageable: true,
                        editble: { disableEditRow: false },
                        virtualScroll: true,
                        height: 500
                    }
                };
            return gridOptions;
        }

        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        exportToExcelFunctionality = (e) => {
            var rows = e.workbook.sheets[0].rows;
            for (var ri = 0; ri < rows.length; ri++) {
                var row = rows[ri];
                if (row.type === "header") {
                    _.forEach(row.cells, (item: any) => {
                        item = this.initExcelCaptions(item);
                    });
                }
                if (row.type === "group-footer" || row.type === "footer") {
                    for (var ci = 0; ci < row.cells.length; ci++) {
                        var cell = row.cells[ci];
                        if (cell.value) {
                            cell.value = '';
                        }
                    }
                }
            }
        }

        initExcelCaptions = (column: any): any => {
            if (column.value.includes("'barcode'")) {
                column.value = this.$scope.translateProvider.instant('barcode');
                return column;
            }
            if (column.value.includes("'product'")) {
                column.value = this.$scope.translateProvider.instant('product');
                return column;
            }
            if (column.value.includes("'categoryName'")) {
                column.value = this.$scope.translateProvider.instant('categoryName');
                return column;
            }
            if (column.value.includes("'producer'")) {
                column.value = this.$scope.translateProvider.instant('producer');
                return column;
            }
            if (column.value.includes("'unit'")) {
                column.value = this.$scope.translateProvider.instant('unit');
                return column;
            }
            if (column.value.includes("'rest'")) {
                column.value = this.$scope.translateProvider.instant('rest');
                return column;
            }
            if (column.value.includes("'Count'")) {
                column.value = this.$scope.translateProvider.instant('Count');
                return column;
            }
            if (column.value.includes("'PriceG'")) {
                column.value = this.$scope.translateProvider.instant('PriceG');
                return column;
            }
            if (column.value.includes("'goodMarkerCode'")) {
                column.value = this.$scope.translateProvider.instant('goodMarkerCode');
                return column;
            }
            if (column.value.includes("'lifeDate'")) {
                column.value = this.$scope.translateProvider.instant('lifeDate');
                return column;
            }
            if (column.value.includes("'vat'")) {
                column.value = this.$scope.translateProvider.instant('vat');
                return column;
            }
            if (column.value.includes("'vat'")) {
                column.value = this.$scope.translateProvider.instant('vat');
                return column;
            }
            if (column.value.includes("'discount'")) {
                column.value = this.$scope.translateProvider.instant('discount');
                return column;
            }
            if (column.value.includes("'vg'")) {
                column.value = this.$scope.translateProvider.instant('vg');
                return column;
            }
            if (column.value.includes("'maxDiscount'")) {
                column.value = this.$scope.translateProvider.instant('maxDiscount');
                return column;
            }
            return column;
        }



        private getAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var cellEdit: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addEventFunctionality
                };
            return cellEdit;
        }

        addEventFunctionality = (e) => {
             
            if (e.model == undefined) {
                var data: any = this.$scope.productGridOptionsForModal.grid.dataSource.data();
                var oldData: any = this.$scope.productsGridOptions.grid.dataSource.data();
                _.forEach(data, (supplie: any) => {
                    if (supplie.Count > 0 && !_.some(oldData, function (orderModel: any) { return orderModel.Supplies_id == supplie.ID; })) {
                        var orderModel: any = {
                            Supplies_id: supplie.ID,
                            SCount: supplie.Count,
                            BCode: supplie.Product.BCode,
                            Name: supplie.Product.Name,
                            Unit: supplie.Product.Unit,
                            PriceG: supplie.Product.PriceGT,
                            ProdPP_id: supplie.ProdPP_id,
                            Vat: supplie.Product.Vat,
                            Discount: supplie.Discount * 100,
                            ProducerName: supplie.Product.Producer.ProducerName,
                            CategoryName: supplie.Product.Category.CategoryName,
                            MaxDiscount: supplie.Product.MaxDiscount,
                            RCount: supplie.RCount - supplie.Count,
                            Vg: supplie.Count * supplie.Product.PriceGT
                        };
                        oldData.push(orderModel);
                    }
                });
                this.$scope.productsGridOptions.grid.dataSource.data().remove(this.$scope.productsGridOptions.grid.dataSource.data()[0]);
                this.$scope.productsGridOptions.grid.dataSource.sync();
            }
            else {
                this.calculationOnEditEvent(e, e.values, e.model);
                e.model.changed = true;
                this.$scope.productsGridOptions.grid.dataSource.sync();
            }
        }

        getCellFocusEvent(): Components.IEvent {
            var cellFocuseEventType: Components.EventType = Components.EventType.Edit;
            var cellFocusEvent: Components.IEvent =
                {
                    eType: cellFocuseEventType,
                    eFunction: this.cellFocusFunctionality
                };
            return cellFocusEvent;
        }

        cellFocusFunctionality = (e: any) => {
            var options: any = { numeric: true, min: 0, allowMinus: false }
            if (this.$scope.isDisabled) {
                if (e.model.PackCount === 1) {
                    $(".apx-col-edit-SCount").find("input").inputmask('integer', options);
                }
            } else {
                if (e.model.Product.PackCount === 1) {
                    $(".apx-col-edit-Count").find("input").inputmask('integer', options);
                }
            }
        }

        private calculationOnEditEvent(e: any, changedValues: any, model: any): void {

            switch (null) {
                case changedValues.Count:
                    e.preventDefault();
                    return;
                case changedValues.SCount:
                    e.preventDefault();
                    return;
                case changedValues.Vg:
                    e.preventDefault();
                    return;
            }

            this.$scope.IsDirty = true;
            if (changedValues.Discount != undefined) {
                var discountNew = changedValues.Discount / 100;
                if (this.$scope.isDisabled) {                  
                    if (discountNew > model.MaxDiscount) {
                        this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("discountNotValid"));
                        e.preventDefault();
                        return;
                    }
                    if (model.SCount != undefined && model.SCount !== 0 && model.PriceG !== undefined && model.PriceG !== 0) {
                        model.Vg = (model.SCount * model.PriceG) - ((model.SCount * model.PriceG) * discountNew);
                        return;
                    }
                } else {
                    if (discountNew > model.Product.MaxDiscount) {
                        this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("discountNotValid"));
                        e.preventDefault();
                        return;
                    }
                    if (model.Count != undefined && model.Count !== 0 && model.Product.PriceGT != undefined && model.Product.PriceGT !== 0) {
                        model.vgLocal = (model.Count * model.Product.PriceGT) - ((model.Count * model.Product.PriceGT) * discountNew);
                        return;
                    }
                }
            }

            if (changedValues.SCount != undefined) {
                if (changedValues.SCount > (model.SCount + model.RCount)) {
                    this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("notenoughremains"));
                    e.preventDefault();
                    return;
                }
                if (model.PriceG != undefined && model.PriceG !== 0) {
                    model.Vg = (changedValues.SCount * model.PriceG) - ((changedValues.SCount * model.PriceG) * model.Discount / 100);
                    model.RCount = (model.SCount + model.RCount) - changedValues.SCount;
                    return;
                }
            }

            if (changedValues.Count != undefined) {
                if (changedValues.Count > (model.Count + model.RCount)) {
                    this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("notenoughremains"));

                    e.preventDefault();
                    return;
                }

                if (model.Count == undefined || model.Count == null) model.Count = 0;
                model.RCount = (model.Count + model.RCount) - changedValues.Count;

                if (model.Product.PriceGT != undefined && model.Product.PriceGT !== 0) {
                    model.vgLocal = (changedValues.Count * model.Product.PriceGT) - ((changedValues.Count * model.Product.PriceGT) * model.Discount / 100);
                    return;
                }
            }

            if (changedValues.PriceG != undefined && model.SCount != undefined && model.SCount !== 0) {
                model.Vg = (model.SCount * changedValues.PriceG) - ((model.SCount * changedValues.PriceG) * model.Discount / 100);
                return;
            }

            if (changedValues['Product.PriceGT'] != undefined && model.Count != undefined && model.Count !== 0) {
                model.vgLocal = (model.Count * changedValues['Product.PriceGT']) - ((model.Count * changedValues['Product.PriceGT']) * model.Discount / 100);
                return;
            }

            if (changedValues.Vg != undefined) {
                if (this.$scope.isDisabled) {
                    if ((1 - (model.Discount / 100)) == 0) {
                        e.preventDefault();
                        return;
                    }
                    else {
                        model.PriceG = changedValues.Vg / (1 - (model.Discount / 100)) / model.SCount;
                        return;
                    }
                }
            }

            if (changedValues.vgLocal != undefined) {
                if ((1 - (model.Discount / 100)) == 0) {
                    e.preventDefault();
                    return;
                }
                else {
                    model.Product.PriceGT = changedValues.vgLocal / (1 - (model.Discount / 100)) / model.Count;
                    return;
                }
            }

        }

        private initRealizationModel(docInfo: Interfaces.IDoc, operid: any): IRealizationModel {
            var model: IRealizationModel = {};
            model.dDate = this.$scope.baseModel.crDate;
            model.numberIn = docInfo.NumberIn;
            model.numberOut = docInfo.NumberOut;
            model.debet = docInfo.DB;
            model.credit = docInfo.CR;
            model.paymentDate = docInfo.DPay;
            var datasource = [];
            _.forEach(docInfo.Orders, (order: Interfaces.IOrder) => {
                var obj = {
                    ID: order.ID,
                    BCode: order.Supplie.Product.BCode,
                    Name: order.Supplie.Product.Name,
                    Unit: order.Supplie.Product.Unit,
                    RCount: order.Supplie.RCount,
                    PriceG: order.PriceG,
                    SCount: order.SCount,
                    ProdPP_id: order.Supplie.ProdPP_id,
                    Vat: order.Vat,
                    Discount: order.Discount * 100,
                    Vg: order.Vg,
                    MaxDiscount: order.Supplie.Product.MaxDiscount,
                    ProducerName: order.Supplie.Product.Producer.ProducerName,
                    CategoryName: order.Supplie.Product.Category.CategoryName,
                    PackCount: order.Supplie.Product.PackCount,
                    Supplies_id: order.Supplies_id
                };

                datasource.push(obj);

            });
            model.products = datasource;
            return model;
        }

        getAvailableProductsGrouped = (date: Date, warehouseAcc: string): any => {
            this.$scope.productsGridOptions.gridView.showLoading();
            return this.accountService.getAvaliableSupplies(date, warehouseAcc).then(a => {
                this.initAvailableProductsGridDatasource(a.Result, true);
                _.forEach(a.Result, (item: any) => {
                    item.Discount *= 100;

                    if (!this.$scope.isDisabled) //todo: remove this if statement after fix ApxNumber functionality behind editable cell.
                    {
                        item.Count = 0;
                        if (!item.Product.PriceGT)
                            item.Product.PriceGT = 0;
                        item.vgLocal = 0;
                    }

                });
                return a.Result;
            });
        }

        getAvailableProductsNotGrouped = (date: Date, warehouseAcc: string): any => {
            this.$scope.productsGridOptions.gridView.showLoading();
            return this.accountService.getAvaliableSuppliesNotGrouped(date, warehouseAcc).then(a => {
                this.initAvailableProductsGridDatasource(a.Result, false);
                _.forEach(a.Result, (item: Interfaces.ISupplie) => {
                    item.Discount *= 100;
                });
                return a.Result;
            });
        }

        initAvailableProductsGridDatasource(Data: any[], isGrouped: boolean): void {

            this.$scope.IsDirty = false;
            this.$scope.realizationModel.products = Data;

            this.$scope.productsGridOptions.dataSource = new kendo.data.DataSource({
                data: Data,
                aggregate: this.getAggregates(false),
                pageSize: 50,
                schema: {
                    model: {
                        fields: this.getFields(false)
                    }
                }
            });
            this.$scope.productsGridOptions.gridView.hideLoading();
        }

        expand = (): void => {
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("choosecredit"));
                return;
            }

            var r: any;
            if (this.$scope.IsDirty) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("resetChangesConfirmation"));
                r.result.then(() => {
                    this.expandGrid();
                });
            } else {
                this.expandGrid();
            }
        }

        expandGrid = (): void => {
            this.$scope.productsGridOptions.dataSource.options.schema.model.id = 'ID';
            this.$scope.isExpanded = true;
            this.$scope.productsGridOptions.grid.showColumn('LifeDate');
            this.$scope.productsGridOptions.grid.showColumn('Code');
            this.getAvailableProductsNotGrouped(this.$scope.realizationModel.dDate, this.$scope.baseModel.credit.Acc);
        }

        collapse = (): void => {
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("choosecredit"));
                return;
            }
            var r: any;
            if (this.$scope.IsDirty) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("resetChangesConfirmation"));
                r.result.then(() => {
                    this.collapseGrid();
                });
            } else {
                this.collapseGrid();
            }
        }

        collapseGrid = () => {
            this.$scope.productsGridOptions.dataSource.options.schema.model.id = 'ProdPP_id';
            this.$scope.isExpanded = false;
            this.$scope.productsGridOptions.grid.hideColumn('LifeDate');
            this.$scope.productsGridOptions.grid.hideColumn('Code');
            this.getAvailableProductsGrouped(this.$scope.realizationModel.dDate, this.$scope.baseModel.credit.Acc);
        }

        getPartial(): string {
            return 'modals\realization.by.price.html';
        }


        saveDoc = () => {

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("choosedebetcredit"));
                return;
            }

            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("choosedebet"));
                return;
            }
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("choosecredit"));
                return;
            }

            if (this.$scope.realizationModel.products == undefined || this.$scope.realizationModel.products.length === 0) {
                this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("productlistisempty"));

                return;
            }

            var baseModel = this.$scope.baseModel;

            var doc: Interfaces.IDoc = {
                DB: baseModel.debet.Acc,
                CR: baseModel.credit.Acc,
                DDate: baseModel.crDate,
                Operid: baseModel.operId,
                NumberIn: this.$scope.realizationModel.numberIn,
                NumberOut: this.$scope.realizationModel.numberOut,
                IsProject: baseModel.isProject,
                DPay: this.$scope.realizationModel.paymentDate
            }

            if (!this.$scope.isCopied)
                doc.DocID = baseModel.docID;

            doc.Orders = [];

            var productsDatasource = this.$scope.productsGridOptions.grid.dataSource.data();

            if (this.$scope.isDisabled) {
                _.forEach(productsDatasource, (item: any) => {
                    if (item.SCount > 0) {
                        var order: Interfaces.IOrder = {
                            ID: item.ID,
                            PriceG: item.PriceG,
                            SCount: item.SCount,
                            Vat: item.Vat,
                            Discount: item.Discount / 100,
                            Vg: item.Vg,
                            Supplie: {
                                RCount: item.RCount,
                                ProdPP_id: item.ProdPP_id
                            }

                        };
                        doc.Orders.push(order);
                    }
                });
            } else {
                if (this.$scope.isExpanded) {
                    _.forEach(productsDatasource, (item: any) => {
                        if (item.changed === true && item.Count != undefined && item.Count > 0) {
                            var order: Interfaces.IOrder = {};
                            order.SCount = item.Count;
                            order.Supplies_id = item.ID,                              
                                order.Vat = item.Product.Vat,
                                order.PriceG = item.Product.PriceGT;
                            order.Discount = item.Discount / 100;
                            doc.Orders.push(order);
                        }
                    });
                } else {
                    _.forEach(productsDatasource, (item: any) => {
                        if (item.changed === true && item.Count != undefined && item.Count > 0) {
                            var order: Interfaces.IOrder = {};
                            order.SCount = item.Count;
                            order.Supplie = {
                                ProdPP_id: item.ProdPP_id
                            };
                            order.Vat = item.Product.Vat,
                                order.PriceG = item.Product.PriceGT;
                            order.Discount = item.Discount / 100;
                            doc.Orders.push(order);
                        }
                    });
                }
            }
            this.saveDocument(doc);
        };

    }
}