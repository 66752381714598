/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IBook {
        BookID?: string;
        DocsID?: string;
        OpDetID?: string;
        CurrencyID?: string;
        Currency?: ICurrency;
        Rate?: number;
        DDate?: Date;
        DB?: string;
        DBInfo?: IAccountInfo;
        CR?: string;
        CRInfo?: IAccountInfo;
        VG?: number;
        VU?: number;
        VC?: number;
        Contents?: string;
        BookNote?: string;
        NumberIn?: string;
        NumberOut?: string;
        RecStatus?: number;
        Blocking?: boolean;
        CUser?: string;
        ContractID?: number;
        CreateTime?: Date;
        BookType?: number;
    }
} 