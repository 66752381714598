/// <reference path='./_reference.ts' />
module Apex.Account {
    'use strict';

    var apexAccount = new Core.Module('account', ['apex.webUI.uiFrame', 'apex.webUI.components','dx'], Account);
    var routes1: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'account',
                url: '^/account',
                template: '<ui-view/>',
                navigation: { caption: 'ACCOUNTS', navigationClass: 'md md-attach-money' },
                childRoutes: [
                    {
                        state: 'books',
                        url: '/books',
                        controller: Account.Controllers.Books.getName(),
                        templateUrl: 'books.html',
                        navigation: {
                            caption: 'BookList',
                            actionId: '93CF837F-AED5-4832-9DD6-F303A19B5979'

                        }
                    },
                    {
                        state: 'privatecard',
                        url: '/privatecard',
                        controller: Account.Controllers.PrivateCard.getName(),
                        templateUrl: 'private.card.html',
                        navigation: {
                            caption: 'PrivateCard',
                            actionId: '027863FC-3F1C-42BF-B40B-BEFCE7A9AFB1'
                        }
                    },
                    {
                        state: 'balance',
                        url: '/balance',
                        controller: Account.Controllers.Balance.getName(),
                        templateUrl: 'balance.html',
                        navigation: {
                            caption: 'Balance',
                            actionId: '37B4DD1D-16D4-4A23-9DB3-60DA03E873B3'
                        }
                    },                    
                    {
                        state: 'acc',
                        url: '/acc',
                        controller: Account.Controllers.Accounts.getName(),
                        templateUrl: 'accounts.html',
                        navigation: {
                            caption: 'accs'
                        }
                    },
                    {
                        state: 'payroll',
                        url: '/payroll',
                        controller: Account.Controllers.PayRoll.getName(),
                        templateUrl: 'payroll.html',
                        navigation: {
                            caption: 'salary'
                        }
                    }
                ]
            }
        ];

    apexAccount.regRoutes(routes1, 'apex');

}