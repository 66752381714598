module Apex.Account.Controllers {

    export interface IServiceModel {
        addedService?: any;
        debet?: string;
        credit?: string;
        operID?: any;
        crDate?: Date;
        services?: Array<IServedModel>;
        numberIn?: string;
        numberOut?: string;
        isProject?: boolean;
        rateC?: number;
    }


    export interface IServiceScope extends IBaseBookScope {
        serviceModel: IServiceModel;
        sameProductsCounter: number;
        currencies?: any;
        translateProvider: any;
        servicesGridOptions: Components.IApxGrid;
        baseServicesLookup: Components.IApxLookup;
        isDisabled?: boolean;
        isCurrencyDisabled?: boolean;
        currencyDropdownOptions?: any;
        isDisabledRateC?: boolean;
        rateMaskOptions?: Components.IApxNumber;
        recalculation: Function;
        servicesList: Array<any>;
    }


    export class Service extends BaseBook {

        $scope: IServiceScope;

        constructor($scope: IServiceScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);
            
            $scope.serviceModel = this.toServiceModel($scope.docInfo, data.Operation.OperID);

            if ($scope.docInfo.DocID) $scope.isDisabled = true;
            else $scope.isDisabled = false;
                       
            $scope.isDisabledRateC = $scope.serviceModel.rateC === 0 ? true : false;
            

            if (!$scope.isDisabled)
                $scope.serviceModel.services = [];

            $scope.servicesGridOptions = this.servicesGridOptions($scope.serviceModel.services);
            this.accountService.getServices().then((data) => {
                $scope.servicesList = data.Result;
                $scope.baseServicesLookup.dataSource = data.Result.toDatasource();
                return data;
            });
            $scope.sameProductsCounter = 0;

            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.baseServicesLookup = this.baseServicesLookup();


            $scope.rateMaskOptions = { allowMinus: false, decimals: 4, numberType: Components.NumberType.Decimal };
            $scope.currencies = _.filter(data.Currency, (itm: any) => (itm.CurType !== 1));


            $scope.currencyDropdownOptions = {
                dataValueField: "CurrencyID",
                filter: 'contains',
                index: 0,
                dataTextField: "textTemplate",
                dataSource: $scope.currencies.toDatasource()
            };

            var left = false;
            $scope.saveDoc = this.saveDoc;
            $scope.$watch('[goodImportModel.crDate, baseModel.currencyID]', () => {
                if ($scope.baseModel.currencyID == undefined) {
                    var firstCurrency: any = _.first(this.$scope.currencies);
                    $scope.baseModel.currencyID = firstCurrency.CurrencyID;
                }
                $scope.baseComponents.credit.dataSource = _.filter($scope.creditDataSource, function (item: any) { return item.Acc.slice(-2) === $scope.baseModel.currencyID }).toDatasource();
                var dt = $scope.serviceModel.crDate;
                this.getRates(dt, $scope.baseModel.currencyID).then(a => {
                    if (left) {
                        $scope.serviceModel.rateC = a.rateC;
                    }
                    else {
                        if ($scope.docInfo.RateC === 0)
                            $scope.serviceModel.rateC = 1;
                    }
                    if (!left) left = true;

                    if ($scope.baseModel.currencyID === '00') {
                        $scope.isDisabledRateC = true;
                        $scope.servicesGridOptions.grid.hideColumn('PriceC');
                        $scope.servicesGridOptions.grid.hideColumn('Vc');
                        $scope.servicesGridOptions.grid.hideColumn('VgInfo');
                        $scope.servicesGridOptions.grid.showColumn('PriceG');
                        $scope.servicesGridOptions.grid.showColumn('Vg');
                    }
                    else {
                        $scope.isDisabledRateC = false;
                        $scope.servicesGridOptions.grid.showColumn('PriceC');
                        $scope.servicesGridOptions.grid.showColumn('Vc');
                        $scope.servicesGridOptions.grid.showColumn('VgInfo');
                        $scope.servicesGridOptions.grid.hideColumn('PriceG');
                        $scope.servicesGridOptions.grid.hideColumn('Vg');
                    }                    
                    return a;
                });
            }, false);

            this.clearModel();

            $scope.recalculation = this.recalculation;
            $scope.$watch('serviceModel.addedService.service', (service: any) => {
                if (service != undefined) {
                    this.$scope.serviceModel.addedService.count = 0;
                    this.$scope.serviceModel.addedService.sum = 0;
                    if (service.Vat != undefined) {
                        this.$scope.serviceModel.addedService.vat = Number(service.Vat);
                    }
                }
            });

            this.$timeout(() => {
                $('.k-button.k-button-icontext.k-grid-edit').click((e) => {
                    this.$scope.isBusy = true;
                    this.filterServicesDatasource();
                });
            });

            this.$timeout(() => {
                $('#multiDelete').on('click', () => {
                    this.multiDelete();
                });
                $('#currencyLookup').prop('disabled', true);
            });

        }

        private filterServicesDatasource = () => {
            var tmp = _.map(this.$scope.serviceModel.services, (item: any) => { return item.ServiceID });

            var result = _.reject(this.$scope.servicesList, (item) => {
                return _.contains(tmp, item.ServiceID);
            });
            this.$scope.baseServicesLookup.dataSource = result.toDatasource();

        }

        private recalculation = (keycode, val: string): void => {

            if (keycode == 110 || keycode == 188 || keycode == 190)
                return;

            var model: any = this.$scope.serviceModel.addedService;

            switch (val) {
                case 'count':
                case 'price':
                    {
                        this.calculateFromCountAndPrice(model);
                        break;
                    }
                case 'sum':
                    {
                        this.calculateFromSum(model);
                        break;
                    }
            }
        }

        private calculateFromCountAndPrice = (model: any) => {
            if (model.count != undefined && model.count !== 0 && model.service.Price != undefined && model.service.Price !== 0) {
                model.sum = parseFloat((model.service.Price * model.count).toFixed(4));
            } else {
                model.sum = 0;
            }

            model.count = Number(model.count);
            model.service.Price = Number(model.service.Price);
        }

        private calculateFromSum = (model: any) => {
            if (model.count != undefined && model.count !== 0) {
                if (model.sum != undefined && model.sum !== 0) {
                    model.service.Price = parseFloat((model.sum / model.count).toFixed(4));
                } else {
                    model.service.Price = 0;
                }
                model.sum = Number(model.sum);
                model.Icount = Number(model.count);
            }
        }
        private clearModel = () => {
            this.$scope.serviceModel.addedService = {
                serviceId: '',
                count: 0,
                service: { Name: '' },
                sum: 0
            };
        }

        multiDelete = () => {
            var selectedRows = this.$scope.servicesGridOptions.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: IServedModel = this.$scope.servicesGridOptions.grid.dataItem(item).toJSON();
                    for (var i = 0; i < this.$scope.serviceModel.services.length; i++) {
                        if (this.$scope.serviceModel.services[i].ID === model.ID) {
                            this.$scope.serviceModel.services.splice(i, 1);
                            break;
                        }
                    }
                });
                this.$scope.servicesGridOptions.grid.dataSource.data(this.$scope.serviceModel.services);

                if (this.$scope.serviceModel.services.length === 0 && !this.$scope.isDisabled)
                    this.$scope.isCurrencyDisabled = false;

                this.makeFormDirty();
            });
        }

        private baseServicesLookup(): Components.IApxLookup {
            var gridOptions: Components.IApxLookup =
                {
                    columns: [
                        { field: 'Code', width: 80, titleTranslated: 'code' },
                        { field: 'Name', width: 250, titleTranslated: 'nameCaption' },
                        { field: 'Price', width: 60, titleTranslated: 'price' },
                        { field: 'Vat', width: 50, titleTranslated: 'vat', format: Core.Kendo.Format.Percent }
                    ],

                    dataSource: new kendo.data.DataSource({
                        schema: {
                            model: {
                                fields: {
                                    'Code': { type: "string" },
                                    'Name': { type: "string" },
                                    'Price': { type: "number" },
                                    'Vat': { type: "number" }
                                }
                            }
                        }
                    }),
                    valueText: 'Name',
                    valueID: 'ServiceId'
                };
            return gridOptions;
        }

        private servicesGridOptions(datasource: Array<IServedModel>): Components.IApxGrid {
            var gridOptions: Components.IApxGrid =
                {
                    columns: this.serviceGridColumns,                
                    dataSource: this.servicesDatasource(datasource),
                    settings:
                    {
                        selectable: "multiple",
                        gridButtons: {
                            multiDelete: { enabled: true },
                            add: { enabled: true },
                            exportToExcell: true
                        },
                        height: 500,
                        editble: {
                            popupTemplateID: 'service_popup_editor',
                            popupWidth: 700
                        }
                    },
                    isLookup: false,
                    parentScope: this.$scope,
                    gridEvents:
                    [
                        this.getAddEvent(),
                        this.getCancelEvent(),
                        this.getExportToExcelEvent()                     
                    ]
                };
            return gridOptions;
        }

        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        exportToExcelFunctionality = (e) => {
            var rows = e.workbook.sheets[0].rows;
            for (var ri = 0; ri < rows.length; ri++) {
                var row = rows[ri];
                if (row.type === "header") {
                    _.forEach(row.cells, (item: any) => {
                        item = this.initExcelCaptions(item);
                    });
                }
                
                if (row.type === "group-footer" || row.type === "footer") {
                    for (var ci = 0; ci < row.cells.length; ci++) {
                        var cell = row.cells[ci];
                        if (cell.value) {
                            cell.value = '';
                        }
                    }
                }
            }
        }

        initExcelCaptions = (column: any): any => {
            if (column.value.includes("'nameCaption'")) {
                column.value = this.$scope.translateProvider.instant('nameCaption');
                return column;
            }
            if (column.value.includes("'code'")) {
                column.value = this.$scope.translateProvider.instant('code');
                return column;
            }
            if (column.value.includes("'Count'")) {
                column.value = this.$scope.translateProvider.instant('Count');
                return column;
            }
            if (column.value.includes("'PriceG'")) {
                column.value = this.$scope.translateProvider.instant('PriceG');
                return column;
            }
            if (column.value.includes("'sumG'")) {
                column.value = this.$scope.translateProvider.instant('sumG');
                return column;
            }
            if (column.value.includes("'vat'")) {
                column.value = this.$scope.translateProvider.instant('vat');
                return column;
            }
            return column;
        }


        private getAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addEventFunctionality
                };
            return addEvent;
        }
        addEventFunctionality = (e) => {
            if (e.model != undefined && !e.model.isNew()) {
                var focusedRowModel: IServedModel = e.model;

                this.calculationOnEditEvent(e, e.values, focusedRowModel);
                               
                e.sender.dataSource.sync();
                this.makeFormDirty();

            }
            else {
                if (this.$scope.serviceModel.addedService.service == undefined ||
                    this.$scope.serviceModel.addedService.service.Name === undefined ||
                    this.$scope.serviceModel.addedService.service.Name.trim().length === 0
                ) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', 'ყურადღება', 'გთხოვთ აირჩიოთ მომსახურება'));
                    e.preventDefault();
                    return;
                }
                if (this.$scope.serviceModel.addedService.sum == undefined ||
                    this.$scope.serviceModel.addedService.sum === 0) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', 'ყურადღება', 'ნულოვანი ჯამით მნიშვნელობის დამატება ვერ ხერხდება!!!'));
                    e.preventDefault();
                    return;
                }
                this.initSomeProperties(this.$scope.serviceModel.addedService);
                this.$scope.serviceModel.services.unshift(this.$scope.serviceModel.addedService);
                this.makeFormDirty();
                if (!this.$scope.isCurrencyDisabled)
                    this.$scope.isCurrencyDisabled = true;
                ++this.$scope.sameProductsCounter;
                this.$scope.servicesGridOptions.grid.dataSource.read();
                this.clearModel();

                this.$timeout(() => { this.$scope.isBusy = false; });
            }
        }
        initSomeProperties = (currentService: IServedModel): void => {
            currentService.Name = this.$scope.serviceModel.addedService.service.Name;
            currentService.Code = this.$scope.serviceModel.addedService.service.Code;
            currentService.ICount = this.$scope.serviceModel.addedService.count;
            currentService.ServiceID = this.$scope.serviceModel.addedService.service.ServiceID;
            currentService.Vat = this.$scope.serviceModel.addedService.service.Vat;
            currentService.ID = this.$scope.serviceModel.addedService.service.ServiceID + '_' + this.$scope.sameProductsCounter;
            if (this.$scope.baseModel.currencyID === '00') {
                currentService.PriceG = this.$scope.serviceModel.addedService.service.Price;
                currentService.Vg = this.$scope.serviceModel.addedService.sum;
            } else {
                currentService.PriceC = this.$scope.serviceModel.addedService.service.Price;
                currentService.Vc = this.$scope.serviceModel.addedService.sum;
                currentService.VgInfo = parseFloat((currentService.Vc * this.$scope.serviceModel.rateC).toFixed(4));
            }
        }


        private calculationOnEditEvent(e: any, changedValues: any, model: IServedModel): void {
           
            switch (null) {
                case changedValues.ICount:
                    e.preventDefault();
                    break;
                case changedValues.Vg:
                    e.preventDefault();
                    break;
                case changedValues.Vc:
                    e.preventDefault();
                    break;
                case changedValues.PriceC:
                    e.preventDefault();
                    break;
                case changedValues.PriceG:
                    e.preventDefault();
                    break;
            }

            if (this.$scope.baseModel.currencyID === '00') {
                if (changedValues.ICount != undefined && changedValues.ICount != null) {
                    if (changedValues.ICount !== 0 && model.PriceG != undefined && model.PriceG !== 0) {
                        model.Vg = parseFloat((model.PriceG * changedValues.ICount).toFixed(4));
                    }
                    else {
                        model.Vg = 0;
                    }
                    return;
                }

                if (changedValues.PriceG != undefined) {
                    if (model.ICount != undefined && model.ICount !== 0 && changedValues.PriceG !== 0) {
                        model.Vg = parseFloat((changedValues.PriceG * model.ICount).toFixed(4));
                    } else {
                        model.Vg = 0;
                    }
                    return;
                }

                if (changedValues.Vg != undefined) {
                    if (model.ICount != undefined && Number(model.ICount) != 0) {
                        model.PriceG = parseFloat((changedValues.Vg / model.ICount).toFixed(4));
                    } else {
                        e.preventDefault();
                    }
                    return;
                }
            } else {

                if (changedValues.ICount != undefined) {
                    if (changedValues.ICount !== 0 && model.PriceC != undefined && model.PriceC !== 0) {
                        model.Vc = parseFloat((model.PriceC * changedValues.ICount).toFixed(4));
                        model.VgInfo = parseFloat((model.Vc * this.$scope.serviceModel.rateC).toFixed(4));
                    } else {
                        model.Vc = 0;
                        model.VgInfo = 0;
                    }
                    return;
                }

                if (changedValues.PriceC != undefined) {
                    if (model.ICount != undefined && model.ICount !== 0 && changedValues.PriceC !== 0) {
                        model.Vc = parseFloat((changedValues.PriceC * model.ICount).toFixed(4));
                        model.VgInfo = parseFloat((model.Vc * this.$scope.serviceModel.rateC).toFixed(4));
                    } else {
                        model.Vc = 0;
                        model.VgInfo = 0;
                    }
                    return;
                }

                if (changedValues.Vc != undefined) {
                    if (model.ICount != undefined && Number(model.ICount) != 0) {
                        model.PriceC = parseFloat((changedValues.Vc / model.ICount).toFixed(4));
                        model.VgInfo = parseFloat((changedValues.Vc * this.$scope.serviceModel.rateC).toFixed(4));
                    } else {
                        e.preventDefault();
                    }
                    return;
                }
            }
        }

        servicesDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate:
                [
                    { field: "Vg", aggregate: "sum" },
                    { field: "Vc", aggregate: "sum" },
                    { field: "PriceG", aggregate: "sum" },
                    { field: "PriceC", aggregate: "sum" },
                    { field: "VgInfo", aggregate: "sum" },
                    { field: "Code", aggregate: "count" },
                    { field: "ICount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "ID",
                        fields: {
                            'Code': { editable: false, type: "string" },
                            'Name': { editable: false, type: "string" },
                            'Vat': { editable: false, type: "number"},
                            'VgInfo': { editable: false, type: "number" },
                            'ICount': { type: "number" },
                            'PriceC': { type: "number" },
                            'PriceG': { type: "number" },
                            'Vc': { type: "number" },
                            'Vg': { type: "number" }
                        }
                    }
                }
            });
            return ds;
        }

        toServiceModel(docInfo: Interfaces.IDoc, operid: any) {
            var serviceModel: IServiceModel = {};
            if (!!docInfo.DocID) {
                serviceModel.crDate = this.$scope.baseModel.crDate;
                serviceModel.numberIn = docInfo.NumberIn;
                serviceModel.numberOut = docInfo.NumberOut;
                serviceModel.debet = docInfo.DB;
                serviceModel.credit = docInfo.CR;
                serviceModel.rateC = docInfo.RateC;
                serviceModel.isProject = docInfo.IsProject;
                serviceModel.services = [];
                _.forEach(docInfo.Served, (item: Interfaces.IServed) => {
                    item.Vat = Number(item.Vat);
                    serviceModel.services.push(
                        {
                            ID: item.ID,
                            ServiceID: item.ServiceID,
                            Vg: item.Vg,
                            VgInfo: item.Vg,
                            Name: item.Service.Name,
                            Code: item.Service.Code,
                            Vc: item.Vc,
                            PriceC: item.PriceC,
                            PriceG: item.PriceG,
                            ICount: item.ICount,
                            Vat: item.Vat,
                            Discount: item.Discount
                        }
                    );
                });

            } else {
                serviceModel.crDate = new Date();
            }
            serviceModel.operID = operid;
            return serviceModel;
        }
        private getCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.cancelEventFunctionality
                };
            return cancelEvent;
        }

        cancelEventFunctionality = (e: kendo.ui.GridEvent) => {
            this.clearModel();
            this.$timeout(() => { this.$scope.isBusy = false; });// todo Global ESC event in dialogs
        }


        private serviceGridColumns: any =
        [
            { field: 'Name', titleTranslated: 'nameCaption' },
            { field: 'Code', titleTranslated: 'code', width: 150, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Code ? data.Code.count : 0) #' },
            { field: 'ICount', titleTranslated: 'Count', width: 140, format: Core.Kendo.Format.Price4, editable: true },
            { field: 'PriceC', titleTranslated: 'PriceC', width: 140, format: Core.Kendo.Format.Price4, editable: true, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.PriceC && data.PriceC.sum ? parseFloat(data.PriceC.sum).toFixed(4) : 0) #' },
            { field: 'PriceG', titleTranslated: 'PriceG', width: 140, format: Core.Kendo.Format.Price4, editable: true, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.PriceG && data.PriceG.sum ? parseFloat(data.PriceG.sum).toFixed(4) : 0) #' },
            { field: 'Vc', titleTranslated: 'SumC', width: 140, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Vc && data.Vc.sum ? parseFloat(data.Vc.sum).toFixed(4) : 0) #' },
            { field: 'Vg', titleTranslated: 'sumG', width: 140, editable: true, format: Core.Kendo.Format.Price4,footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Vg && data.Vg.sum ? parseFloat(data.Vg.sum).toFixed(4) : 0) #' },
            { field: 'VgInfo', titleTranslated: 'sumG', width: 140, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VgInfo && data.VgInfo.sum ? parseFloat(data.VgInfo.sum).toFixed(4) : 0) #' },
            { field: 'Vat', titleTranslated: 'vat', width: 140, format: Core.Kendo.Format.Percent }

        ];

        saveDoc = () => {
            var modalBook = this.$scope.formScope.modalBook;

            if (modalBook.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი !');
                return;
            }
            var model = this.$scope.serviceModel;
            var baseModel = this.$scope.baseModel;

            if (this.$scope.baseModel.debet == undefined && this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', 'ყურადღება!', 'გთხოვთ აირჩიოთ დებეტი და კრედიტი!');
                return;
            }
            if (this.$scope.baseModel.debet == undefined) {
                this.toaster.pop('error', 'ყურადღება!', 'გთხოვთ აირჩიოთ დებეტი!');
                return;
            }
            if (this.$scope.baseModel.credit == undefined) {
                this.toaster.pop('error', 'ყურადღება!', 'გთხოვთ აირჩიოთ კრედიტი!');
                return;
            }

            var doc: any = {
                DB: this.$scope.baseModel.debet.Acc,
                CR: this.$scope.baseModel.credit.Acc,
                DDate: model.crDate,
                Operid: model.operID,
                NumberIn: model.numberIn,
                NumberOut: model.numberOut,
                RateC: model.rateC,
                Currency_id: baseModel.currencyID,
                IsProject: model.isProject
            }
            if (!this.$scope.isCopied)
                doc.DocID = this.$scope.baseModel.docID;

            doc.Served = [];

            var services = this.$scope.servicesGridOptions.grid.dataSource.data();

            _.forEach(services, (item: IServedModel) => {

                var service: Interfaces.IServed = {};
                if (baseModel.currencyID === '00') {
                    //  Gel
                    service.Vg = item.Vg;
                    service.PriceG = item.PriceG;
                    service.PriceC = 0;
                }
                else {
                    // Currency
                    service.Vc = item.Vc;
                    service.PriceC = item.PriceC;
                    service.PriceG = 0;
                }
                service.ServiceID = item.ServiceID;
                service.ICount = item.ICount;
                service.Vat = Number(item.Vat);
                service.Discount = item.Discount;
                if (item.ID.length > 10)
                    service.ID = null;
                else {
                    if (!this.$scope.isCopied)
                        service.ID = item.ID;
                }
                doc.Served.push(service);
            });

            if (doc.Served.length === 0) {
                this.toaster.pop('error', 'ყურადღება!', 'მომსახურების სია ცარიელია!');
                return;
            }

           

            this.saveDocument(doc);
        }

        getPartial(): string {
            return 'modalsservice.html';
        }

    }
}