/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {
    export interface ReferenceInAccountingModalScope extends angular.IScope {
        formName: String,
        events: {
            close: Function,
            save: Function
        },
        model: {
            dataSource: {
                operations?: any,
                acc?:Interfaces.IAccountInfo[];
            }
            OperId?: string,
            dataItem?: any,
            FormId?: string,
            selectedAcc?:any
        },
        disabled: {
            disableSave: boolean
        },
        Components: {
            accDropDown: {
                visible?: boolean
            }
        },
        processCompleted:number

    }


    export class ReferenceInAccountingModal extends Core.Controller {
        /** data is {selectedItem:item, FormId:"1100"} on {selectedItem:item, FormId:"1101"} */
        public static $inject = [Globals.scope, Globals.translate, 'data', 'accountService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: ReferenceInAccountingModalScope, private $translate, data: any, private accountService: Apex.Account.Services.Account,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, ReferenceInAccountingModal.$inject);
            this.initialization($scope);
            if (data.FormId == "1100") {
                $scope.formName = "დარიცხვის ";
                $scope.Components.accDropDown.visible = false;
            } else {
                $scope.Components.accDropDown.visible = true;
                $scope.formName = "გაცემის ";
                this.getAccInfo();
                $timeout(() => {
                    $(".modal-body").css("height", "90px");
                });
            }

            $scope.formName += "უწყისის ბუღალტერიაში ასახვა";
            $scope.model.dataItem = data.selectedItem;
            $scope.model.FormId = data.FormId;

            /// formId უნდა შემოვიდეს data-ში 1100 ან 1101
            this.getOperations(data.FormId);
            $scope.$watch("model.OperId", (val) => {
                if (val)
                    this.$scope.disabled.disableSave = false;
                else
                    this.$scope.disabled.disableSave = true;
            });
        }



        initialization = (scope: ReferenceInAccountingModalScope) => {
            scope.disabled = {
                disableSave: true
            };
            scope.model = {
                dataSource: {
                    acc: new Array<Interfaces.IAccountInfo>()
                },
                OperId: null
            };
            scope.events = {
                close: this.closeForm,
                save: this.save
            };
            scope.Components = {
                accDropDown: {
                    visible: false
                }
            }
        }
        getAccInfo = () => {
            // kendo.ui.progress($('.panel-body'), true);
            this.$scope.processCompleted=0;

            this.accountService.getAccounts(1).then(r => {
                this.$scope.model.dataSource.acc=this.$scope.model.dataSource.acc.concat(r.Result);

                this.$scope.processCompleted+=1;
                // if(this.$scope.processCompleted==2) kendo.ui.progress($('.panel-body'), false);
            }, er => {
                // kendo.ui.progress($('.panel-body'), false);
            });
            this.accountService.getAccounts(2).then(r => {
                this.$scope.model.dataSource.acc=this.$scope.model.dataSource.acc.concat(r.Result);

                this.$scope.processCompleted+=1;
                // if(this.$scope.processCompleted==2) kendo.ui.progress($('.panel-body'), false);
            }, er => {
                // kendo.ui.progress($('.panel-body'), false);
            });

        }
        getOperations = (formid) => {
            return this.accountService.getOpers(formid).then(r => {
                if (r) {
                    this.$scope.model.dataSource.operations = r.Result;
                }
            })
        }
        closeForm = (value) => {
            this.$uibModalInstance.close(value);
        }

        save = () => {


            if (!this.$scope.model.OperId)
                return;
            if(this.$scope.model.FormId != "1100" && !this.$scope.model.selectedAcc) 
                return ;

            if (this.$scope.model.FormId == "1100") {
                this.accountService.recordPayRollInTheAccounting(this.$scope.model.dataItem.RollId, this.$scope.model.OperId).then(r => {
                    if (r.success == undefined) {
                        this.toaster.pop("success", "შეტყობინება", "ბუღალტერიაში ასახვა წარმატებით დასრულდა");
                        this.closeForm(true);
                    }
                });
            }
            else {
                
                this.accountService.RecordPayedPayRollInTheAccounting(this.$scope.model.dataItem.Id, this.$scope.model.OperId,this.$scope.model.selectedAcc).then(r => {
                    if (r.success == undefined) {
                        this.toaster.pop("success", "შეტყობინება", "ბუღალტერიაში ასახვა წარმატებით დასრულდა");
                        this.closeForm(true);
                    }
                });
            }
        }
    }
}