/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface ICurrency {
        CurrencyID?: string;
        Name?: string;
        Code?: string;
        CurType?: number;
        ReCalc?: boolean;
    }
} 