/// <reference path="./../_reference.ts"/>
module Apex.Account.Interfaces {
    "use strict";
    export interface IServed {
        ID?: string;
        Book_id?: string;
        Vg?: number;
        Vu?: number;
        Vc?: number;
        Vat?: number;
        ServiceID?: string;
        Service?: IService;
        Discount?: number;
        ICount?: number;
        PriceG?: number;
        PriceU?: number;
        PriceC?: number;
    }
} 