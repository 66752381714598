/// <reference path="./../_reference.ts"/>
/// <reference path='../../../Apex.WebUI.Core/app/Interfaces/IServiceInterface.ts' />
module Apex.Account.Interfaces {

    
export interface GetCrmBranchPersonsResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;

    // @DataMember(Order=2)
    Result?: CrmBranchPersonsView[];
}

export interface CrmBranchPersonsView extends Apex.Core.Interfaces.ISelected
{
    // @DataMember(Order=1)
    BranchPersonsId?: number;

    // @DataMember(Order=2)
    BranchId?: number;

    // @DataMember(Order=3)
    PersonId?: number;

    // @DataMember(Order=4)
    CompanyId?: number;

    // @DataMember(Order=5)
    CompanyName?: string;

    // @DataMember(Order=6)
    IsOwn?: boolean;

    // @DataMember(Order=7)
    IsPartner?: boolean;

    // @DataMember(Order=8)
    IsSupplier?: boolean;

    // @DataMember(Order=9)
    IsCustomer?: boolean;

    // @DataMember(Order=10)
    PName?: string;

    // @DataMember(Order=11)
    FirstName?: string;

    // @DataMember(Order=12)
    LastName?: string;

    // @DataMember(Order=13)
    Spend?: boolean;

    // @DataMember(Order=14)
    StafStatus?: number;

    // @DataMember(Order=15)
    Salary?: number;

    // @DataMember(Order=16)
    DepId?: number;

    // @DataMember(Order=17)
    DepName?: string;

    // @DataMember(Order=18)
    PostId?: number;

    // @DataMember(Order=19)
    PostName?: string;
     // @DataMember(Order=20)
    NoIncomeTax?: boolean;

    // @DataMember(Order=21)
    TaxAmount?: number;

    // @DataMember(Order=22)
    TaxPercen?: number;
    // @DataMember(Order=23)
    bankAcc?:string;
    // @DataMember(Order=24)    
    BankId?:string;
    // @DataMember(Order=25)    
    PersonSN?:String
}


    // @DataContract
    export interface IPayRollDetView extends Core.Interfaces.ISelected {
        // @DataMember(Order=1)
        Id?: number;

        // @DataMember(Order=2)
        RollId?: number;

        // @DataMember(Order=3)
        PersonId?: number;

        // @DataMember(Order=4)
        PostId?: number;

        // @DataMember(Order=5)
        DepId?: number;

        // @DataMember(Order=6)
        BranchId?: number;

        // @DataMember(Order=7)
        Salary?: number;

        // @DataMember(Order=8)
        TaxPersent?: number;

        // @DataMember(Order=9)
        Amount?: number;

        // @DataMember(Order=10)
        TaxAmount?: number;

        // @DataMember(Order=11)
        PersonSN?: string;

        // @DataMember(Order=12)
        PName?: string;

        // @DataMember(Order=13)
        FirstName?: string;

        // @DataMember(Order=14)
        LastName?: string;

        // @DataMember(Order=15)
        BranchName?: string;

        // @DataMember(Order=16)
        DepName?: string;

        // @DataMember(Order=17)
        PostName?: string;

        StafStatus?:number;
        
        ExistingExemptIncomeAmount?:number;
        TotalPayedSalary?:number;
        RemainingAmount?:number;
        TotalSalary?:number;


    }

    export interface IPayRoll {
        RollId?: number;
        RollType?: number;
        PayRollTypeName?:string;
        DDate?: Date;
        RollNum?: string;
        Content?: string;
        CrTime?: Date;
        CUser?: string;
        Status?:any;
        RollPeriod?:Date;
    }
    export interface IPayRollView extends IPayRoll{
        Salary?:number;
        Amount?:number;
        TaxAmaunt?:number;
    }

    export interface IPayRollDet extends Apex.Core.Interfaces.ISelected {
        Id?: number;
        RollId?: number;
        PersonId?: number;
        PostId?: number;
        DepId?: number;
        BranchId?: number;
        Salary?: number;
        TaxPersent?: number;
        Amount?: number;
        TaxAmount?: number;

    }

    export interface IPayedPayroll {
        Id?: number;
        RollId?: number;
        DDate?: Date;
        PayNum?: number;
        Content?: string;
        CUser?: string;
        CrTime?: Date;
        Status?:any;
        DocId?:string;
    }

    export interface IPayedPayrollView extends IPayedPayroll{
        Amount?:number;
        TaxAmount?:number;
        OtherWithholdAmount?:number;

    }
    export class GetPayedPayRollDetResponse {
        // @DataMember(Order=1)
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        // @DataMember(Order=2)
        Result: PayedPayRollDetView[];
    }
    export class PayedPayRollDetView {
        // @DataMember(Order=1)
        PayDId: number;

        // @DataMember(Order=2)
        PayId: number;

        // @DataMember(Order=3)
        RollDId: number;

        // @DataMember(Order=4)
        Amount: number;

        // @DataMember(Order=5)
        TaxAmount: number;

        // @DataMember(Order=6)
        OtherWithholdAmount: number;

        // @DataMember(Order=7)
        RollId: number;

        // @DataMember(Order=8)
        PersonId: number;

        // @DataMember(Order=9)
        PostId: number;

        // @DataMember(Order=10)
        DepId: number;

        // @DataMember(Order=11)
        BranchId: number;

        // @DataMember(Order=12)
        Salary: number;

        // @DataMember(Order=13)
        TaxPercent: number;

        // @DataMember(Order=14)
        PersonSN: string;

        // @DataMember(Order=15)
        PName: string;

        // @DataMember(Order=16)
        FirstName: string;

        // @DataMember(Order=17)
        LastName: string;

        // @DataMember(Order=18)
        BranchName: string;

        // @DataMember(Order=19)
        DepName: string;

        // @DataMember(Order=20)
        PostName: string;
        // @DataMember(Order=21)
        PayType :number;
        // @DataMember(Order=22)
        BankAcc:string;
    }

    // @DataContract
    export class PayedPayroll {
        // @DataMember(Order=1)
        Id: number;

        // @DataMember(Order=2)
        RollId: number;

        // @DataMember(Order=3)
        DDate: string;

        // @DataMember(Order=4)
        PayNum: string;

        // @DataMember(Order=5)
        Content: string;

        // @DataMember(Order=6)
        CUser: string;

        // @DataMember(Order=7)
        CrTime: string;
    }

    export interface IPayedPayrollDet {
        Id?: number;
        PayId?: number;
        RollDId?: number;
        Amount?: number;
        TaxAmount?: number;
        OtherWithholdAmount?: number;
        PayType?: number;
        BankAcc?: string;
        DocId?:string;
    }
    export interface ISavePayRollDetResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: number;
    }

    export interface ISavePayRollResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: number;
    }

    export interface IGetPayedPayRollDetResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: IPayedPayrollDet[];
    }

    export interface IGetPayedPayRollResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: IPayedPayroll[];
    }

    export interface IGetPayRollResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: IPayRoll[];
    }
    export interface IGetPayRollDetResponse {
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;
        Result: IPayRollDetView[];
    }


}

