/// <reference path='../../_reference.ts' />
module Apex.Account.Controllers {

    export interface IInnerMovementModel {
        orders?: Array<IOrderModel>;
    }

    export interface IOrderModel {
        bCode?: string;
        packCount?: number;
        inCode?: string;
        unit?: string;
        lifeDate?: string;
        count?: number;
        vg?: number;
        rCount?: number;
        vat?: number;
        prodName?: string;
        orderID?: string;
        prodPP?: string;
    }

    export interface IInnerMovementScope extends IBaseBookScope {
        translateProvider: any;
        movementModel: IInnerMovementModel;
        isDisabled?: boolean;
        components?: { pProductLookup: Components.IApxLookup, ordersOptions: Components.IApxGrid };
        countMaskOptions: Components.IApxNumber;
        productEdit: {
            sameProductsCounter?: number;
            product?: Interfaces.ISupplie;
            recalcCount?(type): void;
            bCount?: number;
            count?: number;
        };

    }


    export class InnerMovement extends BaseBook {

        $scope: IInnerMovementScope;

        constructor($scope: IInnerMovementScope, data: any, accountService: Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, data, accountService, $uibModalInstance, toaster, $http);

            $scope.movementModel = this.toInnerMovementModel($scope.docInfo, data.Operation.OperID);
            $scope.translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);          
            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
            if ($scope.docInfo.DocID) $scope.isDisabled = true;
            $scope.saveDoc = this.saveDoc;

            var recalcCount = () => {
                if ($scope.productEdit.product != undefined) {
                    var count = $scope.productEdit.count;
                    var packBCount = $scope.productEdit.product.Product.PackBCount;
                    var bCount = count / packBCount;
                    $scope.productEdit.bCount = parseFloat(bCount.toFixed(4));
                }

            }

            $scope.productEdit = {
                recalcCount: recalcCount,
                sameProductsCounter: 0
            };

            $scope.components = {
                pProductLookup: this.bcDropdownOptions(),
                ordersOptions: this.ordersOption($scope.movementModel.orders)
            };

            $scope.$watch('productEdit.product.Product', (product: any) => {
                this.$scope.productEdit.bCount = 0;
                if (product != undefined) {
                    if (product.PackCount != undefined) {
                        if (product.PackCount === 1) {
                            $scope.countMaskOptions = { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false };
                        } else {
                            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
                        }
                    }
                }
            });

            $scope.$watch('[baseModel.crDate, baseModel.credit.Acc]', (val) => {
                if (val[0] !== undefined && val[1] !== undefined && val[1] !== null) {

                    if (!$scope.docInfo.DocID)
                        $('#addProduct').removeClass("k-state-disabled");
                    this.getAvailableProducts(val[0], val[1]).then(a => {
                        $scope.components.pProductLookup.dataSource.data(a);
                    });
                }
            });

            this.$timeout(() => {
                if (!$scope.docInfo.DocID)
                    $('#addProduct').addClass("k-state-disabled");

                $('#addProduct').click(() => {
                    this.$scope.isBusy = true;
                });
                $('#multiDelete').on('click', () => {
                    this.multiDelete();
                });
            });
        }


        ordersDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate:
                [
                    { field: "count", aggregate: "sum" },
                    { field: "vg", aggregate: "sum" }
                ],

                schema: {
                    model: {
                        id: "orderID",
                        fields: {
                            bCode: { editable: false, type: 'string' },
                            prodName: { editable: false, type: 'string' },
                            unit: { editable: false, type: 'string' },
                            inCode: { editable: false, type: 'string' },
                            lifeDate: { editable: false, type: 'date' },
                            vg: { editable: false, type: 'number' },
                            count: { type: 'number' },
                            rCount: { type: 'number', editable: false }
                        }
                    }
                }
            });
            return ds;
        }

        multiDelete = () => {
            var selectedRows = this.$scope.components.ordersOptions.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.$scope.translateProvider.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }
            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: IOrderModel = this.$scope.components.ordersOptions.grid.dataItem(item).toJSON();
                    for (var i = 0; i < this.$scope.movementModel.orders.length; i++) {
                        if (this.$scope.movementModel.orders[i].orderID === model.orderID) {
                            this.$scope.movementModel.orders.splice(i, 1);
                            break;
                        }
                    }
                });
                this.$scope.components.ordersOptions.grid.dataSource.data(this.$scope.movementModel.orders);
                this.makeFormDirty();
            });
        }

        ordersOption = (dataArray) => {
            var ordersOptions: Components.IApxGrid = {
                columns: this.ordersColumns,             
                settings: {
                    height: 500,
                    selectable: "multiple",
                    gridButtons: {
                        add: {
                            enabled: true, id: 'Product'
                        },
                        multiDelete: {
                            enabled: true
                        },
                        exportToExcell: true
                    },
                    editble: { popupTemplateID: 'popupEditor', popupWidth: 600 }
                },
                gridEvents: [
                    { eType: Components.EventType.Save, eFunction: this.ordersSaveEvent },
                    {
                        eType: Components.EventType.Cancel, eFunction: () => {
                            this.cleanPopupModel();
                        }
                    },
                    this.getSaveEvent(),
                    this.getEditEvent(),
                    this.getExportToExcelEvent()
                ],
                dataSource: this.ordersDatasource(dataArray),
                parentScope: this.$scope
            };
            return ordersOptions;
        }


        getSaveEvent(): Components.IEvent {
            var saveEventType: Components.EventType = Components.EventType.Save;
            var saveEvent: Components.IEvent =
                {
                    eType: saveEventType,
                    eFunction: this.saveEventFunctionality
                };
            return saveEvent;
        }

        saveEventFunctionality = (e: any) => {
            if (e.model && e.values.count)
            {
                if (e.values.count > (e.model.count + e.model.rCount)) {
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        this.toaster.pop('error', this.$scope.translateProvider.instant("Attention"), this.$scope.translateProvider.instant("notenoughremains"));
                        e.preventDefault();
                        return;
                    });
                }
                else
                {
                    e.model.rCount = (e.model.count + e.model.rCount) - e.values.count;
                }               
            }
        }

        private ordersSaveEvent = (e) => {
            if (e.model != undefined && !e.model.isNew()) {
                var focusedRowModel: IOrderModel = e.model;
                if (e.values.count === null) {
                    e.preventDefault();

                }
                for (var i = 0; i < this.$scope.movementModel.orders.length; i++) {
                    if (this.$scope.movementModel.orders[i].orderID === focusedRowModel.orderID) {
                        this.$scope.movementModel.orders[i] = focusedRowModel;
                        e.sender.dataSource.read();
                        this.makeFormDirty();
                        break;
                    }
                }

            } else {
                var product = this.$scope.productEdit.product;
                var count = Number(this.$scope.productEdit.count);
                if (typeof product === 'undefined') {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseProductAlert')));
                    e.preventDefault();
                    return;
                }

                if (isNaN(count) || count === 0) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'გთხოვთ შეიყვანოთ დადებითი რაოდენობა!'));
                    e.preventDefault();
                    return;
                }

                var orderModel: IOrderModel = {
                    bCode: product.Product.BCode,
                    inCode: product.Product.InCode,
                    prodName: product.Product.Name,
                    lifeDate: product.LifeDate,
                    unit: product.Product.Unit,
                    count: count,
                    packCount: product.Product.PackCount,
                    vg: product.Vg,
                    prodPP: product.ProdPP_id,
                    orderID: product.ProdPP_id + '_' + this.$scope.productEdit.sameProductsCounter
                };
                ++this.$scope.productEdit.sameProductsCounter;
                this.$scope.movementModel.orders.unshift(orderModel);
                this.$scope.components.ordersOptions.grid.dataSource.read();
                this.$scope.components.ordersOptions.grid.refresh();
                this.makeFormDirty();
            }
            this.cleanPopupModel();
        }


        private cleanPopupModel() {
            this.$scope.productEdit.bCount = 0;
            this.$scope.productEdit.count = 0;
            delete this.$scope.productEdit.product;
            this.$scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
            this.$timeout(() => { this.$scope.isBusy = false; });
        }
        exportToExcelFunctionality = (e) => {
            var r = e.workbook;
            if (r != undefined) {
                var rows = e.workbook.sheets[0].rows;
                for (var ri = 0; ri < rows.length; ri++) {
                    var row = rows[ri];
                    if (row.type === "header") {
                        _.forEach(row.cells, (item: any) => {
                            item = this.initExcelCaptions(item);
                        });
                    }

                    if (row.type === "group-footer" || row.type === "footer") {
                        for (var ci = 0; ci < row.cells.length; ci++) {
                            var cell = row.cells[ci];
                            if (cell.value) {
                                cell.value = '';
                            }
                        }
                    }
                }
            }
        }

        getExportToExcelEvent(): Components.IEvent {
            var exportToExcelEventType: Components.EventType = Components.EventType.ExportToExcel;
            var exportEvent: Components.IEvent =
                {
                    eType: exportToExcelEventType,
                    eFunction: this.exportToExcelFunctionality
                };
            return exportEvent;
        }


        initExcelCaptions = (column: any): any => {
            switch (column.value) {
                case 'bCode':
                    column.value = this.$scope.translateProvider.instant('barcode');
                    break;
                case 'prodName':
                    column.value = this.$scope.translateProvider.instant('product');
                    break;
                case 'categoryName':
                    column.value = this.$scope.translateProvider.instant('categoryName');
                    break;
                case 'producer':
                    column.value = this.$scope.translateProvider.instant('producer');
                    break;
                case 'unit':
                    column.value = this.$scope.translateProvider.instant('unit');
                    break;
                case 'lifeDate':
                    column.value = this.$scope.translateProvider.instant('lifeDate');
                    break;
                case 'markerCode':
                    column.value = this.$scope.translateProvider.instant('goodMarkerCode');
                    break;
                case 'count':
                    column.value = this.$scope.translateProvider.instant('Count');
                    break;
                case 'vg':
                    column.value = this.$scope.translateProvider.instant('vg');
                    break;
                case 'inCode':
                    column.value = this.$scope.translateProvider.instant('inCode');
                    break;
            }
            return column;
        }

        bcDropdownOptions = () => {
            var pProductsLookup: Components.IApxLookup = {
                columns: [
                    { field: 'Product.BCode', width: 120, titleTranslated: 'barcode' },
                    { field: 'Product.Name', titleTranslated: 'product' }
                ],
                dataSource: new kendo.data.DataSource({
                    schema: {
                        model: {
                            fields: {
                                'Product.BCode': { type: 'string' },
                                'Product.Name': { type: 'string' }
                            }
                        }
                    }
                }),
                valueText: 'Product.Name'
            }
            return pProductsLookup;
        }
        getAvailableProducts = (date: Date, warehouseAcc: string): any => {
            return this.accountService.getAvaliableSupplies(date, warehouseAcc).then(a => {
                return a.Result;
            });
        }


        private deleteEventFunctionality = (e) => {
            var deletedRow = e.model;

            for (var i = 0; i < this.$scope.movementModel.orders.length; i++) {
                if (this.$scope.movementModel.orders[i].orderID === deletedRow.orderID) {
                    this.$scope.movementModel.orders.splice(i, 1);
                    break;
                }
            }         
        }

        private toInnerMovementModel(docInfo: Interfaces.IDoc, operId) {         
            var movementModel: IInnerMovementModel = {};
            movementModel.orders = [];
            if (!!docInfo.DocID) {
                _.forEach(docInfo.Orders, (item: Interfaces.IOrder) => {
                    movementModel.orders.push(
                        {
                            vg: item.Vg,
                            count: item.SCount,
                            lifeDate: this.todate(item.Supplie.LifeDate),
                            unit: item.Supplie.Product.Unit,
                            prodName: item.Supplie.Product.Name,
                            inCode: item.Supplie.Product.InCode,
                            bCode: item.Supplie.Product.BCode,
                            packCount: item.Supplie.Product.PackCount,
                            orderID: item.ID,
                            prodPP: item.Supplie.ProdPP_id,
                            rCount: item.Supplie.RCount
                        });
                });
            }
            return movementModel;
        }

        getPartial(): string {
            return 'modalsinner.movement.html';
        }
        private ordersColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'bCode', titleTranslated: 'barcode', width: 200 },
            { field: 'inCode', titleTranslated: 'inCode', width: 100 },
            { field: 'prodName', titleTranslated: 'product' },
            { field: 'unit', titleTranslated: 'unit', width: 100 },
            { field: 'lifeDate', titleTranslated: 'lifeDate', width: 120, format: Core.Kendo.Format.Date },
            { field: 'rCount', titleTranslated: 'rest', width: 100, format: Core.Kendo.Format.Price4 },
            { field: 'count', titleTranslated: 'Count', width: 80, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data && data.count && data.count.sum ? parseFloat(data.count.sum).toFixed(4) : 0) #' },
            { field: 'vg', titleTranslated: 'vg', width: 100, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.vg ? parseFloat(data.vg.sum).toFixed(4) : 0) #' }
        ];

        saveDoc = () => {
            var modalBook = this.$scope.formScope.modalBook;
            if (modalBook.$invalid) {
                this.toaster.pop('error', 'ყურადღება!', 'საბუთის შესანახად სწორედ შეავსეთ ყველა ველი!');
                return;
            }

            if (this.$scope.baseModel.credit.Acc === this.$scope.baseModel.debet.Acc) {
                this.toaster.pop('error', 'ყურადღება!', 'აირჩიეთ განსხვავებული საწყობები!');
                return;
            }

            if (this.$scope.movementModel.orders.length === 0) {
                this.toaster.pop('error', 'ყურადღება!', 'საქონლის სია ცარიელია!');
                return;
            }

            var model = this.$scope.movementModel;
            var baseModel = this.$scope.baseModel;

            var doc: Interfaces.IDoc = {
                DB: baseModel.debet.Acc,
                CR: baseModel.credit.Acc,
                DDate: baseModel.crDate,
                Operid: baseModel.operId,
                NumberIn: baseModel.numberIn,
                NumberOut: baseModel.numberOut,
                IsProject: baseModel.isProject
            }

            if (!this.$scope.isCopied)
                doc.DocID = baseModel.docID;

            doc.Orders = [];

            var ordersDS = this.$scope.components.ordersOptions.grid.dataSource.data();

            _.forEach(ordersDS, (item: IOrderModel) => {
                var order: Interfaces.IOrder = {};
                order.Vg = item.vg;
                order.SCount = item.count;
                order.Supplie = { ProdPP_id: item.prodPP }
                if (item.orderID.length > 10)
                    order.ID = null;
                else {
                    if (!this.$scope.isCopied)
                        order.ID = item.orderID;
                }
                doc.Orders.push(order);
            });

            this.saveDocument(doc);
        }

    }

}